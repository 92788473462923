import { Fieldset } from 'primereact/fieldset';
import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { OutDatedIcon } from '../../../../components/OutDated/out-dated';
import { integrationCardResponse } from '../../../../models/types';

export interface IntegrationCountProps {
    isIntegrationLoading: boolean,
    isOutDated: boolean,
    integrationCardResponseHelios: integrationCardResponse;
    integrationCardResponseCortex: integrationCardResponse;
    integrationCardResponseAlerta: integrationCardResponse;
    renderCardValues: (value: string) => JSX.Element,
}
export const IntegrationCount = (props: IntegrationCountProps) => {
    const renderSkeletonVehicleTypeValue = <Skeleton shape="rectangle" width='100px' height='30px' />;
    const responsiveConfig = 'col-12 lg:col-6 xl:col-6';
    const cardIconClasses = 'flex align-items-center justify-content-center bg-blue-500 border-round card-icon mr-2';

    const formatCardValue = (value: number): string => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }


    const calculateAverageIntegrationSucess = (integrationResponse: integrationCardResponse): JSX.Element => {
        let average: number = 0;

        if (integrationResponse && integrationResponse.activeCameras !== 0) {
            average = integrationResponse.totalRead - integrationResponse.totalFailure;
            average = (average / integrationResponse.totalRead) * 100;
        }

        return (
            <div className="text-900 text-4xl">
                {props.isIntegrationLoading ? renderSkeletonVehicleTypeValue : average.toFixed(2) + '%'}
            </div>
        );
    }


    return (
        <React.Fragment>
            <div className={responsiveConfig}>
                <Fieldset legend="Resumo Integrações - Helios" toggleable>
                    <div className='flex justify-content-center'>
                        <div className="mr-3">
                            <div className="flex justify-content-center">
                                <div className={cardIconClasses}>
                                    <i className="pi pi-check-circle text-xl" />
                                </div>
                                <div>
                                    <span className="block mb-1 card-title">Integrações Realizadas</span>
                                    {props.renderCardValues(formatCardValue(props.integrationCardResponseHelios.totalRead))}
                                    {OutDatedIcon(props.isOutDated)}
                                </div>
                            </div>
                        </div>
                        <div className="mr-3">
                            <div className="flex justify-content-center">
                                <div className={cardIconClasses}>
                                    <i className="pi pi-times-circle text-xl" />
                                </div>
                                <div>
                                    <span className="block mb-1 card-title">Integrações Recusadas</span>
                                    {props.renderCardValues(formatCardValue(props.integrationCardResponseHelios.totalFailure))}
                                    {OutDatedIcon(props.isOutDated)}
                                </div>
                            </div>
                        </div>
                        <div className="mr-3">
                            <div className="flex justify-content-center">
                                <div className={cardIconClasses}>
                                    <i className="pi pi-check-circle text-xl" />
                                </div>
                                <div>
                                    <span className="block mb-1 card-title">Sucesso Integrações</span>
                                    {calculateAverageIntegrationSucess(props.integrationCardResponseHelios)}
                                    {OutDatedIcon(props.isOutDated)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fieldset>

                <Fieldset legend="Resumo Integrações - Cortex" toggleable>
                    <div className='flex justify-content-center'>
                        <div className="mr-3">
                            <div className="flex justify-content-center">
                                <div className={cardIconClasses}>
                                    <i className="pi pi-check-circle text-xl" />
                                </div>
                                <div>
                                    <span className="block mb-1 card-title">Integrações Realizadas</span>
                                    {props.renderCardValues(formatCardValue(props.integrationCardResponseCortex.totalRead))}
                                    {OutDatedIcon(props.isOutDated)}
                                </div>
                            </div>
                        </div>
                        <div className="mr-3">
                            <div className="flex justify-content-center">
                                <div className={cardIconClasses}>
                                    <i className="pi pi-times-circle text-xl" />
                                </div>
                                <div>
                                    <span className="block mb-1 card-title">Integrações Recusadas</span>
                                    {props.renderCardValues(formatCardValue(props.integrationCardResponseCortex.totalFailure))}
                                    {OutDatedIcon(props.isOutDated)}
                                </div>
                            </div>
                        </div>
                        <div className="mr-3">
                            <div className="flex justify-content-center">
                                <div className={cardIconClasses}>
                                    <i className="pi pi-check-circle text-xl" />
                                </div>
                                <div>
                                    <span className="block mb-1 card-title">Sucesso Integrações</span>
                                    {calculateAverageIntegrationSucess(props.integrationCardResponseCortex)}
                                    {OutDatedIcon(props.isOutDated)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fieldset>

                <Fieldset legend="Resumo Integrações - Alerta Brasil" toggleable>
                    <div className='flex justify-content-center'>
                        <div className="mr-3">
                            <div className="flex justify-content-center">
                                <div className={cardIconClasses}>
                                    <i className="pi pi-check-circle text-xl" />
                                </div>
                                <div>
                                    <span className="block mb-1 card-title">Integrações Realizadas</span>
                                    {props.renderCardValues(formatCardValue(props.integrationCardResponseAlerta.totalRead))}
                                    {OutDatedIcon(props.isOutDated)}
                                </div>
                            </div>
                        </div>
                        <div className="mr-3">
                            <div className="flex justify-content-center">
                                <div className={cardIconClasses}>
                                    <i className="pi pi-times-circle text-xl" />
                                </div>
                                <div>
                                    <span className="block mb-1 card-title">Integrações Recusadas</span>
                                    {props.renderCardValues(formatCardValue(props.integrationCardResponseAlerta.totalFailure))}
                                    {OutDatedIcon(props.isOutDated)}
                                </div>
                            </div>
                        </div>
                        <div className="mr-3">
                            <div className="flex justify-content-center">
                                <div className={cardIconClasses}>
                                    <i className="pi pi-check-circle text-xl" />
                                </div>
                                <div>
                                    <span className="block mb-1 card-title">Sucesso Integrações</span>
                                    {calculateAverageIntegrationSucess(props.integrationCardResponseAlerta)}
                                    {OutDatedIcon(props.isOutDated)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fieldset>
            </div>
        </React.Fragment>
    )
}
