import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { DropdownInfo } from '../../../../models/types';
import { PdfServices } from '../../../../services/pdf-services';


export interface DowloadPdfProps {
    cameras: DropdownInfo[],
    locations: DropdownInfo[],
    selectedCamera: number,
    selectedLocation: number,
    selectedTimeSpan: number,
    showPdfDialog: boolean,
    loadingCameras: boolean,
    loadingLocations: boolean,
    setShowPdfDialog: (e: any) => void,
    onSelectedUF: (e: any) => void,
    onSelectedDate: (e: any) => void,
    onSelectedCamera: (e: any) => void,
    onSelectedTimeSpan: (e: any) => void,
}

export const DownloadPdf = (props: DowloadPdfProps) => {

    const hideDownloadPdfDialog = () => {
        props.setShowPdfDialog(false);
    };

    const showDownloadPdfDialog = () => {
        props.setShowPdfDialog(true);
    };

    const downloadPdfDialogFooter = (
        <>
            <Button label="Gerar PDF" icon="pi pi-file-pdf" className="p-button-text" onClick={(e) => downloadReport()} />
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDownloadPdfDialog} />
        </>
    );

    const header = "Gerar Relatório PDF";

    const style = {
        width: "40%",
        maxWidth: "500px"
    }

    const timeSpanOptions: any[] = [
        { id: 1, description: "1 Dia" },
        { id: 5, description: "5 Dias" },
        { id: 15, description: "15 Dias" },
        { id: 30, description: "30 Dias" },
        { id: 60, description: "60 Dias" }
    ];

    const downloadReport = () => {
        var pdfServices = new PdfServices();

        pdfServices.GetPdf(props.selectedTimeSpan, props.selectedLocation, props.selectedCamera)
            .then(response => {
                const href = URL.createObjectURL(response.data);

                let fileName = 'relatorio_performance.pdf';
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }).catch(error => {
                
            });
    }

    return (<>
        <Button
            onClick={(e: any) => showDownloadPdfDialog()}
            label="Exportar PDF"
            icon="pi pi-file-pdf"
        />

        <Dialog
            modal
            style={style}
            header={header}
            resizable={false}
            draggable={false}
            visible={props.showPdfDialog}
            onHide={hideDownloadPdfDialog}
            footer={downloadPdfDialogFooter}>
            <div className="categoria">
                <div className="grid">
                    <div className="p-field col-12">
                        <strong>Localidade:</strong>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-map-marker"></i>
                            </span>
                            <Dropdown
                                autoFocus
                                value={props.selectedLocation}
                                disabled={props.loadingLocations}
                                onChange={props.onSelectedUF}
                                placeholder="Selecione uma cidade..."
                                optionLabel="description"
                                optionValue="id"
                                options={props.locations}
                            />
                        </div>
                    </div>
                    <div className="p-field col-12">
                        <strong>Camera:</strong>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-video"></i>
                            </span>
                            <Dropdown
                                autoFocus
                                value={props.selectedCamera}
                                placeholder="Selecione uma câmera..."
                                optionLabel="description"
                                optionValue="id"
                                disabled={props.loadingCameras || props.selectedLocation === 0}
                                onChange={(e) => props.onSelectedCamera(e)}
                                options={props.cameras}
                            />
                        </div>
                    </div>
                    <div className="p-field col-12">
                        <strong>Periodicidade:</strong>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-clock"></i>
                            </span>
                            <Dropdown
                                autoFocus
                                value={props.selectedTimeSpan}
                                onChange={(e) => props.onSelectedTimeSpan(e)}
                                placeholder="Selecione uma cidade..."
                                optionLabel="description"
                                optionValue="id"
                                options={timeSpanOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Dialog >
    </>);
}