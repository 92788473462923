import { style } from 'd3-selection';
import { zoom } from 'd3-zoom';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

export interface ImageViewerProps {
    src: string,
    alt: string,
    style: React.CSSProperties
}

export const ImageViewer = (props: ImageViewerProps) => {
    const [zoomDialog, setZoomDialog] = useState<boolean>(false);

    const onImageClicked = (e: any) => {
        setZoomDialog(true);
    }

    const onHideDialog = () => {
        setZoomDialog(false);
    }

    const headerText = "Use o scroll do mouse para ZOOM";

    return (
        <React.Fragment>
            <div onClick={(e) => onImageClicked(e)}>
                <img
                    src={props.src}
                    alt={props.alt}
                    style={props.style}

                />
            </div>
            <Dialog visible={zoomDialog} onHide={onHideDialog} style={{ width: '40%' }} header={headerText}>
                <TransformWrapper smooth >
                    <TransformComponent wrapperStyle={{ width: '100%' }} contentStyle={{ width: '100%' }}>
                        <img
                            src={props.src}
                            alt={props.alt}
                            style={{ width: '100%', height: '100%' }}
                        />
                    </TransformComponent>
                </TransformWrapper>
            </Dialog>
        </React.Fragment>
    )
}