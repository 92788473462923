import React from 'react';
import { integrationCardResponse } from '../../../../models/types';
import { Fieldset } from 'primereact/fieldset';
export interface CameraCountProps {
    integrationCardResponseHelios: integrationCardResponse,
    integrationCardResponseCortex: integrationCardResponse,
    integrationCardResponseAlerta: integrationCardResponse,
    renderCardValues: (value: any) => JSX.Element
}

export const CameraCount = (props: CameraCountProps) => {
    return (
        <React.Fragment>
            <div className='col-12 lg:col-12 xl:col-6'>
                <Fieldset legend="Resumo Localidades - Helios" toggleable>
                    <div className='flex justify-content-center'>
                        <div className="flex justify-content-center mr-4">
                            <div className='flex align-items-center justify-content-center bg-green-500 border-round card-icon mr-2'>
                                <i className="pi pi-map-marker text-blue-50 text-xl" />
                            </div>
                            <div>
                                <span className="block mb-1 card-title">Localidades Ativas</span>
                                {props.renderCardValues(props.integrationCardResponseHelios.locationCount)}
                            </div>
                        </div>
                        <div className="flex justify-content-center mr-4">
                            <div className='flex align-items-center justify-content-center bg-green-500 border-round card-icon mr-2'>
                                <i className="pi pi-camera text-green-50 text-xl" />
                            </div>
                            <div>
                                <span className="block mb-1 card-title">Cameras Ativas</span>
                                {props.renderCardValues(props.integrationCardResponseHelios.activeCameras)}
                            </div>
                        </div>
                        <div className="flex justify-content-center mr-4">
                            <div className='flex align-items-center justify-content-center bg-orange-500 border-round card-icon mr-2'>
                                <i className="pi pi-camera text-blue-50 text-xl" />
                            </div>
                            <div>
                                <span className="block mb-1 card-title">Cameras Inativas</span>
                                {props.renderCardValues(props.integrationCardResponseHelios.inactiveCameras)}
                            </div>
                        </div>
                    </div>
                </Fieldset>

                <Fieldset legend="Resumo Localidades - Cortex" toggleable>
                    <div className='flex justify-content-center'>
                        <div className="flex justify-content-center mr-4">
                            <div className='flex align-items-center justify-content-center bg-green-500 border-round card-icon mr-2'>
                                <i className="pi pi-map-marker text-blue-50 text-xl" />
                            </div>
                            <div>
                                <span className="block mb-1 card-title">Localidades Ativas</span>
                                {props.renderCardValues(props.integrationCardResponseCortex.locationCount)}
                            </div>
                        </div>
                        <div className="flex justify-content-center mr-4">
                            <div className='flex align-items-center justify-content-center bg-green-500 border-round card-icon mr-2'>
                                <i className="pi pi-camera text-green-50 text-xl" />
                            </div>
                            <div>
                                <span className="block mb-1 card-title">Cameras Ativas</span>
                                {props.renderCardValues(props.integrationCardResponseCortex.activeCameras)}
                            </div>
                        </div>
                        <div className="flex justify-content-center mr-4">
                            <div className='flex align-items-center justify-content-center bg-orange-500 border-round card-icon mr-2'>
                                <i className="pi pi-camera text-blue-50 text-xl" />
                            </div>
                            <div>
                                <span className="block mb-1 card-title">Cameras Inativas</span>
                                {props.renderCardValues(props.integrationCardResponseCortex.inactiveCameras)}
                            </div>
                        </div>
                    </div>
                </Fieldset>

                <Fieldset legend="Resumo Localidades - Alerta" toggleable>
                    <div className='flex justify-content-center'>
                        <div className="flex justify-content-center mr-4">
                            <div className='flex align-items-center justify-content-center bg-green-500 border-round card-icon mr-2'>
                                <i className="pi pi-map-marker text-blue-50 text-xl" />
                            </div>
                            <div>
                                <span className="block mb-1 card-title">Localidades Ativas</span>
                                {props.renderCardValues(props.integrationCardResponseAlerta.locationCount)}
                            </div>
                        </div>
                        <div className="flex justify-content-center mr-4">
                            <div className='flex align-items-center justify-content-center bg-green-500 border-round card-icon mr-2'>
                                <i className="pi pi-camera text-green-50 text-xl" />
                            </div>
                            <div>
                                <span className="block mb-1 card-title">Cameras Ativas</span>
                                {props.renderCardValues(props.integrationCardResponseAlerta.activeCameras)}
                            </div>
                        </div>
                        <div className="flex justify-content-center mr-4">
                            <div className='flex align-items-center justify-content-center bg-orange-500 border-round card-icon mr-2'>
                                <i className="pi pi-camera text-blue-50 text-xl" />
                            </div>
                            <div>
                                <span className="block mb-1 card-title">Cameras Inativas</span>
                                {props.renderCardValues(props.integrationCardResponseAlerta.inactiveCameras)}
                            </div>
                        </div>
                    </div>
                </Fieldset>
            </div>
        </React.Fragment>
    )
}
