import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Tag } from "primereact/tag"
import React, { useRef } from "react"
import { destinationABInterface } from "../../types/types"

export interface DestinatioABListDataTableProps {
  destinationsAB: destinationABInterface[];
  selectedDestinationsAB: destinationABInterface[];
  setSelectedDestinationnAB: (e: any) => void;
  loading: boolean;
  globalFilter: string;
  editDestinationAB: (rowData: destinationABInterface) => void;
  confirmDeleteDestinationAB: (rowData: destinationABInterface) => void;
}

export const DestinationABListDataTable = (props: DestinatioABListDataTableProps) => {

  const header = (
    <div className="table-header">
      <h5 className="p-m-2">Ponto A & B</h5>
    </div>
  )

  const pointABodyTemplate = (destionationAB: destinationABInterface) => {
    return <>{destionationAB.cameraPointA.description}</>;
  }

  const pointBBodyTemplate = (destionationAB: destinationABInterface) => {
    return <>{destionationAB.cameraPointB.description}</>;
  }

  const isActiveBodyTemplate = (destionationAB: destinationABInterface) => {
    if (destionationAB.isActive === true) {
      return (
        <>
          <Tag severity="success" value="Ativo" icon="pi pi-check"></Tag>
        </>
      );
    } else {
      return (
        <>
          <Tag severity="danger" value="Inativo" icon="pi pi-times"></Tag>
        </>
      );
    }
  }

  const actionBodyTemplate = (rowData: destinationABInterface) => {
    return (
      <div className="actions my-2 xl:offset-md-5">
        <Button icon="pi pi-pencil" className="p-button-rounded p-button mr-2" onClick={() => props.editDestinationAB(rowData)} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => props.confirmDeleteDestinationAB(rowData)} />
      </div>
    );
  }

  const dt = useRef(null);

  return (
    <DataTable
      ref={dt}
      value={props.destinationsAB}
      selection={props.selectedDestinationsAB}
      selectionMode='multiple'
      onSelectionChange={(e: any) => props.setSelectedDestinationnAB(e.value)}
      dataKey="id"
      paginator
      rows={10}
      rowsPerPageOptions={[5, 10, 25]}
      className="datatable-responsive"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
      currentPageReportTemplate="Mostrando {first} de {last}, de um total de {totalRecords} pontos A & B"
      globalFilter={props.globalFilter}
      emptyMessage="Nenhum ponto A & B encontrado."
      header={header}
      loading={props.loading}
    >
      <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
      <Column field="cameraNamePointA" header="Ponto A" sortable body={pointABodyTemplate}></Column>
      <Column field="cameraNamePointB" header="Ponto B" sortable body={pointBBodyTemplate}></Column>
      <Column field="isActive" header="Ativo" sortable body={isActiveBodyTemplate}></Column>
      <Column body={actionBodyTemplate}></Column>
    </DataTable>
  )
}
