import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { DropdownInfo } from '../../../models/types';
import { FeatureServices } from '../../../services/feature-services';
import { ToastServices } from '../../../services/utils/toast-services';
import { CameraServices } from '../../../services/camera-services';

export interface EventBikerBehaviorHeaderProps {
    selectedLocation: number,
    loading: boolean,
    setLoading: (val: boolean) => void,
    selectedCamera: number,
    setSelectedCamera: (val: number) => void,
    globalFilter: string,
    setGlobalFilter: (val: string) => void,
    setSelectedLocation: (e: any) => void,
}

export const EventBikerBehaviorHeader = (props: EventBikerBehaviorHeaderProps) => {
    const _toastServices = new ToastServices();
    const _featureServices = new FeatureServices();
    const _cameraServices = new CameraServices();

    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [cameras, setCameras] = useState<DropdownInfo[]>([]);

    useEffect(() => {
        props.setLoading(true);
        _featureServices.getLocationsByFeature('BIKER-ANALYSIS')
            .then((data: any) => {
                setLocations(data.data);
                props.setLoading(false);
            }).catch((error: any) => {
                _toastServices.showErrorRequestMessage(error);
                props.setLoading(false);
            });
    }, [])

    useEffect(() => {
        if (props.selectedLocation !== 0) {
            if (props.selectedCamera !== 0) {
                props.setSelectedCamera(null);
            }

            var response: DropdownInfo[] = _cameraServices
                .getCamerasByLocation(props.selectedLocation);

            setCameras(response);
        }
    }, [props.selectedLocation]);


    return (
        <React.Fragment>
            <div className='grid'>
                <div className="col-6 lg:col-6 xl:col-2">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-map-marker" />
                        </span>
                        <Dropdown
                            options={locations}
                            value={props.selectedLocation}
                            optionLabel="description"
                            optionValue="id"
                            disabled={props.loading}
                            placeholder="SELECIONE UMA LOCALIDADE"
                            onChange={(e) => props.setSelectedLocation(e.value)}
                            emptyMessage="NENHUMA LOCALIDADE ENCONTRADA">
                        </Dropdown>
                    </div>
                </div>
                {props.selectedLocation !== 0 &&
                    <div className="col-6 lg:col-6 xl:col-3">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-camera" />
                            </span>
                            <Dropdown
                                options={cameras}
                                value={props.selectedCamera}
                                optionLabel="description"
                                optionValue="id"
                                disabled={props.loading}
                                placeholder="Selecione uma camera"
                                onChange={(e) => props.setSelectedCamera(e.value)}
                                emptyMessage="Nenhuma Camera Encontrada.">
                            </Dropdown>
                        </div>
                    </div>
                }
                <div className="col-12 lg:col-2 xl:col-8">
                    <ClipLoader color='var(--text-color)' loading={props.loading} size={25} />
                </div>
            </div>
        </React.Fragment>
    )
}