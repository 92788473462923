import axios from "axios";
import { environment } from "../configuration/environment";
import { AuthServices } from "./utils/auth-services";
import { BehavioralConvoyEventsRequest } from "../pages/Event-behabioral-convoy/types/types";

export class BehavioralConvoyServices {
    baseControllerUrl: string = environment().baseUrl + environment().version + "/behavioral-convoy-mode";
    _authServices = new AuthServices();

    getEvents(cameraId: number, locationId: number) {
        const behavioralConvoyRequest: BehavioralConvoyEventsRequest = {
            cameraId: cameraId === 0 ? null : cameraId,
            locationId: locationId === 0 ? null : locationId,
        };
        return axios.post(this.baseControllerUrl + "/get-events", behavioralConvoyRequest, {
            headers: { Authorization: sessionStorage.getItem("Bearer") },
        });
    }

    getConfigurations() {
        return axios.get(this.baseControllerUrl + "/configurations", {
            headers: { Authorization: sessionStorage.getItem("Bearer") },
        });
    }

    getBehavioralConvoy(locationId: number) {
        return axios.get(this.baseControllerUrl + `/by-location/${locationId}`, {
            headers: { Authorization: sessionStorage.getItem("Bearer") },
        });
    }

    updateBehavioralConvoy(locationId: number, firstVehicleTypeId: number, secondVehicleTypeId: number) {
        return axios.put(
            this.baseControllerUrl + `/by-location/${locationId}`,
            {},
            {
                headers: { Authorization: sessionStorage.getItem("Bearer") },
                params: {
                    locationId: locationId,
                    firstVehicleTypeId: firstVehicleTypeId,
                    secondVehicleTypeId: secondVehicleTypeId,
                },
            }
        );
    }
}
