import { Dropdown } from 'primereact/dropdown';
import React, { useState, useEffect } from 'react';
import { DropdownInfo } from '../../../../models/types';
import { Button } from 'primereact/button';
import { ClipLoader } from 'react-spinners';
import { LocationServices } from '../../../../services/location-services';
import { ToastServices } from '../../../../services/utils/toast-services';

export interface DssHeaderProps {
    loading: boolean
    selectedLocation: number,
    setLoading: (val: boolean) => void,
    setSelectedLocation: (val: number) => void,
}

export const DssHeader = (props: DssHeaderProps) => {
    const _toastServices = new ToastServices();
    const _locationServices = new LocationServices();

    const [locations, setLocations] = useState<DropdownInfo[]>([]);

    useEffect(() => {
        getLocations();
    }, [])

    const getLocations = () => {
        props.setLoading(true);
        _locationServices.getLocationsWithActiveCameras()
            .then((data: any) => {
                setLocations(data.data);
                props.setLoading(false);
            }).catch((error) => {
                _toastServices.showErrorRequestMessage(error);
                props.setLoading(false);
            });
    }

    const onSelectedLocation = (e: any) => {
        props.setSelectedLocation(e.value);
    }

    return (
        <React.Fragment>
            <div className='grid'>
                <div className='col-2'>
                    <div className="p-inputgroup mb-2">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-map-marker"></i>
                        </span>
                        <Dropdown
                            options={locations}
                            value={props.selectedLocation}
                            disabled={props.loading}
                            placeholder='Selecione uma localidade'
                            optionLabel='description'
                            optionValue='id'
                            onChange={(e) => onSelectedLocation(e)}
                        />
                    </div>
                </div>
                <div className='col-1'>
                    <ClipLoader color='var(--text-color)' loading={props.loading} size={25} />
                </div>
            </div>
        </React.Fragment>
    )
}
