import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { DropdownInfo } from '../../../../models/types';

export interface EventBlackListHeaderProps {
    loading: boolean,
    plate: string,
    cameras: DropdownInfo[],
    locations: DropdownInfo[],
    selectedCamera: number,
    selectedLocation: number,
    onSelectedLocation: (e: { value: any }) => void,
    onSelectedCamera: (e: { value: any }) => void,
    onPlateInputChange: (val: string) => void,
}

export const EventBlackListHeader = (props: EventBlackListHeaderProps) => {
    return (
        <React.Fragment>
            <div className="grid">
                <div className="col-4 lg:col-6 xl:col-2">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-pencil"></i>
                        </span>
                        <InputText
                            value={props.plate}
                            disabled={props.loading}
                            onChange={(e) => props.onPlateInputChange(e.target.value)}
                            type="text"
                            placeholder="Placa"
                        />
                    </div>
                </div>

                <div className="col-4 lg:col-6 xl:col-2">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-map-marker"></i>
                        </span>
                        <Dropdown
                            options={props.locations}
                            disabled={props.loading}
                            value={props.selectedLocation}
                            optionLabel="description"
                            optionValue="id"
                            placeholder="Localidade"
                            onChange={props.onSelectedLocation}
                            emptyMessage="Nenhuma Localidade Encontrada."
                        />
                    </div>
                </div>
                <div className="col-4 lg:col-6 xl:col-2">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-camera"></i>
                        </span>
                        <Dropdown
                            options={props.cameras}
                            disabled={props.loading || props.selectedLocation === 0}
                            value={props.selectedCamera}
                            optionLabel="description"
                            optionValue="id"
                            placeholder="Câmera"
                            onChange={props.onSelectedCamera}
                            emptyMessage={"Nenhuma Câmera Encontrada."}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}