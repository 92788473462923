import React, { useState, useEffect } from 'react';
import moment from 'moment';

export interface TimeSinceUpdateProps {
    lastUpdated: string;
    className?: string
    style?: any
}

export const TimeSinceUpdate = (props: TimeSinceUpdateProps) => {
    const [timeSince, setTimeSince] = useState('');

    useEffect(() => {
        const calculateTimeSince = () => {
            const now = moment();
            const lastUpdateTime = moment(props.lastUpdated);
            const duration = moment.duration(now.diff(lastUpdateTime));

            const hours = Math.floor(duration.asHours());
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            setTimeSince(`${hours}h ${minutes}m ${seconds}s`);
        };

        calculateTimeSince();
        const interval = setInterval(calculateTimeSince, 1000);

        return () => clearInterval(interval);
    }, [props.lastUpdated]);

    return <div style={props.style} className={props.className}>{timeSince}</div>;
};




