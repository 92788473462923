import React from 'react';

export interface LocationBikerBehaviorWarningProps {
    startHour: Date,
    stopHour: Date
}

export const LocationBikerBehaviorWarning = (props: LocationBikerBehaviorWarningProps) => {
    return (
        <>
            <div className="card mb-4 black-list-warning">
                <span style={{ color: `#fcad03`, fontSize: "1.2rem", display: `flex`, alignItems: `center` }}>
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "1.2em" }} />
                    A análise comportamental de motoqueiros estará em operação de <strong className='ml-2 mr-2'>{props.startHour.toLocaleTimeString()}</strong> até as  <strong className='ml-2 mr-2'>{props.stopHour.toLocaleTimeString()}</strong> para as câmeras com a opção ativada.
                </span>
            </div>
        </>
    );

}