import { Feature } from "../../Features/types/types"

export const emptyFeature: Feature = {
    featureId: 1,
    featureName: '',
    locationFeatureId: 1,
    whatsappNotification: false
}
export interface whatsappRecipient {
    recipientId: number,
    recipientName: string,
    recipientCode: string,
    createStamp: string,
    active: boolean,
    whatsappGroupId: number
    whatsappGroupName: string
}

export interface whatsappGroup {
    locationWhatsappGroupId: number,
    groupName: string,
    createStamp: string,
    active: boolean,
    locationId: number,
}
