import axios from 'axios';
import { environment } from "../configuration/environment";
import { AuthServices } from './utils/auth-services';

export class FeatureServices {
    _authServices = new AuthServices();

    getLocationsByFeature(featureDescription: string) {
        return axios.get(environment().baseUrl + environment().version + '/feature/locations-by-feature', {
            params: {
                featureDescription: featureDescription
            },
            headers: {
                Authorization: this._authServices.GetUserToken()
            }
        });
    }

    getFeatures(alarmTypeFeatures: boolean) {
        return axios.get(environment().baseUrl + environment().version + '/feature', {
            params: {
                alarmTypeFeatures: alarmTypeFeatures
            },
            headers: {
                Authorization: this._authServices.GetUserToken()
            }
        });
    }
}
