import React from 'react';
import { GetMoonVersion } from './moon-version';
import blackLogo from './wwroot/img/moon-black-logo.png';
import whiteLogo from './wwroot/img/moon-white-logo.png';

export const AppFooter = (props) => {
    const getImageSource = () => {
        var userTheme = localStorage.getItem("user-theme") || 'claro';

        return userTheme === 'claro' ? blackLogo : whiteLogo;
    }

    return (
        <div className="layout-footer">
            {GetMoonVersion()}
        </div>
    );
}
