import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { InputText } from "primereact/inputtext"
import { Tag } from "primereact/tag"
import { Toolbar } from "primereact/toolbar"
import React from "react"
import { user } from "../../../Login/types/types"

export interface UserListDataTableProps {
  users: user[];
  selectedUsers: user[];
  setSelectedUsers: (selectedUsers: user[]) => void;
  loading: boolean;
  globalFilter: string;
  setGlobalFilter: (val: any) => void,
  editUser: (rowData: user) => void;
  confirmDeleteUser: (rowData: user) => void;
  confirmDeleteSelected: () => void,
  openNew: () => void
}

export const UserListDataTable = (props: UserListDataTableProps) => {

  const header = (
    <div className="table-header">
      <h5 className="p-m-2">Usuários</h5>
    </div>
  )

  const emailBodyTemplate = (user: user) => {
    return <>{user.email}</>;
  }

  const nameBodyTemplate = (user: user) => {
    return <>{user.userName}</>;
  }

  const accessBodyTemplate = (user: user) => {
    return <>{user.userAccess}</>;
  }

  const administradorBodyTemplate = (user: user) => {
    if (user.isAdministrator === true) {
      return <Tag severity="success" value="Administrador" icon="pi pi-check"></Tag>
    }

    return <Tag value="Usuário" icon="pi pi-times"></Tag>
  }

  const actionBodyTemplate = (rowData: user) => {
    return (
      <div className="actions my-2 xl:offset-md-5">
        <Button icon="pi pi-pencil" className="p-button-rounded p-button mr-2" onClick={() => props.editUser(rowData)} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => props.confirmDeleteUser(rowData)} />
      </div>
    );
  }

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={props.openNew} />
          <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" onClick={props.confirmDeleteSelected} disabled={!props.selectedUsers || !props.selectedUsers.length} />
        </div>
      </React.Fragment>
    );
  }

  const rightToolbarTemplate = () => {
    return (
      <>
        <React.Fragment>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={props.globalFilter}
              onInput={(e: any) => props.setGlobalFilter(e.target.value)}
              placeholder="Procurar..."
            />
          </span>
        </React.Fragment>
      </>
    );
  }

  return (
    <React.Fragment>
      <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
      <DataTable
        value={props.users}
        selection={props.selectedUsers}
        onSelectionChange={(e: any) => props.setSelectedUsers(e.value as user[])}
        dataKey="id"
        paginator
        rows={10}
        selectionMode='multiple'
        rowsPerPageOptions={[5, 10, 25]}
        className="datatable-responsive"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
        currentPageReportTemplate="Mostrando {first} de {last}, de um total de {totalRecords} usuários"
        globalFilter={props.globalFilter}
        emptyMessage="Nenhum usuário encontrado."
        header={header}
        loading={props.loading}>
        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
        <Column field="nomUsuario" header="Nome" sortable body={nameBodyTemplate}></Column>
        <Column field="nomAcesso" header="Acesso" sortable body={accessBodyTemplate}></Column>
        <Column field="endEmail" header="Email" sortable body={emailBodyTemplate}></Column>
        <Column field="indAdministrador" header="Tipo Acesso" sortable body={administradorBodyTemplate}></Column>
        <Column body={actionBodyTemplate}></Column>
      </DataTable>
    </React.Fragment>
  )
}
