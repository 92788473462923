import React, { useEffect, useRef, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { cameraUpdateResponse, updatedCameraUpdateResponse } from '../../types/types';
import { TimeSinceUpdate } from '../time/time-since-update';
import { GraphStatusCamera, GraphStatusCameraProps } from '../graph/graph-status-camera';
import '../../styles/dsm-monitor.scss';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';

export interface CamerasListDataTableProps {
    cameras: cameraUpdateResponse[];
    selectedCameras: cameraUpdateResponse[];
    recent: updatedCameraUpdateResponse[];
    setSelectedCameras: (selectedCameras: cameraUpdateResponse[]) => void;
    loading: boolean;
    globalFilter: string;
    setGlobalFilter: (val: any) => void;
    toggleActive: (e: cameraUpdateResponse) => void
}

export const CamerasListDataTable = (props: CamerasListDataTableProps) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const [chartWidth, setChartWidth] = useState<number>(600);

    useEffect(() => {
        const resizeListener = () => {
            if (chartContainerRef.current) {
                const newWidth = chartContainerRef.current.clientWidth;
                setChartWidth(newWidth);
            }
        };

        // Executa o resize imediatamente após a renderização inicial
        resizeListener();

        // Executa o resize após um pequeno delay para garantir o dimensionamento correto
        setTimeout(resizeListener, 5000);
        setTimeout(resizeListener, 7000);

        // Adiciona listener de resize para eventos futuros
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);


    const header = (
        <div className="table-header">
            <h5 className="p-m-2">Cameras</h5>
        </div>
    );

    const getColor = (cameraUpdateResponse?: cameraUpdateResponse) => {
        if (!cameraUpdateResponse.activeMonitor) {
            return '#FFA500'; // Laranja
        }
        else if (cameraUpdateResponse.online) {
            return '#00FF00'; // Verde fluorescente
        } else {
            return '#FF0000'; // Vermelho fluorescente
        }
    };

    const getStatus = (online?: boolean) => {
        if (online) {
            return 'ONLINE';
        } else {
            return 'OFFLINE';
        }
    };

    const nameBodyTemplate = (cameraUpdateResponse: cameraUpdateResponse) => {
        return <>{cameraUpdateResponse.cameraName}</>;
    };

    const urlAddressBodyTemplate = (cameraUpdateResponse: cameraUpdateResponse) => {
        return <>{cameraUpdateResponse.httpUrl}</>;
    };

    const urlPortBodyTemplate = (cameraUpdateResponse: cameraUpdateResponse) => {
        return <>{cameraUpdateResponse.httpPort}</>;
    };


    const statusBodyTemplate = (cameraUpdateResponse: cameraUpdateResponse) => {
        return (
            <div>
                <div className='dsm-monitor-justify flex-container'>
                    <div style={{ color: getColor(cameraUpdateResponse) }} className='dsm-monitor-justify'>{cameraUpdateResponse.type}</div>
                    <div className="pi pi-fw pi-bell" style={{ color: getColor(cameraUpdateResponse) }}></div>
                </div>
            </div>
        );
    };

    const activeTemplate = (rowData: cameraUpdateResponse) => {
        const handleToggle = () => {
            props.toggleActive(rowData);
        };

        return (
            <div onClick={handleToggle} style={{ cursor: 'pointer' }}>
                {rowData.activeMonitor ? (
                    <Tag severity="success" value="Ativo" icon="pi pi-check" />
                ) : (
                    <Tag severity="danger" value="Inativo" icon="pi pi-times" />
                )}
            </div>
        );
    };

    const timeBodyTemplate = (cameraUpdateResponse: cameraUpdateResponse) => {
        return (
            <>
                <TimeSinceUpdate style={{ color: getColor(cameraUpdateResponse) }} className='dsm-monitor-justify' lastUpdated={cameraUpdateResponse.lastUpdated} />
            </>
        )
    };

    const graphBodyTemplate = (cameraUpdateResponse: cameraUpdateResponse) => {
        // Filtra os dados recent para encontrar correspondências com location.dsmApplicationId
        const recentData = props.recent.filter(recent => recent.cameraId === cameraUpdateResponse.cameraId && cameraUpdateResponse.activeMonitor);

        return (
            <div ref={chartContainerRef} className='dsm-monitor-center'>
                {recentData.length > 0 ? (
                    <GraphStatusCamera width={chartWidth} data={recentData as GraphStatusCameraProps['data']} />
                ) : (
                    <div>No Data</div>
                )}
            </div>
        );
    };


    return (
        <React.Fragment>
            <DataTable
                value={props.cameras}
                selection={props.selectedCameras}
                // onSelectionChange={(e: any) => props.setSelectedCameras(e.value as cameraUpdateResponse[])}
                onSelectionChange={(e: any) => {}}
                dataKey="id"
                paginator
                rows={10}
                selectionMode='multiple'
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                currentPageReportTemplate="Mostrando {first} de {last}, de um total de {totalRecords} usuários"
                globalFilter={props.globalFilter}
                emptyMessage="Nenhum usuário encontrado."
                header={header}
                loading={props.loading}>

                <Column field="nomCamera" header="Nome" sortable body={nameBodyTemplate}></Column>
                <Column field="urlAddressCamera" header="URL" sortable body={urlAddressBodyTemplate}></Column>
                <Column field="urlPortCamera" header="Porta" sortable body={urlPortBodyTemplate}></Column>
                <Column field="nomEstado" header="Estado" sortable body={statusBodyTemplate}></Column>
                <Column field="tempEstado" header="Tempo de atividade" sortable body={timeBodyTemplate}></Column>
                <Column field="active" header="Ativo" sortable body={activeTemplate} ></Column>
                {/* <Column body={actionsTemplate} header="Ações"></Column> */}
                <Column field="graphEstado" headerStyle={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }} header="Gráfico" sortable body={graphBodyTemplate}></Column>
                {/* <Column body={actionBodyTemplate}></Column> */}
            </DataTable>
        </React.Fragment>
    );
};
