import axios from "axios";
import { environment } from "../configuration/environment";

export class PdfServices {
    GetPdf(numberOfDays: number, locationId: number | null, cameraId: number | null) {
        return axios.get(environment().baseUrl + environment().version + '/report/generate', {
            responseType: 'blob',
            params: {
                numberOfDays: numberOfDays,
                locationId: locationId === 0 ? null : locationId,
                cameraId: cameraId === 0 ? null : cameraId
            },
            headers: {
                'Authorization': sessionStorage.getItem('Bearer')
            }
        });
    }
}