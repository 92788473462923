import { Button } from "primereact/button";
import React from "react";
import { ConfigurationsResponse, ConvoyConfiguration } from "../../types/types";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";

export interface locationConvoyConfigProps {
    config: ConvoyConfiguration;
    loading: boolean;
    updateConfig: (config: ConvoyConfiguration) => void;
    saveConfig: () => void;
    selectedBehavioralConvoy: number[];
    setSelectedBehavioralConvoy: React.Dispatch<React.SetStateAction<number[]>>;
    configurations: ConfigurationsResponse[];
    onSaveBehavioralConvoyConfig: () => void;
}

export const BehavioralConvoyConfig = (props: locationConvoyConfigProps) => {
    const header = <>
        <span className="titulo-categoria">
            <i className='pi pi-chart-bar mr-2'></i>
            Configuração Modo Comboio Comportamental
        </span>
    </>  

    return (
        <Fieldset legend={header} className='mb-4'>
            <div className="grid">
                <div className="col-2">
                    <div className='flex'>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-chart-bar" />
                            </span>
                            <Dropdown
                                options={props.configurations}
                                value={props.selectedBehavioralConvoy}
                                optionLabel="description"
                                optionValue="vehicleTypesIds"
                                disabled={props.loading}
                                placeholder="Selecione uma Configuração"
                                onChange={(e) => props.setSelectedBehavioralConvoy(e.value)}
                                emptyMessage="Nenhuma Configuração Encontrada."
                            ></Dropdown>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <Button

                        label="SALVAR CONFIGURAÇÃO"
                        onClick={(e) => {
                            props.onSaveBehavioralConvoyConfig();
                        }}
                    />
                </div>
            </div>
        </Fieldset>
    );
};
