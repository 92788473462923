import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { AuthServices } from "../../../services/utils/auth-services";
import { ToastServices } from "../../../services/utils/toast-services";
import { PageHeader } from "../../../views/page-header";
import { UserServices } from "../../../services/user-services";
import { emptyUser, user } from "../../Login/types/types";
import { CreateUserDialog } from "./dialogs/create-user-dialog";
import { DeleteUserDialog } from "./dialogs/delete-user-dialog";
import { DeleteUsersDialog } from "./dialogs/delete-users-dialog";
import { UserListDataTable } from "./data-tables/user-list-data-table";

export const User = () => {
    const _toastServices = new ToastServices();
    const _userServices = new UserServices();
    const _authServices = new AuthServices();

    const [users, setUsers] = useState([]);
    const [user, setUser] = useState<user>(emptyUser);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userDialog, setUserDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState<user[]>([]);
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [deleteUsersDialog, setDeleteUsersDialog] = useState(false);
    const [dialogHeader, setDialogHeader] = useState<string>(null);

    const [isEditingUser, setIsEditingUser] = useState<boolean>(null);

    _authServices.HandleAppAuth();



    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = () => {
        setLoading(true);
        _userServices.getUsers().then(
            (response) => {
                setUsers(response.data);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                _toastServices.showErrorRequestMessage(error);
            }
        );
    }

    const openNew = () => {
        setUser(emptyUser);
        setDialogHeader("Cadastrar Novo Usuário");
        setIsEditingUser(false);
        setUserDialog(true);
        setSubmitted(false);
    }

    const hideDialog = () => {
        setUserDialog(false);
        setLoading(false);
        setUser(emptyUser);
        setSubmitted(false);
    }

    const editUser = (user: user) => {
        setUser(user);
        setDialogHeader("Editar Usuário");
        setIsEditingUser(true);
        setUserDialog(true);
        setSubmitted(false);
    }

    const confirmDeleteUser = (user: user) => {
        setUser(user);
        setDeleteUserDialog(true);
    }

    const confirmDeleteSelected = () => {
        setDeleteUsersDialog(true);
    }

    const onSubmit = () => {
        setLoading(true);
        setSubmitted(true);

        if (isUserValid()) {

            if (user.id) {
                _userServices.updateUser(user).then(
                    () => {
                        _toastServices.showSuccesMessage("Usuário atualizado com sucesso.");
                        getUsers();
                    },
                    (error) => _toastServices.showErrorRequestMessage(error));
            } else {
                _userServices.addUser(user).then(
                    () => {
                        _toastServices.showSuccesMessage("Usuário Cadastrado com Sucesso.");
                        getUsers();
                    },
                    (error) => _toastServices.showErrorRequestMessage(error));
            }
            setUser(emptyUser);
            setUserDialog(false);
        }
        setLoading(false);
    }

    const isUserValid = () => {
        return user.userName && user.email && user.userAccess && user.password && user.allowedLocations.length > 0
    }

    return (
        <div className="grid">
            <div className="col-12">
                <PageHeader title="CADASTRO USUARIOS" icon="pi pi-user" />
                <div className="card">
                    <UserListDataTable
                        confirmDeleteSelected={confirmDeleteSelected}
                        openNew={openNew}
                        setGlobalFilter={setGlobalFilter}
                        users={users}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        loading={loading}
                        globalFilter={globalFilter}
                        editUser={editUser}
                        confirmDeleteUser={confirmDeleteUser}
                    />
                    <CreateUserDialog
                        user={user}
                        setUser={setUser}
                        setUserDialog={setUserDialog}
                        onSubmit={onSubmit}
                        setSubmitted={setSubmitted}
                        isEditingUser={isEditingUser}
                        visible={userDialog}
                        hideDialog={hideDialog}
                        submitted={submitted}
                        dialogHeader={dialogHeader}
                    />
                    <DeleteUserDialog
                        user={user}
                        setUser={setUser}
                        getUsers={getUsers}
                        emptyUser={emptyUser}
                        setLoading={setLoading}
                        visible={deleteUserDialog}
                        setDeleteUserDialog={setDeleteUserDialog}
                    />
                    <DeleteUsersDialog
                        getUsers={getUsers}
                        setLoading={setLoading}
                        visible={deleteUsersDialog}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        setDeleteUsersDialog={setDeleteUsersDialog}
                        user={user}
                    />
                </div>
            </div>
        </div>
    )
};
