import React from 'react';
import { blackListSearch } from '../../../../models/types';
import { DataView } from 'primereact/dataview';
import { ImageViewer } from '../../../../components/ImageViewer/image-viewer';
export interface EventBlackListProps {
    blackList: blackListSearch[],
    loading: boolean
}
export const EventBlackListGrid = (props: EventBlackListProps) => {
    const renderGridItem = (response: blackListSearch) => {
        return (
            <div className="col-12 sm:col-12 lg:col-6 xl:col-2 p-2">
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="gap-2">
                        <div className="flex align-items-center gap-2">
                            <i className="pi pi-camera"></i>
                            <span className="product-category font-semibold">{response.cameraName}</span>
                        </div>
                    </div>
                    <div className="flex justify-content-evenly gap-2">
                        <div className="gap-3 py-10">
                            <div className="grid mt-1">
                                <div className='col-12'>
                                    <ImageViewer
                                        alt={response.cameraName}
                                        src={response.imageUrl}
                                        style={{
                                            width: '90%',
                                            height: '200px',
                                            border: '1px solid var(--text-color)',
                                            borderRadius: '15px',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </div>
                                <div className='col-12 sm:col-6 lg:col-12 xl:col-12 p-2'>
                                    <strong>
                                        <span className="pi pi-tag mr-1"></span>
                                        <span className="mr-2">PLACA VEÍCULO:</span>
                                        {response.plate}
                                    </strong>
                                </div>
                                <div className='col-12 sm:col-6 lg:col-6 xl:col-12 p-2'>
                                    <span className="mr-2 pi pi-clock"></span>
                                    {response.dateTime}
                                </div>
                                <div className='col-12 sm:col-12 lg:col-6 xl:col-12 p-2' style={{ wordBreak: 'break-all' }}>
                                    <span className="pi pi-info mr-1"></span>
                                    <span className="mr-2">MOTIVO:</span>
                                    {response.reason}
                                </div>
                                {response.descriptions && <div className='col-12 sm:col-12 lg:col-12 xl:col-12 p-2' style={{ wordBreak: 'break-all' }}>
                                    <span className="pi pi-info mr-1"></span>
                                    <span className="mr-2">Descrição:</span>
                                    {response.descriptions}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
    const itemTemplate = (cameraConvoy: blackListSearch) => {
        if (!cameraConvoy) {
            return;
        }

        return renderGridItem(cameraConvoy);
    }
    return (
        <React.Fragment>
            <DataView
                header='EVENTOS DE BLACK-LIST'
                value={props.blackList}
                itemTemplate={itemTemplate}
                loading={props.loading}
                layout='grid'
                emptyMessage="NENHUM EVENTO ENCONTRADO PARA O FILTRO SELECIONADO"
                rows={12}
                paginator>
            </DataView>
        </React.Fragment>
    )
}