import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import React, { useEffect, useState } from "react";
import { DropdownInfo } from "../../../models/types";
import { CameraServices } from "../../../services/camera-services";
import { FeatureServices } from "../../../services/feature-services";
import { ToastServices } from "../../../services/utils/toast-services";
import { PageHeader } from "../../../views/page-header";
import "../styles/event-behavioral-convoy.scss";
import { BlobImageResponse } from "../types/types";
import { NavigateToLocation } from "../../../components/Navigation/navigate-to-location";
import { BehavioralConvoyServices } from "../../../services/behavioral-convoy-services";

export const EventBehavioralConvoy = () => {
    const _behavioralConvoyServices = new BehavioralConvoyServices();
    const _cameraServices = new CameraServices();
    const _featureServices = new FeatureServices();
    const _toastServices = new ToastServices();

    const [cameras, setCameras] = useState<DropdownInfo[]>();
    const [images, setImages] = useState<BlobImageResponse[]>([]);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [selectedCamera, setSelectedCamera] = useState<number>(0);
    const [loadingImages, setLoadingImages] = useState<boolean>(false);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [loadingLocations, setLoadingLocations] = useState<boolean>(true);

    useEffect(() => {
        loadLocations();
    }, []);

    const loadLocations = () => {
        let result: DropdownInfo[] = [];
        result.push({ id: 0, description: "TODAS LOCALIDADES" });
        setLoadingLocations(true);
        _featureServices
            .getLocationsByFeature("COMBOIO-COMPORTAMENTAL")
            .then((data) => {
                data.data.forEach((location: any) => {
                    result.push(location);
                });
                setLocations(result);
                setLoadingLocations(false);
            })
            .catch((error) => {
                _toastServices.showErrorRequestMessage(error);
            });
    };

    const getVehicleImages = () => {
        setLoadingImages(true);
        _behavioralConvoyServices.getEvents(selectedCamera, selectedLocation).then((data: any) => {
            setImages(data.data);
            setLoadingImages(false);
        });
    };

    const onSelectedUF = (e: { value: any }) => {
        setSelectedLocation(e.value);

        let response: DropdownInfo[] = _cameraServices.getCamerasByLocation(e.value);
        setSelectedCamera(0);
        setCameras(response);
    };

    const onSelectedCamera = (e: { value: any }) => {
        setSelectedCamera(e.value);
    };

    const renderSearchFilters = () => {
        return (
            <div className="grid">
                <div className="col-2">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-map-marker"></i>
                        </span>
                        <Dropdown options={locations} placeholder="Selecione uma localidade" className="mr-2" optionLabel="description" optionValue="id" value={selectedLocation} disabled={loadingLocations} onChange={(e) => onSelectedUF(e)}></Dropdown>
                    </div>
                </div>
                <div className="col-2">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-camera"></i>
                        </span>
                        <Dropdown options={cameras} placeholder="Camera" className="mr-2" optionLabel="description" optionValue="id" value={selectedCamera} disabled={selectedLocation === 0} onChange={(e) => onSelectedCamera(e)}></Dropdown>
                    </div>
                </div>
                <div className="col-3">
                    <Button label="Pesquisar" disabled={loadingImages} onClick={(e) => getVehicleImages()}></Button>
                </div>
            </div>
        );
    };

    const renderSkeletonDataScroller = () => {
        let responseArray: any[] = [];

        for (let i = 0; i < 6; i++) {
            responseArray.push(skeletonGridItem());
        }

        return (
            <div className="p-dataview p-component p-dataview-grid">
                <div className="p-dataview-content">
                    <div className="flex">{responseArray}</div>
                </div>
            </div>
        );
    };

    const skeletonGridItem = () => {
        return (
            <div className="col-12 sm:col-4 lg:col-12 xl:col-2 p-2">
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                        <Skeleton className="w-6rem border-round h-1rem" />
                    </div>
                    <div className="flex flex-column align-items-center gap-3 py-5">
                        <Skeleton className="w-6 shadow-2 border-round h-10rem" />
                        <Skeleton className="w-8rem border-round h-2rem" />
                    </div>
                    <div className="flex align-items-center justify-content-between">
                        <Skeleton className="w-4rem border-round h-2rem" />
                        <Skeleton shape="circle" className="w-3rem h-3rem" />
                    </div>
                </div>
            </div>
        );
    };

    const gridItem = (event: BlobImageResponse) => {
        return (
            <div className="card col-12 lg:col-8 xl:col-6 p-2" style={{ border: "1px solid var(--text-color)", borderRadius: "10px" }}>
                <div className="card">
                    <div className="col-12 behavioral-convoy-plate">
                        <i className="pi pi-chart-bar mt-3 icon" />
                        <span className="ml-2 behavioral-convoy-plate">{event.firstVehicleType.type + "-" + event.secondVehicleType.type}</span>
                    </div>
                    <div className="col-12 behavioral-convoy-plate">
                        <i className="pi pi-camera mt-3 icon" />
                        <span className="ml-2">{event.cameraName}</span>
                    </div>
                    <div className="col-12 behavioral-convoy-plate">
                        <i className="pi pi-map mt-3 icon" />
                        <span className="ml-2">{event.cameraAddress}</span>
                    </div>
                    <div className="flex col-12">
                        <div className="grid col-6">
                            <div className="behavioral-convoy-plate col-12 flex justify-content-start behavioral-convoy-plate-div">
                                <i className="pi pi-tag" />
                                <strong className="margin-auto ml-2">{event.firstVehicleType.vehiclePlate}</strong>
                            </div>
                            <div className="col-6">
                                <img className="behavioral-convoy-image" src={event.firstVehicleType.imageUrl} alt={event.firstVehicleType.vehiclePlate}></img>
                            </div>
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12">
                                        <i className="pi pi-clock" />
                                        <span className="ml-2 behavioral-convoy-text">{event.firstVehicleType.date}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid col-6">
                            <div className="behavioral-convoy-plate col-12 flex justify-content-start behavioral-convoy-plate-div">
                                <i className="pi pi-tag" />
                                <strong className="margin-auto ml-2">{event.secondVehicleType.vehiclePlate}</strong>
                            </div>
                            <div className="col-6">
                                <img className="behavioral-convoy-image flex justify-content-start" src={event.secondVehicleType.imageUrl} alt={event.secondVehicleType.vehiclePlate}></img>
                            </div>
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12">
                                        <i className="pi pi-clock" />
                                        <span className="ml-2 behavioral-convoy-text">{event.secondVehicleType.date}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const itemTemplate = (event: BlobImageResponse) => {
        if (!event) {
            return;
        }

        return gridItem(event);
    };

    const renderDataScroller = () => {
        return <DataView value={images} itemTemplate={itemTemplate} layout="grid" emptyMessage="Nenhuma imagem encontrada para o filtro selecionado" rows={12} paginator></DataView>;
    };

    return (
        <div className="grid">
            <div className="col-12">
                <PageHeader title="EVENTOS DE MODO COMBOIO COMPORTAMENTAL" icon="pi pi-chart-bar" />
                <div className="card">
                    <NavigateToLocation icon="pi-spin pi-cog" text="CONFIGURAR MODO COMBOIO COMPORTAMENTAL" to="camera-convoy-configuration" />
                    {renderSearchFilters()}
                    {loadingImages ? renderSkeletonDataScroller() : renderDataScroller()}
                </div>
            </div>
        </div>
    );
};
