import axios from "axios";
import { environment } from "../configuration/environment";
import { AuthServices } from "./utils/auth-services";

export class ConvoyServices {
    baseControllerUrl: string = environment().baseUrl + environment().version + "/convoy";
    _authServices = new AuthServices();

    getEvents(cameraId: number, locationId: number) {
        return axios.get(this.baseControllerUrl + "/get-events", {
            headers: { Authorization: sessionStorage.getItem("Bearer") },
            params: {
              cameraId: cameraId == 0 ? null : cameraId,
              locationId: locationId == 0 ? null : locationId
            }
        });
    }

    getLocationConvoyTime(locationId: number) {
        return axios.get(this.baseControllerUrl + '/' + locationId, {
            headers: { Authorization: sessionStorage.getItem("Bearer") },
        });
    }

    updateConvoyTime(locationId: number, startTime: Date, stopTime: Date, whatsappGroupdId: number) {
        return axios.put(this.baseControllerUrl + '/update-convoy-time/' + locationId, {}, {
            headers: { Authorization: sessionStorage.getItem("Bearer") },
            params: {
                startTime: startTime.toLocaleString(),
                stopTime: stopTime.toLocaleString(),
                whatsappGroupId: whatsappGroupdId
            }
        });
    }
}