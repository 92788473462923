import axios from 'axios';
import { environment } from "../configuration/environment";
import { AuthServices } from "./utils/auth-services";
import { Feature } from "../pages/Features/types/types";

export class LocationFeatureServices {
    _authServices = new AuthServices();
    _controllerBaseUrl = environment().baseUrl + environment().version + '/location-feature/';

    updateLocationFeatures(locationId: number, features: Feature[]) {
        return axios.put(this._controllerBaseUrl + 'update-location-features', {
            locationId,
            features
        }, {
            headers: {
                Authorization: this._authServices.GetUserToken()
            }
        })
    }

    getLocationFeatures(locationId: number, alarmTypeFeatures: boolean) {
        return axios.get(this._controllerBaseUrl + 'features-by-location', {
            params: {
                locationId,
                alarmTypeFeatures
            },
            headers: {
                Authorization: this._authServices.GetUserToken()
            }
        })
    }

    updateLocationFeatureWhatsappNotification(locationFeatureId: number, whatsappNotification: boolean) {
        return axios.put(this._controllerBaseUrl + 'update-location-feature-whatsapp', {
            LocationFeatureId: locationFeatureId,
            WhatsAppNotification: whatsappNotification
        }, {
            headers: {
                Authorization: this._authServices.GetUserToken()
            }
        })
    }
}