import axios from "axios";
import { environment } from "../configuration/environment";
import { customAlarmInterface, customAlarmRequest, customAlarmUpdateRequest, eventsRequest } from "../pages/Custom-alarm/types/types";

const baseEndpointUrl = `${environment().version}/custom-alarm/`;

export class CustomAlarmService {
    getEvents(cameraId: number, locationId: number, vehicleTypeId: number,
         vehicleColorId: number, vehicleBrandId: number, numberOfPassengers: number) {

        var request = this.buildEventsRequest(cameraId, locationId, vehicleTypeId, vehicleColorId, 
            vehicleBrandId, numberOfPassengers);

        return axios.post(environment().baseUrl + baseEndpointUrl + "get-events", request, {
            headers: { Authorization: sessionStorage.getItem("Bearer") },
        });
    }

    getCustomAlarms() {
        return axios.get(environment().baseUrl + baseEndpointUrl, {
            headers: { Authorization: sessionStorage.getItem("Bearer") },
        });
    }

    addCustomAlarm(customAlarm: customAlarmInterface) {
        var request: customAlarmRequest = this.buildCustomAlarmRequest(customAlarm);

        return axios.post(environment().baseUrl + baseEndpointUrl, request, {
            headers: {
                Authorization: sessionStorage.getItem("Bearer"),
            },
        });
    }

    updateCustomAlarm(customAlarm: customAlarmInterface) {
        var request: customAlarmRequest = this.buildCustomAlarmRequest(customAlarm);

        return axios.put(environment().baseUrl + baseEndpointUrl + customAlarm.id, request, {
            headers: {
                Authorization: sessionStorage.getItem("Bearer"),
            },
        });
    }

    deleteCustomAlarm(customAlarmId: number) {
        return axios.delete(environment().baseUrl + baseEndpointUrl + customAlarmId, {
            headers: {
                Authorization: sessionStorage.getItem("Bearer"),
            },
        });
    }

    private buildCustomAlarmRequest(customAlarm: customAlarmInterface): customAlarmUpdateRequest {
        return {
            cameraId: customAlarm.camera.id,
            vehicleBrand: customAlarm.vehicleBrandId,
            vehicleColor: customAlarm.vehicleColorId,
            vehicleType: customAlarm.vehicleTypeId,
            isActive: customAlarm.isActive,
            numberOfPassengers: customAlarm.numberOfPassengers === 0 ? null : customAlarm.numberOfPassengers,
            whatsappGroupId: customAlarm.whatsappGroupId
        };
    }

    private buildEventsRequest(cameraId: number, locationId: number, vehicleTypeId: number,
         vehicleColorId: number, vehicleBrandId: number, numberOfPassengers: number): eventsRequest {
        const allOptionSelected = 0;
        const allVehicleTypeOptionSelected = 6;
        return {
            cameraId: cameraId === allOptionSelected ? null : cameraId,
            locationId: locationId === allOptionSelected ? null : locationId,
            vehicleBrand: vehicleBrandId === allOptionSelected ? null : vehicleBrandId,
            vehicleColor: vehicleColorId === allOptionSelected ? null : vehicleColorId,
            vehicleType: vehicleTypeId === allVehicleTypeOptionSelected ? null : vehicleTypeId,
            numberOfPassengers: numberOfPassengers === allOptionSelected ? null : numberOfPassengers
        };
    }
}
