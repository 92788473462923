import React from 'react';
import { Tooltip } from 'primereact/tooltip';
export const OutDatedIcon = (olderDate: boolean): JSX.Element => {
    return (
        <>
            <Tooltip target=".out-dated">
                <span>A data selecionada no filtro é antiga, atualização em tempo real interrompida.</span>
            </Tooltip>

            {olderDate && <i className='pi pi-info-circle text-xl out-dated' style={{color:'red'}}></i>}
        </>
    );
}