import axios from "axios";
import { environment } from "../configuration/environment";
import { UF_LIST } from "../shared/constants/states";
import { DropdownInfo } from "../models/types";
import { camera, cameraIntegration } from "../pages/Camera/types/types";
import { AuthServices } from "./utils/auth-services";

export enum INTEGRATION_LIST {
    CORTEX = 1,
    HELIOS = 2,
    ALERTA = 3,
}

export enum IMAGE_MODE_LIST {
    COMPLETA = 0,
    CARRO = 1,
    PLACA = 2,
}

export const getUFList: {
    value: string;
}[] = Object.values(UF_LIST)
    .filter((value) => typeof value === "string")
    .map((value) => ({ value: value as string }));

export const getIntegrationList: {
    value: number;
    name: string;
}[] = Object.keys(INTEGRATION_LIST)
    .filter((v) => isNaN(Number(v)))
    .map((name) => {
        return {
            value: INTEGRATION_LIST[name as keyof typeof INTEGRATION_LIST],
            name,
        };
    });

export const getImageModeList: {
    value: number;
    name: string;
}[] = Object.keys(IMAGE_MODE_LIST)
    .filter((v) => isNaN(Number(v)))
    .map((name) => {
        return {
            value: IMAGE_MODE_LIST[name as keyof typeof IMAGE_MODE_LIST],
            name,
        };
    });

interface iCameraBrands {
    name: String;
    idIntegration: Number;
}

export class CameraServices {
    _authServices = new AuthServices();

    getCameraBrands() {
        let cameraBrands = Array<iCameraBrands>();
        cameraBrands.push({
            name: "DAHUA - 120Km/h",
            idIntegration: 3,
        } as iCameraBrands);
        cameraBrands.push({
            name: "DAHUA - 80Km/h ",
            idIntegration: 1,
        } as iCameraBrands);
        cameraBrands.push({
            name: "HIKIVISION",
            idIntegration: 2,
        } as iCameraBrands);

        return cameraBrands;
    }

    getActiveCamerasByLocation = (date: Date, location: string): DropdownInfo[] => {
        let result: DropdownInfo[] = [];

        result.push({ id: 0, description: "TODAS CÂMERAS" });
        axios
            .get(environment().baseUrl + environment().version + "/dashboard/cameras-by-location", {
                params: {
                    date: date.toLocaleString("en-us", { day: "2-digit", month: "2-digit", year: "numeric" }),
                    locationId: location,
                },
                headers: {
                    Authorization: this._authServices.GetUserToken(),
                },
            })
            .then((response) => {
                response.data.forEach((camera: any) => {
                    let cameraResponse = {
                        description: camera.description,
                        id: camera.id,
                    } as DropdownInfo;

                    result.push(cameraResponse);
                });
            });

        return result;
    };

    getCamerasByLocation = (location: number): DropdownInfo[] => {
        let result: DropdownInfo[] = [];

        result.push({ id: 0, description: "TODAS CÂMERAS" });
        axios
            .get(environment().baseUrl + environment().version + "/camera/cameras-by-location", {
                params: {
                    locationId: location,
                },
                headers: {
                    Authorization: this._authServices.GetUserToken(),
                },
            })
            .then((response) => {
                response.data.forEach((camera: any) => {
                    let cameraResponse = {
                        description: camera.description,
                        id: camera.id,
                    } as DropdownInfo;

                    result.push(cameraResponse);
                });
            });

        return result;
    };

    getCamerasPointAByLocation = (location: string): DropdownInfo[] => {
        let result: DropdownInfo[] = [];

        result.push({ id: 0, description: "TODAS CÂMERAS" });
        axios
            .get(environment().baseUrl + environment().version + "/camera/cameras-point-a-by-location", {
                params: {
                    locationId: location,
                },
                headers: {
                    Authorization: this._authServices.GetUserToken(),
                },
            })
            .then((response) => {
                response.data.forEach((camera: any) => {
                    result.push(camera);
                });
            });

        return result;
    };

    getCamerasPointBByLocation = (location: string): DropdownInfo[] => {
        let result: DropdownInfo[] = [];

        result.push({ id: 0, description: "TODAS CÂMERAS" });
        axios
            .get(environment().baseUrl + environment().version + "/camera/cameras-point-b-by-location", {
                params: {
                    locationId: location,
                },
                headers: {
                    Authorization: this._authServices.GetUserToken(),
                },
            })
            .then((response) => {
                response.data.forEach((camera: any) => {
                    result.push(camera);
                });
            });

        return result;
    };

    getCamerasIntelligenceConfiguration = (location: number) => {
        return axios.get(environment().baseUrl + environment().version + "/camera/cameras-intelligence-mode", {
            params: {
                locationId: location === 0 ? null : location,
            },
            headers: { Authorization: this._authServices.GetUserToken() },
        });
    };

    setCameraIntelligenceConfiguration = (cameraId: number, convoyConfig: boolean, bikerBehaviorConfig: boolean, noPlateConfig: boolean = null, behavioralConvoyConfig: boolean = null) => {
        return axios.put(environment().baseUrl + environment().version + "/camera/set-intelligence-mode/" + cameraId, null, {
            params: {
                convoyMode: convoyConfig,
                bikerBehaviorMode: bikerBehaviorConfig,
                noPlateMode: noPlateConfig,
                behavioralConvoyMode: behavioralConvoyConfig,
            },
            headers: { Authorization: this._authServices.GetUserToken() },
        });
    };

    getCameras() {
        return axios.get(environment().baseUrl + environment().version + "/camera", {
            headers: { Authorization: this._authServices.GetUserToken() },
        });
    }

    getCameraIntegrations(cameraId: any) {
        return axios.get(environment().baseUrl + environment().version + "/camera/" + cameraId + "/integrations", {
            headers: { Authorization: this._authServices.GetUserToken() },
        });
    }

    deleteCamera(camera: any) {
        return axios.delete(environment().baseUrl + environment().version + "/camera/" + camera.id, {
            headers: { Authorization: this._authServices.GetUserToken() },
        });
    }

    createCamera(camera: any) {
        return axios.post(environment().baseUrl + environment().version + "/camera", camera, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    updateCamera(camera: any) {
        return axios.put(environment().baseUrl + environment().version + "/camera/" + camera.id, camera, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    deleteCameraIntegration(camera: camera, integration: cameraIntegration) {
        return axios.delete(environment().baseUrl + environment().version + "/camera/" + camera.id + "/integrations/" + integration.idCameraIntegracao, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    isCameraValid(camera: camera): boolean {
        let isValid: boolean = false;

        if (camera.dscCamera && camera.idLocalidade && camera.numLatitude && camera.numLongitude && camera.dscEndereco) {
            isValid = true;
        }

        return isValid;
    }
}
