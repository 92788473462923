import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { cardResponse, integrationCardResponse } from '../../../../models/types';
import '../../styles/card.scss';
import { CameraCount } from './camera-count-data';
import { EventsCardsData } from './events-cards-data';
import { IntegrationCount } from './integration-count-data';
import { VehicleType } from './vehicle-type';
import { Accordion, AccordionTab } from 'primereact/accordion';

export interface LiveCardProps {
    isCardLoading: boolean,
    isIntegrationCardLoading: boolean,
    isOutDated: boolean,
    cardResponse: cardResponse,
    integrationResponseHelios: integrationCardResponse,
    integrationResponseCortex: integrationCardResponse,
    integrationResponseAlerta: integrationCardResponse,
}

export const LiveCard = (props: LiveCardProps) => {

    const renderSkeletonCardValue = <Skeleton shape="rectangle" width='100px' height='30px' />;

    const renderCardValues = (value: string) => {
        return props.isCardLoading ? renderSkeletonCardValue :
            <div className="text-900 text-4xl">
                {value ?? 0}
            </div>
    }

    const renderIntegrationCardValues = (value: string) => {
        return props.isIntegrationCardLoading ? renderSkeletonCardValue :
            <div className="text-900 text-4xl">
                {value ?? 0}
            </div>
    }

    const headerFilterFieldset = <>
        <span className="titulo-categoria">
            <i className='pi pi-info-circle mr-2'></i>
            Informações de Integrações
        </span>
    </>

    return (
        <>
            <div className="col-12 lg:col-12 xl:col-12">
                <Accordion>
                    <AccordionTab header={headerFilterFieldset}>
                        <div className="grid">
                            <CameraCount
                                integrationCardResponseHelios={props.integrationResponseHelios}
                                integrationCardResponseCortex={props.integrationResponseCortex}
                                integrationCardResponseAlerta={props.integrationResponseAlerta}
                                renderCardValues={renderIntegrationCardValues}
                            />
                            <IntegrationCount
                                integrationCardResponseHelios={props.integrationResponseHelios}
                                integrationCardResponseCortex={props.integrationResponseCortex}
                                integrationCardResponseAlerta={props.integrationResponseAlerta}
                                isIntegrationLoading={props.isIntegrationCardLoading}
                                isOutDated={props.isOutDated}
                                renderCardValues={renderIntegrationCardValues}
                            />
                        </div>
                    </AccordionTab>
                </Accordion>
            </div >
            <VehicleType
                cardResponse={props.cardResponse}
                isOutDated={props.isOutDated}
                isLoading={props.isCardLoading}
            />
            <EventsCardsData
                cardResponse={props.cardResponse}
                renderCardValues={renderCardValues}
                isLoading={props.isCardLoading}
            />
        </>
    )
}
