import { PickList } from 'primereact/picklist';
import React from 'react';
import { DropdownInfo } from '../../../../models/types';

export interface FeaturePickListProps {
    features: DropdownInfo[],
    locationFeatures: DropdownInfo[],
    onChange: (e: any) => void,
}

const itemTemplate = (item: DropdownInfo) => {
    return (
        <div className="flex flex-wrap p-2 align-items-center gap-3">
            <i className="pi pi-bolt text-sm"></i>
            <span className="font-bold">{item.description}</span>
            <span className="font-bold text-900">

            </span>
        </div>
    );
};
export const FeaturePickList = (props: FeaturePickListProps) => {
    return (

            <PickList 
            dataKey="id" 
            source={props.features}
            target={props.locationFeatures}
            onChange={props.onChange}
            itemTemplate={itemTemplate}
            filter 
            filterBy="description" 
            breakpoint="1280px"
                sourceHeader="Available"
                 targetHeader="Selected" sourceStyle={{ height: '24rem' }} targetStyle={{ height: '24rem' }}
    sourceFilterPlaceholder="Search by name" targetFilterPlaceholder="Search by name"
     />

        // <PickList
        //     source={props.features}
        //     target={props.locationFeatures}
        //     onChange={props.onChange}
        //     dataKey='xpto'
        //     itemTemplate={itemTemplate}
        //     breakpoint="10px"
        //     sourceHeader="Recursos Disponíveis"
        //     targetHeader="Recursos Selecionados"
        //     sourceStyle={{ height: '40rem' }}
        //     targetStyle={{ height: '40rem' }}
        // />
    );
}