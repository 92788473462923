import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import React from 'react';
import { blackListItem } from '../../types/types';
import { Tag } from 'primereact/tag';

export interface BlackListDataTableProps {
    loading: boolean,
    events: blackListItem[],
    selectedEvents: blackListItem[],
    globalFilter: string,
    setGlobalFilter: (val: any) => void,
    openNew: () => void,
    confirmDeleteSelected: () => void,
    setSelectedEvents: (selectedEvents: blackListItem[]) => void,
    editEventReason: (event: blackListItem) => void,
    confirmDeleteEvent: (event: blackListItem) => void,
}

export const BlackListDataTable = (props: BlackListDataTableProps) => {
    const header = (
        <div className="table-header">
            <h5 className="p-m-2">Eventos</h5>
        </div>
    )

    const plateBodyTemplate = (rowData: blackListItem) => {
        return <span>{rowData.plate}</span>;
    }
    const reasonBodyTemplate = (rowData: blackListItem) => {
        return (<>{rowData.reason}</>);
    }
    const descriptionBodyTemplate = (rowData: blackListItem) => {
        return (<>{rowData.description}</>);
    }
    const activeBodyTemplate = (rowData: blackListItem) => {
        if (rowData.active === true) {
            return <Tag severity="success" value="Ativa" icon="pi pi-check" />;
        }

        return <Tag severity="danger" value="Inativa" icon="pi pi-times" />;
    }
    const locationBodyTemplate = (rowData: blackListItem) => {
        return (<>{rowData.locationName}</>);
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nova Black-List" icon="pi pi-plus" className="p-button-success mr-2" onClick={props.openNew} />
                    <Button label="Excluir Selecionados" icon="pi pi-trash" className="p-button-danger" onClick={props.confirmDeleteSelected} disabled={!props.selectedEvents || !props.selectedEvents.length} />
                </div>
            </React.Fragment>
        )
    }
    const actionBodyTemplate = (rowData: blackListItem) => {
        return (
            <div className="actions my-2">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button mr-2" onClick={() => props.editEventReason(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => props.confirmDeleteEvent(rowData)} />
            </div>
        );
    }
    const rightToolbarTemplate = () => {
        return (
            <>
                <React.Fragment>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={props.globalFilter}
                            onInput={(e: any) => props.setGlobalFilter(e.target.value)}
                            placeholder="Procurar..."
                        />
                    </span>
                </React.Fragment>
            </>
        )
    }

    return (
        <React.Fragment>
            <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            <DataTable
                value={props.events}
                selection={props.selectedEvents}
                onSelectionChange={(e: any) => props.setSelectedEvents(e.value as blackListItem[])}
                dataKey="idBlackList"
                paginator
                rows={10}
                selectionMode='multiple'
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Placas"
                globalFilter={props.globalFilter}
                emptyMessage="Nenhum evento Encontrado."
                header={header}
                loading={props.loading}>
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column field="plate" header="Placa" sortable body={plateBodyTemplate}></Column>
                <Column field="reason" header="Razão" sortable body={reasonBodyTemplate}></Column>
                <Column field="description" header="Descrição" sortable body={descriptionBodyTemplate}></Column>
                <Column field="locationName" header="Localidade" sortable body={locationBodyTemplate}></Column>
                <Column field="locationName" header="Ativa" sortable body={activeBodyTemplate}></Column>
                <Column body={actionBodyTemplate}></Column>
            </DataTable>
        </React.Fragment>
    )
}
