import classNames from "classnames";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import { DropdownInfo } from "../../../../models/types";
import { ToastServices } from "../../../../services/utils/toast-services";
import { getUFList } from "../../../../services/camera-services";
import { DSMApplicationServices } from "../../../../services/dsm-application-services";
import { emptyLocation, location } from "../../types/types";

export interface SelectDSMApplicationLocationDialogProps {
    visible: boolean;
    closeDialog: () => void;
    setLocation: (location: location) => void;
    setSelectedLocations:(locations: DropdownInfo[]) => void;
    isEditing: boolean;
    setState: (state: string) => void;
}

export const SelectDSMApplicationLocationDialog = (props: SelectDSMApplicationLocationDialogProps) => {
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [selectedState, setSelectedState] = useState<string>('');
    const [selectedLocation, setSelectedLocation] = useState<location>(emptyLocation);

    const dsmApplicationService = new DSMApplicationServices();
    const _toastService = new ToastServices();

    const addNewLocation = () => {
        setSubmitted(true);

        if (selectedLocation) {
            props.setState(selectedState);
            props.setLocation(selectedLocation);
            props.closeDialog();
            setSubmitted(false);
            setSelectedState(null);
            setSelectedLocation(emptyLocation);
            setLocations([])
        }
    };

    const newLocationDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={props.closeDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={(e) => addNewLocation()} />
        </>
    );

    const updateCitiesByState = (e: any) => {
        const stateName = e.target.value;

        setSelectedState(stateName);

        dsmApplicationService.getCitiesByState(stateName).then(
            (response) => {
                setLocations(response.data)
                props.setSelectedLocations(response.data)
            },
            (error: any) => _toastService.showErrorRequestMessage(error));
    };

    const onDropdownChange = (e: any) => {
        const locationDropdownObject = locations.find((x) => x.id === e.target.value);
        const locationObject = {
            locationId: locationDropdownObject.id,
            locationName: locationDropdownObject.description,
        } as location;

        setSelectedLocation(locationObject);

    };

    return (
        <>
            <Dialog visible={props.visible} footer={newLocationDialogFooter} onHide={props.closeDialog} style={{ width: "35rem" }} header="NOVA LOCALIDADE" modal className="p-fluid">
                <div className="grid">
                    <div className="p-field col-6">
                        <strong>Estado:</strong>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-dsmApplication"></i>
                            </span>
                            <Dropdown optionLabel="value"
                                optionValue="value"
                                options={getUFList}
                                placeholder="Estado"
                                value={selectedState}
                                onChange={(e: any) => updateCitiesByState(e)}
                                className={classNames({ "p-invalid": submitted && !selectedState })}
                            />
                        </div>
                        {submitted && !selectedState && <small className="p-invalid">Selecione um Estado.</small>}
                    </div>
                    <div className="p-field col-6">
                        <strong>Cidade:</strong>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-dsmApplication"></i>
                            </span>
                            <Dropdown
                                emptyMessage="Necessário Selecionar um Estado"
                                optionLabel="description"
                                optionValue="id"
                                options={locations}
                                placeholder="Cidade"
                                value={selectedLocation.locationId}
                                onChange={(e: any) => onDropdownChange(e)}
                                className={classNames({ "p-invalid": submitted && selectedLocation === emptyLocation })}
                            />
                        </div>
                        {submitted && selectedLocation === emptyLocation && <small className="p-invalid">Selecione uma Cidade.</small>}
                    </div>
                </div>
            </Dialog>
        </>
    );
};
