import { Dialog } from 'primereact/dialog';
import React from 'react';
import { CustomAlarmAlertResponse } from '../../../../models/types';
import { vehicleBrand } from '../../../../shared/constants/vehicle-brand';
import { vehicleColor } from '../../../../shared/constants/vehicle-color';
import { vehicleType } from '../../../../shared/constants/vehicle-type';
import '../../styles/point-AB-alert-dialog.scss';

export interface CustomAlarmDialogProps {
    customAlarmAlertDialog: boolean,
    customAlarmAlert: CustomAlarmAlertResponse,
    setCustomAlarmAlertDialog: (state: boolean) => void
}

export const CustomAlarmAlertDialog = (props: CustomAlarmDialogProps) => {
    return (
        <Dialog visible={props.customAlarmAlertDialog} className='point-ab-dialog' resizable={false} header='ALERTA DE ALARME CUSTOMIZADO DETECTADO' onHide={() => props.setCustomAlarmAlertDialog(false)}>
            <div className='card'>
                <div className='custom-alarm-plate col-12 flex justify-content-center custom-alarm-plate-div'>
                    <i className="pi pi-tag" />
                    <strong className='margin-auto ml-2'>{props.customAlarmAlert.plate}</strong>
                </div>
                <div className='flex col-12'>
                    <div className='grid col-12'>
                        <div className='col-12'>
                            <div className='grid'>
                                <div className='col-12'>
                                    <div className="flex justify-content-center">
                                        <img className='custom-alarm-image' src={props.customAlarmAlert.imageUrl} alt={props.customAlarmAlert.plate}></img>
                                    </div>
                                </div>
                                <div className='col-12 title-message justify-content-center flex'>
                                    {props.customAlarmAlert.cameraName}
                                </div>
                                <div className='col-8 '>
                                    <i className="pi pi-map" />
                                    <span className='ml-2 custom-alarm-text'>{props.customAlarmAlert.cameraAddress}</span>
                                </div>
                                <div className='col-4'>
                                    <i className="pi pi-clock" />
                                    <span className='ml-2 custom-alarm-text'>{props.customAlarmAlert.dateTime}</span>
                                </div>
                                <div className='col-4'>
                                    <i className="pi pi-fw pi-chart-bar" />
                                    <strong className="ml-2 ">Tipo de veículo:</strong>
                                    <span className='custom-alarm-text__Parameter'>{props.customAlarmAlert.vehicleTypeId === 6 ? "TODOS" :
                                        vehicleType[props.customAlarmAlert.vehicleTypeId]}</span>
                                </div>
                                <div className='col-4'>
                                    <i className="pi pi-fw pi-chart-bar" />
                                    <strong className="ml-2">Marca do veículo:</strong>
                                    <span className='custom-alarm-text__Parameter'>{props.customAlarmAlert.vehicleBrandId === 0 ? "TODAS" :
                                        vehicleBrand[props.customAlarmAlert.vehicleBrandId]}</span>
                                </div>
                                <div className='col-4'>
                                    <i className="pi pi-fw pi-chart-bar" />
                                    <strong className="ml-2">Cor do veículo:</strong>
                                    <span className='custom-alarm-text__Parameter'>{props.customAlarmAlert.vehicleColorId === 0 ? "TODAS" :
                                        vehicleColor[props.customAlarmAlert.vehicleColorId]}</span>
                                </div>
                                {props.customAlarmAlert.vehicleTypeId == vehicleType.MOTO &&
                                    <div className='col-6'>
                                        <i className="pi pi-fw pi-chart-bar" />
                                        <strong className="ml-2">Número de pessoas:</strong>
                                        <span className='custom-alarm-text__Parameter'>{props.customAlarmAlert.numberOfPassengers === null ?
                                         "QUALQUER" : props.customAlarmAlert.numberOfPassengers}</span>
                                    </div>
                                    }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
