import { useEffect, useState } from "react";
import { ToastServices } from "../../../../services/utils/toast-services";
import React from "react";
import { DsmListDataTable } from "../data-tables/dsm-list-data-table";
import { applicationResponse } from "../../types/types";
import { AuthServices } from "../../../../services/utils/auth-services";
import { MonitoringServices } from "../../../../services/monitoring-services";
import { DsmBodyProps } from "../../interfaces/DsmBodyProps";


export const DsmBodyRED = (props: DsmBodyProps) => {
    const _toastServices = new ToastServices();
    const _monitoringServices = new MonitoringServices();
    const _authServices = new AuthServices();

    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedLocations, setSelectedLocations] = useState<applicationResponse[]>([]);

    const [locations, setLocations] = useState<[]>([]);
    const [recent, setRecent] = useState<[]>([]);

    _authServices.HandleAppAuth();

    const loadData = async () => {
        try {
            const locationsData = await _monitoringServices.getLocationsByFeature("RED");
            setLocations(locationsData.data);
        } catch (error) {
            console.log(error);
            _toastServices.showErrorRequestMessage(error);
        }
        try {
            const recentData = await _monitoringServices.getApplicationRecent("RED");
            setRecent(recentData.data);
        } catch (error) {
            console.log(error);
            _toastServices.showErrorRequestMessage(error);
        }
    };

    const loadDataInitial = async () => {
        props.setLoading(true);
        try {
            const locationsData = await _monitoringServices.getLocationsByFeature("RED");
            setLocations(locationsData.data);
        } catch (error) {
            console.log(error);
            _toastServices.showErrorRequestMessage(error);
        } finally {
            props.setLoading(false);
        }
        try {
            const recentData = await _monitoringServices.getApplicationRecent("RED");
            setRecent(recentData.data);
        } catch (error) {
            console.log(error);
            _toastServices.showErrorRequestMessage(error);
        } finally {
            props.setLoading(false);
        }
    };

    useEffect(() => {
        loadDataInitial();
        // Set a timeout for 10 seconds, then start the interval
        const timeoutId = setTimeout(() => {
            const intervalId = setInterval(() => {
                loadData();
            }, 10000); // 10 segundos

            // Cleanup interval on unmount
            return () => clearInterval(intervalId);
        }, 10000); // 10 segundos

        // Cleanup timeout on unmount
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const confirmDeleteSelected = () => { };
    const confirmDeleteLocation = () => { };
    const editLocation = () => { };
    const openNew = () => { };

    return (
        <React.Fragment>
            <DsmListDataTable
                confirmDeleteSelected={confirmDeleteSelected}
                openNew={openNew}
                setGlobalFilter={setGlobalFilter}
                locations={locations}
                recent={recent}
                selectedLocations={selectedLocations}
                setSelectedLocations={setSelectedLocations}
                loading={props.loading}
                globalFilter={globalFilter}
                editLocation={editLocation}
                confirmDeleteLocation={confirmDeleteLocation}
            />
        </React.Fragment>
    );
};
