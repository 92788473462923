import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React from 'react';

export interface DeleteCamerasDialogProps {
    deleteCamerasDialog: boolean,
    hideDeleteCamerasDialog: () => void,
    deleteSelectedCameras: () => void
}

export const DeleteCamerasDialog = (props: DeleteCamerasDialogProps) => {
    const deleteCamerasDialogFooter = (
        <>
            <Button
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={props.hideDeleteCamerasDialog}
            />
            <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={props.deleteSelectedCameras}
            />
        </>
    )

    return (
        <Dialog visible={props.deleteCamerasDialog} style={{ width: '700px' }} header="Confirm" modal footer={deleteCamerasDialogFooter} onHide={props.hideDeleteCamerasDialog}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle mr-2" style={{ fontSize: '2rem' }} />
                {<span>Tem certeza que deseja apagar as cameras selecionadas?</span>}
            </div>
        </Dialog>
    )
}