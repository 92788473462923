import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthServices } from "../../../services/utils/auth-services";
import { ToastServices } from "../../../services/utils/toast-services";
import { PageHeader } from "../../../views/page-header";
import { CustomAlarmService } from "../../../services/custom-alarm-service";
import { customAlarmInterface, emptyCustomAlarm } from "../types/types";
import { CreateCustomAlarmDialog } from "./create-custom-alarm-dialog";
import { CustomAlarmListDataTable } from "./custom-alarm-list-data-table";
import { DeleteCustomAlarmDialog } from "./delete-custom-alarm-dialog";
import { DeleteCustomAlarmsDialog } from "./delete-custom-alarms-dialog";


export const CustomAlarm = () => {
    const _toastService = new ToastServices();
    const _authServices = new AuthServices();
    const [customAlarms, setCustomAlarms] = useState([]);
    const [customAlarm, setCustomAlarm] = useState<customAlarmInterface>(emptyCustomAlarm);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [customAlarmDialog, setCustomAlarmDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedCustomAlarms, setSelectedCustomAlarms] = useState(null);
    const [deleteCustomAlarmDialog, setDeleteCustomAlarmDialog] = useState(false);
    const [deleteCustomAlarmsDialog, setDeleteCustomAlarmsDialog] = useState(false);
    const [dialogHeader, setDialogHeader] = useState<string>(null);
    const [isEditingCustomAlarm, setIsEditingCustomAlarm] = useState<boolean>(null);
    const customAlarmService = new CustomAlarmService();

    _authServices.HandleAppAuth();

    useEffect(() => {
        getCustomAlarms();
    }, []);

    const getCustomAlarms = () => {
        setLoading(true);
        customAlarmService.getCustomAlarms().then(
            (response) => {
                setCustomAlarms(response.data);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                _toastService.showErrorRequestMessage(error);
            }
        );
    }

    const openNew = () => {
        setCustomAlarm(emptyCustomAlarm);
        setDialogHeader("Cadastrar Novo Alarme Customizado");
        setIsEditingCustomAlarm(false);
        setCustomAlarmDialog(true);
        setSubmitted(false);
    }

    const hideDialog = () => {
        setLoading(false);
        setSubmitted(false);
        setCustomAlarmDialog(false);
    }

    const editCustomAlarm = (customAlarm: customAlarmInterface) => {
        setCustomAlarm(customAlarm);
        setDialogHeader("Editar Alarme Customizado: " + customAlarm.camera.description);
        setCustomAlarmDialog(true);
        setIsEditingCustomAlarm(true);
        setSubmitted(false);
    }

    const confirmDeleteCustomAlarm = (customAlarm: customAlarmInterface) => {
        setCustomAlarm(customAlarm);
        setDeleteCustomAlarmDialog(true);
    }

    const confirmDeleteSelected = () => {
        setDeleteCustomAlarmsDialog(true);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCustomAlarms || !selectedCustomAlarms.length} />
                </div>
            </React.Fragment>
        );
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                <React.Fragment>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText type="search" value={globalFilter} onInput={(e: any) => setGlobalFilter(e.target.value)} placeholder="Procurar Alarmes..." />
                    </span>
                </React.Fragment>
            </>
        );
    }

    const onSubmit = () => {
        setSubmitted(true);
        if (isCustomAlarmValid()) {

            if (customAlarm.id) {
                customAlarmService.updateCustomAlarm(customAlarm).then(
                    () => {
                        _toastService.showSuccesMessage("Alarme Customizado Atualizado com Sucesso.");
                        getCustomAlarms();
                    },
                    (error) => {
                        _toastService.showErrorMessage(error.response.data);
                        getCustomAlarms();
                    });
            } else {
                customAlarmService.addCustomAlarm(customAlarm).then(
                    () => {
                        _toastService.showSuccesMessage("Alarme Customizado Cadastrado com Sucesso.");
                        getCustomAlarms();
                    },
                    (error) => {
                        _toastService.showErrorMessage(error.response.data);
                        getCustomAlarms();
                    });
            }
            setCustomAlarm(emptyCustomAlarm);
            setCustomAlarmDialog(false);
        }
        setLoading(false);
    }

    const hasLessThanTwoParametersSelected = (): boolean => {
        let parametersSelected = 0;
        const allOptionSelected = 0;
        const allVehicleTypeOptionSelected = 6;

        if (customAlarm.vehicleTypeId !== allVehicleTypeOptionSelected) {
            parametersSelected += 1;
        }
        if (customAlarm.vehicleColorId !== allOptionSelected) {
            parametersSelected += 1;
        }
        if (customAlarm.vehicleBrandId !== allOptionSelected) {
            parametersSelected += 1;
        }
        if (customAlarm.numberOfPassengers !== allOptionSelected) {
            parametersSelected += 1;
        }

        var minAllowedParametersSelected = 2;

        return parametersSelected < minAllowedParametersSelected;
    };

    const isCustomAlarmValid = () => {
        return customAlarm.camera.id !== 0 && !hasLessThanTwoParametersSelected();
    }

    return (
        <div className="grid">
            <div className="col-12">
                <PageHeader title="CONFIGURAÇÃO ALARME CUSTOMIZADO" icon="pi pi-chart-bar" />
                <div className="card">
                    <Toolbar
                        className="p-mb-4"
                        left={leftToolbarTemplate}
                        right={rightToolbarTemplate}
                    />

                    <CustomAlarmListDataTable
                        customAlarms={customAlarms}
                        selectedCustomAlarms={selectedCustomAlarms}
                        setSelectedCustomAlarm={setSelectedCustomAlarms}
                        loading={loading}
                        globalFilter={globalFilter}
                        editCustomAlarm={editCustomAlarm}
                        confirmDeleteCustomAlarm={confirmDeleteCustomAlarm}
                    />

                    <CreateCustomAlarmDialog
                        customAlarm={customAlarm}
                        setCustomAlarm={setCustomAlarm}
                        setCustomAlarmDialog={setCustomAlarmDialog}
                        onSubmit={onSubmit}
                        setSubmitted={setSubmitted}
                        isEditingCustomAlarm={isEditingCustomAlarm}
                        visible={customAlarmDialog}
                        hideDialog={hideDialog}
                        submitted={submitted}
                        dialogHeader={dialogHeader}
                        setLoading={setLoading}
                        hasLessThanTwoParametersSelected={hasLessThanTwoParametersSelected}
                    />

                    <DeleteCustomAlarmDialog
                        setLoading={setLoading}
                        emptyCustomAlarm={emptyCustomAlarm}
                        visible={deleteCustomAlarmDialog}
                        setDeleteCustomAlarmDialog={setDeleteCustomAlarmDialog}
                        customAlarm={customAlarm}
                        setCustomAlarm={setCustomAlarm}
                        getCustomAlarms={getCustomAlarms} />

                    <DeleteCustomAlarmsDialog
                        setLoading={setLoading}
                        getCustomAlarms={getCustomAlarms}
                        selectedCustomAlarms={selectedCustomAlarms}
                        customAlarm={customAlarm}
                        setDeleteCustomAlarmsDialog={setDeleteCustomAlarmsDialog}
                        setSelectedCustomAlarms={setSelectedCustomAlarms}
                        visible={deleteCustomAlarmsDialog} />
                </div>
            </div>
        </div>
    )
};
