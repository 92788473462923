import classNames from "classnames";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import { DropdownInfo } from "../../../../models/types";
import { ToastServices } from "../../../../services/utils/toast-services";
import { getUFList } from "../../../../services/camera-services";
import { UserServices } from "../../../../services/user-services";
import { allowedLocations, emptyLocation } from "../../../Login/types/types";

export interface CreateUserLocationDialogProps {
    visible: boolean;
    closeDialog: () => void;
    allowedUserLocations: allowedLocations[];
    isEditing: boolean;
}

export const CreateUserLocationDialog = (props: CreateUserLocationDialogProps) => {
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [selectedState, setSelectedState] = useState<string>(null);
    const [selectedLocation, setSelectedLocation] = useState<allowedLocations>(emptyLocation);

    const userService = new UserServices();
    const _toastService = new ToastServices();
    const addLocationToAllowedUserLocations = () => {
        props.allowedUserLocations.push(selectedLocation);
    };

    const sortAllowedUserLocations = () => {
        const propertyName: string = "locationName";
        props.allowedUserLocations.sort((a: any, b: any) => {
            if (a[propertyName] < b[propertyName]) return -1;
            if (a[propertyName] > b[propertyName]) return 1;
            return 0;
        });
    };

    const addNewLocation = () => {
        setSubmitted(true);

        if (selectedLocation.locationId) {
            props.closeDialog();
            setSubmitted(false);

            addLocationToAllowedUserLocations();
            sortAllowedUserLocations();
            setSelectedLocation(emptyLocation);
            setSelectedState(null);
            setLocations([])
        }
    };

    const newLocationDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={props.closeDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={(e) => addNewLocation()} />
        </>
    );

    const updateCitiesByState = (e: any) => {
        const stateName = e.target.value;

        setSelectedState(stateName);
        userService.getCitiesByState(stateName).then(
            (response) => {
                const allowedUserLocationsIds = props.allowedUserLocations.map((x) => x.locationId);
                setLocations(response.data.filter((x: any) => !allowedUserLocationsIds.includes(x.id)));
            },
            (error: any) => _toastService.showErrorRequestMessage(error));
    };

    const onDropdownChange = (e: any) => {
        const locationDropdownObject = locations.find((x) => x.id === e.target.value);
        const locationObject = {
            locationId: locationDropdownObject.id,
            locationName: locationDropdownObject.description,
        } as allowedLocations;

        setSelectedLocation(locationObject);
    };

    return (
        <>
            <Dialog visible={props.visible} footer={newLocationDialogFooter} onHide={props.closeDialog} style={{ width: "35rem" }} header="NOVA LOCALIDADE" modal className="p-fluid">
                <div className="grid">
                    <div className="p-field col-6">
                        <strong>Estado:</strong>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-user"></i>
                            </span>
                            <Dropdown optionLabel="value"
                                optionValue="value"
                                options={getUFList}
                                placeholder="Estado"
                                value={selectedState}
                                onChange={(e: any) => updateCitiesByState(e)}
                                className={classNames({ "p-invalid": submitted && !selectedState })}
                            />
                        </div>
                        {submitted && !selectedState && <small className="p-invalid">Selecione um Estado.</small>}
                    </div>
                    <div className="p-field col-6">
                        <strong>Cidade:</strong>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-user"></i>
                            </span>
                            <Dropdown
                                emptyMessage="Necessário Selecionar um Estado"
                                optionLabel="description"
                                optionValue="id"
                                options={locations}
                                placeholder="Cidade"
                                value={selectedLocation.locationId}
                                onChange={(e: any) => onDropdownChange(e)}
                                className={classNames({ "p-invalid": submitted && selectedLocation === emptyLocation })}
                            />
                        </div>
                        {submitted && selectedLocation === emptyLocation && <small className="p-invalid">Selecione uma Cidade.</small>}
                    </div>
                </div>
            </Dialog>
        </>
    );
};
