import React from 'react';
import { blackListItem } from '../../types/types';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

export interface deleteEventsDialogProps {
    visible: boolean,
    hideDialog: () => void,
    event: blackListItem,
    deleteEvents: () => void
}
export const DeleteEventsDialog = (props: deleteEventsDialogProps) => {
    const deleteEventsDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={props.deleteEvents} />
        </>
    );
    return (
        <Dialog visible={props.visible} style={{ width: '450px' }} header="Confirm" modal footer={deleteEventsDialogFooter} onHide={props.hideDialog}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                {props.event && <span>Tem certeza que deseja deletar os eventos selecionados?</span>}
            </div>
        </Dialog>
    );
}