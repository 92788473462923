import { Dialog } from 'primereact/dialog';
import React from 'react';
import { blackListResponse } from '../../../../models/types';

export interface BlackListDialogProps {
    blackListDialog: boolean,
    blackListAlert: blackListResponse,
    setBlackListDialog: () => void
}

export const BlackListDialog = (props: BlackListDialogProps) => {

    return (<>
        <Dialog visible={props.blackListDialog} style={{ width: "600px" }} header="ALERTA BLACK LIST" modal onHide={() => props.setBlackListDialog()}>
            <div className="confirmation-content">
                <div className="col-12">
                    <div style={{ display: `flex` }}>
                        <h5 style={{ fontWeight: `bold`, width: `50%`, margin: `0 0 0 0`, padding: `0px` }}>Placa: {props.blackListAlert.plate}</h5>
                        <h5 style={{ fontWeight: `bold`, width: `50%`, margin: `0 0 0 0`, padding: `0px` }}>Data: {props.blackListAlert.dateTime}</h5>
                    </div>
                    <div style={{ display: `flex` }}>
                        <h5 style={{ width: `50%`, margin: `10px 0 0 0`, padding: `0px` }}>Camera: {props.blackListAlert.cameraName}</h5>
                        <h5 style={{ width: `50%`, margin: `10px 0 0 0`, padding: `0px` }}>Razão: {props.blackListAlert.reason}</h5>
                    </div>
                    <div style={{ display: `flex` }}>
                        <h5 style={{ width: `100%`, margin: `10px 0 0 0`, padding: `0px` }}>
                            Endereço: {props.blackListAlert.address} { }
                        </h5>
                    </div>
                    <div style={{ display: `flex` }}>
                        <h5 style={{ width: `100%`, margin: `10px 0 0 0`, padding: `0px`, display: `${props.blackListAlert.description ? `inherit` : `none`}` }}>Descrição: {props.blackListAlert.description}</h5>
                    </div>
                    <div></div>
                </div>
                <div className="col-12">
                    <img src={props.blackListAlert.imageUrl} style={{ width: `100%`, height: `30rem`, borderRadius: `5px` }} />
                </div>
            </div>
        </Dialog>
    </>)
}