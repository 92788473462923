import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { CSSProperties, useState } from "react";
import { DropdownInfo } from "../../../../models/types";
import { onCheckedChange, onInputChange } from "../../../../services/utils/input-services";
import { whatsappRecipient } from "../../types/types";

export interface CreateRecipientDialogProps {
    locationGroups: DropdownInfo[],
    visible: boolean;
    closeDialog: () => void;
    dialogHeader: string;
    submitted: boolean;
    saveRecipient: () => void;
    recipient: whatsappRecipient;
    setRecipient: (recipient: whatsappRecipient) => void;
    locationId: number;
}

export const CreateRecipientDialog = (props: CreateRecipientDialogProps) => {
    const [showNewGroupInput, setShowNewGroupInput] = useState<boolean>(false);

    const setWhatsappGroupIdNull = () => {
        let recipient = { ...props.recipient } as any;

        recipient[`whatsappGroupId`] = null;

        props.setRecipient(recipient);
    }

    const createNewGroup = () => {
        setWhatsappGroupIdNull();
        setShowNewGroupInput(true)
    }

    const recipientDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={props.closeDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={(e) => props.saveRecipient()} />
        </>
    );
    const dialogStyle: CSSProperties = {
        width: "700px",
    };

    return (
        <Dialog visible={props.visible} onHide={props.closeDialog} style={dialogStyle} resizable={false} footer={recipientDialogFooter} header={props.dialogHeader}>
            <span className="titulo-categoria">GRUPO:</span>
            <div className="categoria">
                <div className="grid">
                    <div className="col-6">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-users"></i>
                            </span>
                            <Dropdown
                                emptyMessage="Nenhuma Opção Encontrada"
                                optionLabel="description"
                                optionValue="id"
                                options={props.locationGroups}
                                placeholder="Selecionar grupo"
                                value={props.recipient.whatsappGroupId}
                                onChange={(e) => onInputChange(e, 'whatsappGroupId', props.recipient, props.setRecipient)}
                                className={classNames({ "p-invalid": props.submitted && !props.recipient.whatsappGroupId })}
                            />
                            <Button
                                icon='pi pi-plus'
                                className="p-button-success ml-0"
                                label="Novo Grupo"
                                onClick={(e) => createNewGroup()}
                            />
                        </div>
                    </div>

                    {showNewGroupInput && props.recipient.whatsappGroupId === null &&
                        <div className="col-6">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-fw pi-pencil"></i>
                                </span>
                                <InputText
                                    required
                                    placeholder="Nome do Grupo"
                                    value={props.recipient.whatsappGroupName}
                                    onChange={(e) => onInputChange(e, "whatsappGroupName", props.recipient, props.setRecipient)}
                                    className={classNames({ "p-invalid": props.submitted && !props.recipient.whatsappGroupName })}
                                />
                            </div>
                            {props.submitted && !props.recipient.whatsappGroupName && <small className="p-invalid">Insira um nome para o grupo</small>}
                        </div>
                    }
                </div>
            </div>
            <span className="titulo-categoria">CONTATO:</span>
            <div className="categoria">
                <div className="grid">
                    <div className="p-field col-6">
                        <strong>Nome do recipiente:</strong>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-user"></i>
                            </span>
                            <InputText value={props.recipient.recipientName} required onChange={(e) => onInputChange(e, "recipientName", props.recipient, props.setRecipient)} placeholder="Nome do Recipiente" className={classNames({ "p-invalid": props.submitted && !props.recipient.recipientName })} />
                        </div>
                        {props.submitted && !props.recipient.recipientName && <small className="p-invalid">Insira um nome para o recipiente</small>}
                    </div>
                    <div className="p-field col-4">
                        <strong>Número do recipiente:</strong>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-whatsapp"></i>
                            </span>
                            <InputMask
                                id="phone"
                                mask="+55 (99) 99999-9999"
                                type="tel"
                                placeholder="+99 (99) 99999-9999"
                                value={props.recipient.recipientCode}
                                className={classNames({ "p-invalid": props.submitted && !props.recipient.recipientCode })}
                                onChange={(e) => onInputChange(e, "recipientCode", props.recipient, props.setRecipient)}
                            />
                        </div>
                        <small className="p-invalid">Exemplo: +99 (99) 99999-9999</small>
                        <br />
                        {props.submitted && !props.recipient.recipientCode && <small className="p-invalid">Insira um nome para o recipiente</small>}
                    </div>
                    <div className="p-field col-2">
                        <strong>Ativo:</strong>
                        <div className="p-inputgroup">
                            <Checkbox checked={props.recipient.active} onChange={(e) => onCheckedChange(e, "active", props.recipient, props.setRecipient)} />
                        </div>
                    </div>
                </div>
            </div>
        </Dialog >
    );
};
