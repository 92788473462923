import classNames from "classnames";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Toolbar } from "primereact/toolbar";
import React, { useState } from "react";
import { onCheckedChange, onInputChange } from "../../../../services/utils/input-services";
import { user } from "../../../Login/types/types";
import { UserLocationsDataTable } from "../data-tables/user-locations-data-table";
import "./../../styles/user.scss";
import { CreateUserLocationDialog } from "./create-user-location-dialog";

export interface CreateUserDialogProps {
    user: user;
    setUser: (user: user) => void;
    setUserDialog: (showUserDialog: boolean) => void;
    isEditingUser: boolean;
    visible: boolean;
    submitted: boolean;
    setSubmitted: (value: boolean) => void;
    onSubmit: () => void;
    hideDialog: () => void;
    dialogHeader: string;
}

export const CreateUserDialog = (props: CreateUserDialogProps) => {
    const [showNewLocationDialog, setShowNewLocationDialog] = useState<boolean>(false);

    const userDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={(e) => props.onSubmit()} />
        </>
    )

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-">
                    <Button label="Nova Localidade" icon="pi pi-plus" className="p-button-success" onClick={(e) => openNewLocationDialog()} />
                </div>
            </React.Fragment>
        );
    }

    const closeNewLocationDialog = () => {
        setShowNewLocationDialog(false);
    }

    const openNewLocationDialog = () => {
        setShowNewLocationDialog(true);
    }

    const deleteLocation = (rowData: any) => {
        let locations = props.user.allowedLocations.filter((x) => x.locationId !== rowData.locationId);

        let _user = { ...props.user } as any;
        _user.allowedLocations = locations;

        props.setUser(_user);
    }

    return (
        <div className="p-grid crud-demo">
            <div className="card">
                <Dialog visible={props.visible} style={{ width: "450px" }} header={props.dialogHeader} modal className="p-fluid" footer={userDialogFooter} onHide={props.hideDialog}>
                    <span className="titulo-categoria">IDENTIFICAÇÃO:</span>
                    <div className="categoria">
                        <div className="grid">
                            <div className="p-field col-6">
                                <span>Nome*</span>
                                <InputText
                                    id="name"
                                    type="text"
                                    value={props.user.userName}
                                    onChange={(e) => onInputChange(e, "userName", props.user, props.setUser)}
                                    required
                                    autoFocus
                                    className={classNames({ "p-invalid": props.submitted && !props.user.userName })}
                                />
                                {props.submitted && !props.user.userName && <small className="p-invalid">Insira um Nome.</small>}
                            </div>
                            <div className="p-field col-6">
                                <span>Email*</span>
                                <InputText
                                    id="email"
                                    type="text"
                                    value={props.user.email}
                                    onChange={(e) => onInputChange(e, "email", props.user, props.setUser)}
                                    required
                                    autoFocus
                                    className={classNames({ "p-invalid": props.submitted && !props.user.email })}
                                />
                                {props.submitted && !props.user.email && <small className="p-invalid">Insira um Email.</small>}
                            </div>
                        </div>
                    </div>

                    <span className="titulo-categoria">CREDENCIAIS:</span>
                    <div className="categoria">
                        <div className="formgrid grid">
                            <div className="field col-6">
                                <span>Acesso*</span>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <InputText
                                        id="access"
                                        type="text"
                                        value={props.user.userAccess}
                                        onChange={(e) => onInputChange(e, "userAccess", props.user, props.setUser)}
                                        required
                                        autoFocus
                                        className={classNames({ "p-invalid": props.submitted && !props.user.userAccess })}
                                    />
                                </div>
                                {props.submitted && !props.user.userAccess && <small className="p-invalid">Insira um Usuário.</small>}
                            </div>
                            <div className="field col-6">
                                <span>Senha*</span>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-lock"></i>
                                    </span>
                                    <Password
                                        value={props.user.password}
                                        toggleMask
                                        feedback={false}
                                        onChange={(e) => onInputChange(e, "password", props.user, props.setUser)}
                                        required
                                        autoFocus
                                        className={classNames({ "p-invalid": props.submitted && !props.user.password })}
                                    />
                                </div>
                                {props.submitted && !props.user.password && <small className="p-invalid">Insira uma senha.</small>}
                            </div>
                        </div>
                    </div>

                    <span className="titulo-categoria">TIPO USUÁRIO:</span>
                    <div className="categoria">
                        <div className="p-field-checkbox">
                            <Checkbox
                                className="mr-2"
                                inputId="isAdministrator"
                                name="option"
                                checked={props.user.isAdministrator}
                                onChange={(e) => onCheckedChange(e, "isAdministrator", props.user, props.setUser)}
                            />
                            <span>Administrador</span>
                        </div>
                    </div>
                    <span className="titulo-categoria">LOCALIDADES:</span>
                    <div className={'categoria'}>
                        <CreateUserLocationDialog
                            visible={showNewLocationDialog}
                            closeDialog={closeNewLocationDialog}
                            allowedUserLocations={props.user.allowedLocations}
                            isEditing={props.isEditingUser}
                        />
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} />
                        <UserLocationsDataTable
                            allowedLocations={props.user.allowedLocations}
                            deleteLocation={deleteLocation}
                        />
                    </div>
                    {props.submitted && props.user.allowedLocations.length <= 0 && <small className="p-invalid">Selecione pelo menos uma localidade.</small>}
                </Dialog>
            </div>
        </div>
    )
};
