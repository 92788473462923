import { Button } from "primereact/button";
import { DataView } from 'primereact/dataview';
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { DropdownInfo } from "../../../models/types";
import { PageHeader } from "../../../views/page-header";
import { CameraServices } from "../../../services/camera-services";
import { FeatureServices } from "../../../services/feature-services";
import { DestinationABService } from "../../../services/destination-ab-service";
import '../styles/event-ab.scss';
import { BlobImageResponse } from "../types/types";
import { NavigateToLocation } from "../../../components/Navigation/navigate-to-location";

export const EventAB = () => {
    const _destinationAbServices = new DestinationABService();
    const _featureServices = new FeatureServices();
    const _cameraServices = new CameraServices();

    const [selectedPlate, setSelectedPlate] = useState<string>();
    const [images, setImages] = useState<BlobImageResponse[]>([]);
    const [loadingImages, setLoadingImages] = useState<boolean>(false);
    const [loadingLocations, setLoadingLocations] = useState<boolean>(true);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [camerasPointA, setCamerasPointA] = useState<DropdownInfo[]>();
    const [camerasPointB, setCamerasPointB] = useState<DropdownInfo[]>();
    const [selectedLocationPointA, setSelectedLocationPointA] = useState<number>(0);
    const [selectedLocationPointB, setSelectedLocationPointB] = useState<number>(0);
    const [selectedCamerasPointA, setSelectedCamerasPointA] = useState<number>(0);
    const [selectedCamerasPointB, setSelectedCamerasPointB] = useState<number>(0);

    useEffect(() => {
        loadLocations();
    }, [])

    const loadLocations = () => {
        let result: DropdownInfo[] = [];
        result.push({ id: 0, description: "TODAS LOCALIDADES" });
        setLoadingLocations(true);
        _featureServices.getLocationsByFeature('DESTINATION-AB')
            .then(data => {
                data.data.forEach((location: any) => {
                    result.push(location);
                });
                setLocations(result);
                setLoadingLocations(false);
            })
    }

    const getVehicleImages = (vehiclePlate: string) => {
        setLoadingImages(true);

        _destinationAbServices.getEventsThrownOff(vehiclePlate, selectedLocationPointA, selectedCamerasPointA,
            selectedLocationPointB, selectedCamerasPointB)
            .then((data: any) => {
                setImages(data.data);
                setLoadingImages(false);
            });
    }

    const onSelectedUFPointA = (e: { value: any }) => {
        setSelectedLocationPointA(e.value);

        let response: DropdownInfo[] = _cameraServices.getCamerasPointAByLocation(e.value);
        setSelectedCamerasPointA(0);
        setCamerasPointA(response);
    }

    const onSelectedUFPointB = (e: { value: any }) => {
        setSelectedLocationPointB(e.value);

        let response: DropdownInfo[] = _cameraServices.getCamerasPointBByLocation(e.value);

        setSelectedCamerasPointB(0);
        setCamerasPointB(response);
    }

    const onSelectedCameraPointA = (e: { value: any }) => {
        setSelectedCamerasPointA(e.value);
    }

    const onSelectedCameraPointB = (e: { value: any }) => {
        setSelectedCamerasPointB(e.value);
    }

    const updatePlate = (value: any) => {
        var result: string = value.toUpperCase();
        setSelectedPlate(result);
    }

    const renderSearchFilters = () => {
        return (
            <div className="card">
                <div className="flex">
                    <div className="col-4 lg:col-6 xl:col-2">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-map-marker"></i>
                            </span>
                            <Dropdown
                                options={locations}
                                placeholder='Selecione uma localidade ponto A'
                                className="mr-2"
                                optionLabel="description"
                                optionValue="id"
                                value={selectedLocationPointA}
                                disabled={loadingLocations}
                                onChange={(e) => onSelectedUFPointA(e)}>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="col-4 lg:col-6 xl:col-2">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-camera"></i>
                            </span>
                            <Dropdown
                                options={camerasPointA}
                                placeholder='Camera'
                                className="mr-2"
                                optionLabel="description"
                                optionValue="id"
                                value={selectedCamerasPointA}
                                disabled={selectedLocationPointA === 0}
                                onChange={(e) => onSelectedCameraPointA(e)}>
                            </Dropdown>
                        </div>
                    </div>

                    <div className="col-4 lg:col-6 xl:col-2">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-map-marker"></i>
                            </span>
                            <Dropdown
                                options={locations}
                                placeholder='Selecione uma localidade ponto B'
                                className="mr-2"
                                optionLabel={"description"}
                                optionValue="id"
                                value={selectedLocationPointB}
                                disabled={loadingLocations}
                                onChange={(e) => onSelectedUFPointB(e)}>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="col-4 lg:col-6 xl:col-2">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-camera"></i>
                            </span>
                            <Dropdown
                                options={camerasPointB}
                                placeholder='Camera'
                                className="mr-2"
                                optionLabel="description"
                                optionValue="id"
                                value={selectedCamerasPointB}
                                disabled={selectedLocationPointB === 0}
                                onChange={(e) => onSelectedCameraPointB(e)}>
                            </Dropdown>
                        </div>
                    </div>

                    <div className="col-4 lg:col-6 xl:col-2">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-tag"></i>
                            </span>
                            <InputText
                                className="mr-2"
                                value={selectedPlate}
                                placeholder="Placa do veículo"
                                onChange={(e) => updatePlate(e.target.value)}>
                            </InputText>
                        </div>
                    </div>
                    <div className="col-4 lg:col-6 xl:col-2">
                        <Button
                            label="Pesquisar"
                            disabled={loadingImages}
                            onClick={(e) => getVehicleImages(selectedPlate)}>
                        </Button>
                    </div>
                </div>
            </div>)
    }

    const renderSkeletonDataScroller = () => {
        let responseArray: any[] = [];

        for (let i = 0; i < 6; i++) {
            responseArray.push(skeletonGridItem());
        }

        return (
            <div className="card">
                <div className="p-dataview p-component p-dataview-grid">
                    <div className="p-dataview-content">
                        <div className="flex">
                            {responseArray}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const skeletonGridItem = () => {
        return (
            <div className="col-12 sm:col-4 lg:col-12 xl:col-2 p-2">
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                        <Skeleton className="w-6rem border-round h-1rem" />
                    </div>
                    <div className="flex flex-column align-items-center gap-3 py-5">
                        <Skeleton className="w-6 shadow-2 border-round h-10rem" />
                        <Skeleton className="w-8rem border-round h-2rem" />
                    </div>
                    <div className="flex align-items-center justify-content-between">
                        <Skeleton className="w-4rem border-round h-2rem" />
                        <Skeleton shape="circle" className="w-3rem h-3rem" />
                    </div>
                </div>
            </div>
        );
    }

    const gridItem = (event: BlobImageResponse) => {
        return (
            <div className="card col-12 sm:col-8 lg:col-12 xl:col-6 p-2">
                <div className='grid'>
                    <div className='point-ab-plate col-12 flex justify-content-start point-ab-plate-div'>
                        <i className="pi pi-tag" />
                        <strong className='margin-auto ml-2'>{event.vehiclePlate}</strong>
                    </div>
                    <div className='flex col-12'>
                        <div className='grid col-6'>
                            <div className='col-6'>
                                <img className='point-ab-image' src={event.imageUrlPointA} alt={event.vehiclePlate}></img>
                            </div>
                            <div className='col-12'>
                                <div className='grid'>
                                    <div className='col-12 title-message'>
                                        {event.cameraNamePointA}
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <i className="pi pi-map" />
                                        <span className='ml-2 point-ab-text'>{event.cameraAddressPointA}</span>
                                    </div>
                                    <div className='col-12'>
                                        <i className="pi pi-clock" />
                                        <span className='ml-2 point-ab-text'>{event.datPointA}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='grid col-6'>
                            <div className='col-6'>
                                <img className='point-ab-image' src={event.imageUrlPointB} alt={event.vehiclePlate}></img>
                            </div>
                            <div className='col-12'>
                                <div className='grid'>
                                    <div className='col-12 title-message'>
                                        {event.cameraNamePointB}
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <i className="pi pi-map" />
                                        <span className='ml-2 point-ab-text'>{event.cameraAddressPointB}</span>
                                    </div>
                                    <div className='col-12'>
                                        <i className="pi pi-clock" />
                                        <span className='ml-2 point-ab-text'>{event.datThrownoff}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const itemTemplate = (event: BlobImageResponse) => {
        if (!event) {
            return;
        }

        return gridItem(event);
    }

    const renderDataScroller = () => {
        return (
            <div className="card">
                <DataView
                    value={images}
                    itemTemplate={itemTemplate}
                    layout='grid'
                    emptyMessage="Nenhum evento encontrado para o filtro selecionado"
                    rows={12}
                    paginator>
                </DataView>
            </div>
        )
    }

    return (
        <div className="grid">
            <div className="col-12">
                <PageHeader
                    title="EVENTOS DE DESLOCAMENTO ESPECIAL"
                    icon="pi pi-chart-bar"
                />
                <div className="card">
                    <NavigateToLocation
                        icon="pi-spin pi-cog"
                        text="CRIAR NOVO DESLOCAMENTO ESPECIAL"
                        to="destination-ab"
                    />
                    {renderSearchFilters()}
                    {loadingImages ? renderSkeletonDataScroller() : renderDataScroller()}
                </div>
            </div>
        </div>
    );
}
