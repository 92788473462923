import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faBus, faCar, faTruckPickup, faMotorcycle, faQuestion, faTruck } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

export interface VehicleTypeIconProps {
    typeInput: 'string' | 'number',
    val: any,
    size: 'sm' | 'lg' | 'xl'
    classes: string
}

export const VehicleTypeIcon = (props: VehicleTypeIconProps) => {
    const getVehicleIcon = (response: string | number) => {
        let iconDefinition: IconDefinition = faQuestion;
        
        if (response === 'Carro' || response === 0) {
            iconDefinition = faCar;
        } else if (response === 'Moto' || response === 1) {
            iconDefinition = faMotorcycle;
        }
        else if (response === 'Pickup' || response === 2) {
            iconDefinition = faTruckPickup;
        }
        else if (response === 'Onibus' || response === 3) {
            iconDefinition = faBus;
        } else if (response === 'Caminhao' || response === 4) {
            iconDefinition = faTruck;
        }

        return <FontAwesomeIcon icon={iconDefinition} size={props.size} className={props.classes} />;
    }

    return getVehicleIcon(props.val);
}