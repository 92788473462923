import axios, { AxiosRequestConfig } from "axios";
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { environment } from '../../../configuration/environment';
import { AuthServices } from '../../../services/utils/auth-services';
import { ToastServices } from "../../../services/utils/toast-services";
import { PageHeader } from '../../../views/page-header';
import { CameraServices } from '../../../services/camera-services';
import { camera, emptyCamera } from "../types/types";
import { CamerasDataTable } from "./data-tables/cameras-data-table";
import { CreateCameraDialog } from './dialogs/create-camera-dialog';
import { DeleteCameraDialog } from "./dialogs/delete-camera-dialog";
import { DeleteCamerasDialog } from "./dialogs/delete-cameras-dialog";

export const CrudCamera = () => {
    const _cameraService = new CameraServices();
    const _toastService = new ToastServices();
    const _authServices = new AuthServices();

    const [cameras, setCameras] = useState([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [cameraDialog, setCameraDialog] = useState(false);
    const [selectedCameras, setSelectedCameras] = useState([]);
    const [deleteCameraDialog, setDeleteCameraDialog] = useState(false);
    const [camera, setCamera] = useState<camera>(emptyCamera);
    const [deleteCamerasDialog, setDeleteCamerasDialog] = useState(false);

    _authServices.HandleAppAuth();

    useEffect(() => {
        getCameras();
    }, [])

    const hideDialog = () => {
        setSubmitted(false);
        setCameraDialog(false);
        getCameras();
    }

    const getCameras = () => {
        _cameraService.getCameras()
            .then((data) => {
                setCameras(data.data);
                setLoading(false);
            }, () => {
                setLoading(false);
            });
    }

    const onSaveCameraIntegrations = () => {
        if (!camera.integrations || !camera) {
            return;
        }

        camera.integrations.forEach((integration) => {
            let url = environment().baseUrl + environment().version + "/camera/" + camera.id + "/integrations";
            let method = "POST";

            if (integration.idCameraIntegracao) {
                url = environment().baseUrl + environment().version + "/camera/" + camera.id + "/integrations/" + integration.idCameraIntegracao;
                method = "PUT";
            }

            let axiosConfiguration: AxiosRequestConfig = {
                method: method === "PUT" ? "PUT" : "POST",
                url: url,
                data: integration,
                headers: {
                    Authorization: _authServices.GetUserToken(),
                }
            };

            axios(axiosConfiguration).then(
                (response) => {
                    getCameraIntegration(camera.id);
                },
                (error) => _toastService.showErrorRequestMessage(error)
            );
        });
        getCameraIntegration(camera.id);
    }

    const getCameraIntegration = (cameraId: number) => {
        _cameraService.getCameraIntegrations(cameraId).then(
            (response) => {
                let _camera = { ...camera } as any;

                _camera.integrations = response.data;
                setCamera(_camera);
            },
            (error) => _toastService.showErrorRequestMessage(error)
        );
    }

    const openNew = () => {
        setCamera(emptyCamera);
        setSubmitted(false);
        setCameraDialog(true);
    }

    const hideDeleteCameraDialog = () => {
        setDeleteCameraDialog(false);
    }

    const hideDeleteCamerasDialog = () => {
        setDeleteCamerasDialog(false);
    }

    const confirmDeleteCamera = (camera: camera) => {
        setCamera(camera);
        setDeleteCameraDialog(true);
    }

    const onEditCamera = (novaCamera: camera) => {
        setCamera({ ...novaCamera });
        setCameraDialog(true);
    }

    const onDeleteCamera = () => {
        let _cameras = cameras.filter(val => val.id !== camera.id);

        _cameraService.deleteCamera(camera)
            .then((response) => {
                setCameras(_cameras);
                setDeleteCameraDialog(false);
                setCamera(emptyCamera);
                setLoading(false);
                _toastService.showSuccesMessage('Camera excluída');
            }, (error) => {
                setLoading(false);
                _toastService.showErrorRequestMessage(error);
            });
    }

    const onSaveCamera = () => {
        setSubmitted(true);

        if (_cameraService.isCameraValid(camera)) {
            if (camera.id) {
                _cameraService.updateCamera(camera)
                    .then((response) => {
                        handleRequestSuccess(response);
                        onSaveCameraIntegrations();
                    }, (error) => _toastService.showErrorRequestMessage(error));

            } else {
                _cameraService.createCamera(camera)
                    .then((response) => handleRequestSuccess(response),
                        (error) => _toastService.showErrorRequestMessage(error));
            }
            setSubmitted(false);
            setCameraDialog(false);
            getCameras();
        }
    }

    const confirmDeleteSelected = () => {
        setDeleteCamerasDialog(true);
    }

    const deleteSelectedCameras = () => {
        let _camerasToDelete = cameras?.filter(val => selectedCameras.includes(val));

        _camerasToDelete.forEach(x => {
            _cameraService.deleteCamera(x)
                .then((response) => {
                    _toastService.showSuccesMessage('Camera ' + x.dscCamera + ' excluída');
                }, (error) => {
                    setLoading(false);
                    _toastService.showErrorRequestMessage(error);
                });
        })

        let _cameras = cameras.filter(val => !selectedCameras.includes(val));
        setCameras(_cameras);
        setDeleteCamerasDialog(false);
        setSelectedCameras(null);
    }

    const handleRequestSuccess = (response: any) => {
        _toastService.showSuccessRequestMessage(response);
        getCameras();
    }

    return (
        <div className="grid">
            <div className="col-12">
                <PageHeader title="CADASTRO CAMERAS" icon="pi pi-camera"/>
                <div className="card">
                    <CamerasDataTable
                        cameras={cameras}
                        loading={loading}
                        openNew={openNew}
                        editCamera={onEditCamera}
                        globalFilter={globalFilter}
                        selectedCameras={selectedCameras}
                        setGlobalFilter={setGlobalFilter}
                        setSelectedCameras={setSelectedCameras}
                        confirmDeleteCamera={confirmDeleteCamera}
                        confirmDeleteSelected={confirmDeleteSelected}
                    />
                    <CreateCameraDialog
                        camera={camera}
                        setCamera={setCamera}
                        submitted={submitted}
                        visible={cameraDialog}
                        hideDialog={hideDialog}
                        saveCamera={onSaveCamera}
                    />
                    <DeleteCameraDialog
                        camera={camera}
                        deleteCamera={onDeleteCamera}
                        deleteCameraDialog={deleteCameraDialog}
                        hideDeleteCameraDialog={hideDeleteCameraDialog}
                    />
                    <DeleteCamerasDialog
                        deleteCamerasDialog={deleteCamerasDialog}
                        deleteSelectedCameras={deleteSelectedCameras}
                        hideDeleteCamerasDialog={hideDeleteCamerasDialog}
                    />
                </div>
            </div>
        </div>
    )
}
