import axios from 'axios';
import { AuthServices } from "./utils/auth-services";
import { environment } from "../configuration/environment";

export class BikerBehaviorServices {
    baseControllerUrl: string = environment().baseUrl + environment().version + "/biker-behavior";
    _authServices = new AuthServices();

    getBikerBehaviorTime(locationId: number) {
        return axios.get(this.baseControllerUrl + '/' + locationId, {
            headers: { Authorization: this._authServices.GetUserToken() },
        });
    }

    getBikerBehaviorEvents(locationId: number, cameraId: number) {
        return axios.get(this.baseControllerUrl + '/biker-behavior-events', {
            params: {
                locationId: locationId,
                cameraId: cameraId
            },
            headers: { Authorization: this._authServices.GetUserToken() },
        });
    }
    updateBikerBehaviorServices(locationId: number, startTime: Date, stopTime: Date) {
        return axios.put(this.baseControllerUrl + '/update-biker-time/' + locationId, {}, {
            headers: { Authorization: this._authServices.GetUserToken() },
            params: {
                startTime: startTime.toLocaleString(),
                stopTime: stopTime.toLocaleString()
            }
        });
    }
}