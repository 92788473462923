import axios from "axios";
import { environment } from "../configuration/environment";

const baseEndpointUrl = `${environment().version}/no-plate/`;

export class NoPlateService {
    getEvents(cameraId: number, locationId: number) {
      return axios.get(environment().baseUrl + baseEndpointUrl + "get-events", {
          headers: { Authorization: sessionStorage.getItem("Bearer") },
          params: {
            cameraId: cameraId == 0 ? null : cameraId,
            locationId: locationId == 0 ? null : locationId
          }
      });
  }
}
