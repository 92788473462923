import { Dialog } from 'primereact/dialog';
import React from 'react';
import { NoPlateAlertResponse } from '../../../../models/types';
import '../../styles/point-AB-alert-dialog.scss';

export interface NoPlateDialogProps {
    noPlateAlertDialog: boolean,
    noPlateAlert: NoPlateAlertResponse,
    setNoPlateAlertDialog: (state: boolean) => void
}

export const NoPlateAlertDialog = (props: NoPlateDialogProps) => {
    return (
        <Dialog visible={props.noPlateAlertDialog} className='point-ab-dialog' resizable={false} header='ALERTA DE VEÍCULO SEM PLACA DETECTADO' onHide={() => props.setNoPlateAlertDialog(false)}>
            <div className='card'>
                <div className='flex col-12'>
                    <div className='grid col-12'>
                        <div className='col-12'>
                            <div className='grid'>
                                <div className='col-12'>
                                    <div className="flex justify-content-center">
                                        <img className='point-ab-image' src={props.noPlateAlert.imageUrl} alt={props.noPlateAlert.imageUrl}></img>
                                    </div>
                                </div>
                                <div className='col-12 title-message justify-content-center flex'>
                                    {props.noPlateAlert.cameraName}
                                </div>
                                <div className='col-8 '>
                                    <i className="pi pi-map" />
                                    <span className='ml-2 point-ab-alert-text'>{props.noPlateAlert.cameraAddress}</span>
                                </div>
                                <div className='col-4'>
                                    <i className="pi pi-clock" />
                                    <span className='ml-2 point-ab-alert-text'>{props.noPlateAlert.dateTime}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
