import { Dialog } from 'primereact/dialog';
import React from 'react';
import { DssEventSubscriptionResponse } from '../../../../models/types';
import '../../styles/dss-event-dialog.scss';

export interface DssEventSubscriptionDialogProps {
    dssEventSubscriptionDialog: boolean,
    setDssEventSubscriptionDialog: (val: boolean) => void,
    dssEventSubscriptionAlert: DssEventSubscriptionResponse
}
export const DssEventSubscriptionDialog = (props: DssEventSubscriptionDialogProps) => {
    return (
        <Dialog visible={props.dssEventSubscriptionDialog} className='point-ab-dialog' resizable={false} header='ALERTA DE ALARME DSS' onHide={() => props.setDssEventSubscriptionDialog(false)}>
            <div className='card'>
                <div className='custom-alarm-plate col-12 flex justify-content-center custom-alarm-plate-div'>
                    <i className="pi pi-camera custom-alarm-plate" />
                    <strong className='margin-auto ml-2'>{props.dssEventSubscriptionAlert.cameraName}</strong>
                </div>
                <div className='col-12 justify-content-center flex'>
                    <span>{props.dssEventSubscriptionAlert.alarmTime}</span>
                </div>
                <div className='flex col-12'>
                    <div className='grid col-12'>
                        <div className='col-12'>
                            <div className='grid'>
                                <div className='col-12'>
                                    <div className="flex justify-content-center">
                                        <img className='image-dss-event' src={props.dssEventSubscriptionAlert.azureImageUrl} alt={props.dssEventSubscriptionAlert.vehiclePlate}></img>
                                    </div>
                                </div>
                                <div className='col-12  justify-content-center flex'>
                                    <i className="pi pi-tag dss-plate" />
                                    <span className='ml-2 dss-plate'>
                                        {props.dssEventSubscriptionAlert.vehiclePlate}
                                    </span>
                                </div>
                                <div className='col-12 justify-content-center flex'>
                                    <span>Tipo alarme:</span>
                                    <span>{props.dssEventSubscriptionAlert.alarmType}</span>
                                </div>
                                <div className='col-12 justify-content-center flex'>
                                    <span>Descrição:</span>
                                    <span>{props.dssEventSubscriptionAlert.alarmDescription}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </Dialog >
    )
}