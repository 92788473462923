import { faBus, faCar, faCheckCircle, faMotorcycle, faQuestion, faTimesCircle, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'primereact/skeleton';
import React from "react";
import { OutDatedIcon } from '../../../../components/OutDated/out-dated';
import { integrationMessageResponse, plateEventResponse } from "../../../../models/types";
import '../../styles/vehicle-visualization-card.scss';
import { Fieldset } from 'primereact/fieldset';
import { ColorPicker } from 'primereact/colorpicker';
import { Tag } from 'primereact/tag';
import { Badge } from 'primereact/badge';

export interface visualizationVehicleCardProps {
    list: plateEventResponse[],
    isOlderDate: boolean,
    emptyExceptionList: boolean
}

export const VehicleVisualizationCard = (props: visualizationVehicleCardProps): JSX.Element => {
    const itemGridScale = props.emptyExceptionList ? "xl:col-6" : "xl:col-12";
    const componentGridScale = props.emptyExceptionList ? "xl:col-6" : "xl:col-3";

    const getIconValue = (response: boolean) => {
        if (response === true) {
            return <FontAwesomeIcon icon={faCheckCircle} size={"xl"} className="vehicle-smaller-details__success mr-2" />;
        } else {
            return <FontAwesomeIcon icon={faTimesCircle} size={"xl"} className="vehicle-smaller-details__error mr-2" />;
        }
    }

    const getVehicleIcon = (response: string) => {
        if (response === 'Carro') {
            return <FontAwesomeIcon icon={faCar} size={"xl"} className="mr-2" />;
        } else if (response === 'Moto') {
            return <FontAwesomeIcon icon={faMotorcycle} size={"xl"} className="mr-2" />;
        } else if (response === 'Onibus') {
            return <FontAwesomeIcon icon={faBus} size={"xl"} className="mr-2" />;
        } else if (response === 'Caminhao') {
            return <FontAwesomeIcon icon={faTruck} size={"xl"} className="mr-2" />;
        }

        return <FontAwesomeIcon icon={faQuestion} size={"xl"} className="mr-2" />;
    }

    const getVehicleDescription = (response: string) => {
        if (response === 'Carro') {
            return "CARRO";
        } else if (response === 'Moto') {
            return "MOTOCICLETA";
        } else if (response === 'Onibus') {
            return "ÔNIBUS";
        } else if (response === 'Caminhao') {
            return "CAMINHÃO";
        }

        return "DESCONHECIDO";
    }

    const getVehicleSpeed = (speed: number, cameraMaxSpeed: number) => {
        if (speed > cameraMaxSpeed) {
            return <span className="vehicle-big-details-error">Velocidade: {speed} Km/h</span>
        }

        return <span className="vehicle-big-details">Velocidade: {speed} Km/h</span>
    }

    const renderSkeletonVehicleVisualization = () => {
        return (
            <React.Fragment>
                <div key='skeleton-1' className={itemGridScale}>
                    <div className="card">
                        <div id="informacoes-camera" className="camera-information mb-3">
                            <Skeleton width='100%' height='30px' shape='rectangle' />
                        </div>
                        <div className="flex mb-2">
                            <div className="col-6 lg:col-6 xl:col-6">
                                <span className="informacoes-veiculo-i vehicle-big-details">
                                    <Skeleton width='90%' height='30px' shape='rectangle' className='mb-1' />
                                </span>
                                <span className="informacoes-veiculo-i mb-2 vehicle-big-details">
                                    <Skeleton width='90%' height='20px' shape='rectangle' className='mb-3' />
                                </span>
                                <span className="informacoes-veiculo-i vehicle-small-details">
                                    <Skeleton width='80%' height='25px' shape='rectangle' className='mb-1' />
                                </span>
                            </div>
                            <div className="vertical-bar mr-4"></div>
                            <div className="col-6 lg:col-6 xl:col-6">
                                <Skeleton width='90%' height='15px' shape='rectangle' className='mb-1' />
                                <Skeleton width='90%' height='15px' shape='rectangle' className='mb-5' />
                                <div className='flex'>
                                    <Skeleton width='30%' height='25px' shape='rectangle' className='mr-2 mt-1' />
                                    <Skeleton width='30%' height='25px' shape='rectangle' className='mt-1' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div key='skeleton-2' className={itemGridScale}>
                    <div className="card">
                        <div id="informacoes-camera" className="camera-information mb-3">
                            <Skeleton width='100%' height='30px' shape='rectangle' />
                        </div>
                        <div className="flex mb-2">
                            <div className="col-6 lg:col-6 xl:col-6">
                                <span className="informacoes-veiculo-i vehicle-big-details">
                                    <Skeleton width='90%' height='30px' shape='rectangle' className='mb-1' />
                                </span>
                                <span className="informacoes-veiculo-i mb-2 vehicle-big-details">
                                    <Skeleton width='90%' height='20px' shape='rectangle' className='mb-3' />
                                </span>
                                <span className="informacoes-veiculo-i vehicle-small-details">
                                    <Skeleton width='80%' height='25px' shape='rectangle' className='mb-1' />
                                </span>
                            </div>
                            <div className="vertical-bar mr-4"></div>
                            <div className="col-6 lg:col-6 xl:col-6">
                                <Skeleton width='90%' height='15px' shape='rectangle' className='mb-1' />
                                <Skeleton width='90%' height='15px' shape='rectangle' className='mb-5' />
                                <div className='flex'>
                                    <Skeleton width='30%' height='25px' shape='rectangle' className='mr-2 mt-1' />
                                    <Skeleton width='30%' height='25px' shape='rectangle' className='mt-1' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const renderVehicleVisualization = (cameraReport: plateEventResponse) => {
        const legendTemplate = (
            <div className="flex align-items-center gap-2 px-2">
                {getVehicleIcon(cameraReport.vehicleType)}
                <span className="font-bold">{cameraReport.camera}</span>
            </div>
        );

        return (
            <React.Fragment>
                <div className={"col-12 lg:col-12 " + itemGridScale}>
                    <Fieldset legend={legendTemplate}>
                        <div className='grid'>
                            <div className='col-6'>
                                <span className="informacoes-veiculo vehicle-big-details">
                                    Placa: {cameraReport.plate}
                                </span>
                            </div>
                            <div className='col-6'>
                                <span className="informacoes-veiculo-1 mb-2 vehicle-big-details">
                                    Marca: {cameraReport.vehicleBrand}
                                </span>
                            </div>

                            <div className='col-6'>
                                <span className="informacoes-veiculo vehicle-big-details">Cor: <Badge className='mt-0' style={{ backgroundColor: cameraReport.vehicleColor }} />
                                </span>
                            </div>
                            <div className='col-6'>
                                <span>
                                    {cameraReport.vehicleSpeed > 0 && getVehicleSpeed(cameraReport.vehicleSpeed, cameraReport.cameraMaxSpeed)}
                                    {cameraReport.vehicleSpeed > 0 && <small style={{ display: 'block' }}>Velocidade máxima: {cameraReport.cameraMaxSpeed} Km/h</small>}
                                </span>
                            </div>
                        </div>

                        <Fieldset legend='Integrações Ativas'>
                            <div className='flex'>
                                {cameraReport.integrations.map((x: integrationMessageResponse) => {
                                    return (
                                        <React.Fragment>
                                            <div className="informacoes-veiculo-i vehicle-smaller-details mr-4">
                                                {getIconValue(x.success)}
                                                <strong>
                                                    {x.name.toUpperCase()}
                                                </strong>
                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                            </div>

                        </Fieldset>
                        <div className="vehicle-information flex justify-content-between">
                            <span className="vehicle-smaller-details informacoes-veiculo-i mb-1 mt-2">EVENTO MOON-AI</span>
                            <span className="vehicle-small-details informacoes-veiculo-i mb-1 mt-2">{cameraReport.captureTime}</span>
                        </div>
                    </Fieldset>
                </div>
            </React.Fragment>
        )
    }

    const renderOutDatedMessage = () => {
        return (
            <div className={itemGridScale}>
                <div className="card">
                    <div className="camera-information mb-3">
                        {OutDatedIcon(props.isOlderDate)} <strong className='ml-2'>Mensagens interrompidas</strong>
                    </div>

                    <div className="flex mb-2">
                        <div className="col-6 lg:col-6 xl:col-6">
                            <span className="informacoes-veiculo-i vehicle-big-details">
                                <Skeleton width='90%' height='30px' shape='rectangle' className='mb-1' />
                            </span>
                            <span className="informacoes-veiculo-i mb-2 vehicle-big-details">
                                <Skeleton width='90%' height='20px' shape='rectangle' className='mb-3' />
                            </span>
                            <span className="informacoes-veiculo-i vehicle-small-details">
                                <Skeleton width='80%' height='25px' shape='rectangle' className='mb-1' />
                            </span>
                        </div>
                        <div className="vertical-bar mr-4"></div>
                        <div className="col-6 lg:col-6 xl:col-6">
                            <Skeleton width='90%' height='15px' shape='rectangle' className='mb-1' />
                            <Skeleton width='90%' height='15px' shape='rectangle' className='mb-5' />
                            <div className='flex'>
                                <Skeleton width='30%' height='25px' shape='rectangle' className='mr-2 mt-1' />
                                <Skeleton width='30%' height='25px' shape='rectangle' className='mt-1' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={"col-12 lg:col-12 " + componentGridScale}>
            <div className="grid" style={{ position: 'relative' }}>
                <div className="grid col-12 lg:col-12 xl:col-12 vehicle-visualization-card-container">
                    {props.isOlderDate ? renderOutDatedMessage() :
                        props.list.length > 0 ? props.list.map((x: plateEventResponse) => {
                            return renderVehicleVisualization(x)
                        }) : renderSkeletonVehicleVisualization()
                    }
                </div>
            </div>
        </div>
    )
}
