export interface DssModel {
    locationDssId: number,
    url: string,
    port: string | null,
    user: string,
    password: string,
    locationId: number
}

export const blankDss: DssModel = {
    locationDssId: 0,
    port: null,
    url: '',
    user: '',
    password: '',
    locationId: 0
}

export interface TimelineEvent {
    status?: string;
    date?: string;
    icon?: string;
    color?: string;
    image?: string;
}