import React from 'react';
import { useHistory } from 'react-router';
import './styles/navigate-to-location.scss';

export interface NavigateToLocationProps {
    icon: string,
    text: string,
    to: string
}

export const NavigateToLocation = (props: NavigateToLocationProps) => {
    const history = useHistory();

    const onRouteChange = () => {
        history.push(props.to);
    }

    return (
        <React.Fragment>
            <h4 className='navigation-button' onClick={(e) => { onRouteChange() }}>
                <i className={`pi pi-spin ${props.icon} mr-2`} style={{ fontSize: '1.5rem' }} />
                <span>{props.text}</span>
            </h4>
        </React.Fragment>
    )
}