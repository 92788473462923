import axios from "axios";
import { environment } from "../configuration/environment";
import { LoginResponse, LoginInterface, user } from "../pages/Login/types/types";

export class LoginServices {

    // Método de login
    login(user: LoginInterface) {
        return axios.post(environment().baseUrl + environment().version + '/auth/login', user);
    }

    // Salvar informações do usuário após o login
    saveInfo(response: LoginResponse) {
        sessionStorage.setItem('Bearer', 'Bearer ' + response.accessToken)

        response.userToken.claims
            .forEach((item: any) => sessionStorage.setItem(item.type, item.value))
    }

    getUserProfile(): user {
        const userProfile: user = {
            isMultifactor: sessionStorage.getItem('IsMultifactor') === 'true',
            id: parseInt(sessionStorage.getItem('UserID')),
            email: sessionStorage.getItem('UserEmail') || '',
            userName: sessionStorage.getItem('UserName') || '',
            userAccess: sessionStorage.getItem('userAccess') || ''
        };

        return userProfile;
    }

    // Validar se o usuário preencheu os campos necessários
    isUserValid = (user: LoginInterface): boolean => {
        return !!(user.username && user.password);
    }

    // Validar o código de verificação
    async validateVerificationCode(username: string, code: string) {
        return await axios.post(environment().baseUrl + environment().version + '/auth/validate-verification-code', {
            username: username,
            code: code
        });
    }
}
