import React, { useEffect, useState } from 'react';
import { FeatureServices } from '../../../services/feature-services';
import { DropdownInfo } from '../../../models/types';
import { ToastServices } from '../../../services/utils/toast-services';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ClipLoader } from 'react-spinners';

export interface LocationBikerBehaviorHeaderProps {
    loading: boolean
    globalFilter: string,
    selectedLocation: number,
    setGlobalFilter: (val: string) => void,
    setLoading: (loading: boolean) => void,
    setSelectedLocation: (locationId: number) => void,
}

export const LocationBikerBehaviorHeader = (props: LocationBikerBehaviorHeaderProps) => {
    const _toastServices = new ToastServices();
    const _featureServices = new FeatureServices();

    const [locations, setLocations] = useState<DropdownInfo[]>([]);

    useEffect(() => {
        props.setLoading(true);
        _featureServices.getLocationsByFeature('BIKER-ANALYSIS')
            .then((data: any) => {
                setLocations(data.data);
                props.setLoading(false);
            }).catch((error: any) => {
                _toastServices.showErrorRequestMessage(error);
                props.setLoading(false);
            });
    }, []);

    return (
        <React.Fragment>
            <div className='grid'>
                <div className="col-4 lg:col-6 xl:col-2">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-map-marker" />
                        </span>
                        <Dropdown
                            options={locations}
                            value={props.selectedLocation}
                            optionLabel="description"
                            optionValue="id"
                            disabled={props.loading}
                            placeholder="Selecione uma Localidade"
                            onChange={(e) => props.setSelectedLocation(e.value)}
                            emptyMessage="Nenhuma Camera Encontrada.">
                        </Dropdown>
                    </div>
                </div>
                {props.selectedLocation !== 0 &&
                    <div className="col-4 lg:col-6 xl:col-2">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-camera" />
                            </span>
                            <InputText
                                value={props.globalFilter}
                                disabled={props.loading || props.selectedLocation === 0}
                                placeholder="Câmera"
                                onInput={(e: any) => props.setGlobalFilter(e.target.value)}
                            />
                        </div>
                    </div>
                }
                <div className="col-4 lg:col-0 xl:col-8">
                    <ClipLoader color='var(--text-color)' loading={props.loading} size={25} />
                </div>
            </div>
        </React.Fragment>
    )
}