import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface ProtectedRouteProps extends RouteProps {
    component: React.ComponentType<any>;
}

const AdminRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {
    const haveAcess = () => {
        return sessionStorage.getItem("IsAdministrator") === "True";
    };

    return (
        <Route
            {...rest}
            render={props =>
                haveAcess() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
};

export default AdminRoute;
