import { DataView } from "primereact/dataview";
import React, { useEffect, useState } from "react";
import { ImageViewer } from "../../../components/ImageViewer/image-viewer";
import { BikerBehaviorServices } from "../../../services/biker-behavior-services";
import { ToastServices } from "../../../services/utils/toast-services";
import { EventBikerBehaviorResponse } from "../types/types";

export interface EventBikerBehaviorGridProps {
    selectedLocation: number,
    cameraId: number,
    loading: boolean,
    setLoading: (val: boolean) => void,
}

export const EventBikerBehaviorGrid = (props: EventBikerBehaviorGridProps) => {
    const _bikerBehaviorServices = new BikerBehaviorServices();
    const _toastServices = new ToastServices();
    const [events, setEvents] = useState<EventBikerBehaviorResponse[]>([]);

    useEffect(() => {
        if (props.selectedLocation !== 0) {
            getBikerBehaviorEvents();
        }
    }, [props.selectedLocation, props.cameraId])

    const getBikerBehaviorEvents = () => {
        _bikerBehaviorServices.getBikerBehaviorEvents(props.selectedLocation, props.cameraId)
            .then((data: any) => {
                setEvents(data.data);
                props.setLoading(false);
            }).catch((error: any) => {
                _toastServices.showErrorRequestMessage(error);
                props.setLoading(false);
            });
    }
    const getBikeInfo = (response: EventBikerBehaviorResponse, bikeNumber: number) => {
        return (
            <React.Fragment>
                <div className="grid mt-1">
                    <div className='col-12'>
                        <ImageViewer
                            alt={response.cameraName}
                            src={bikeNumber === 1 ? response.firstBikeImageUrl : response.secondBikeImageUrl}
                            style={{
                                width: '90%',
                                height: '180px',
                                border: '1px solid var(--text-color)',
                                borderRadius: '15px',
                                cursor:'pointer'
                            }}
                        />
                    </div>
                    <div className='col-6'>
                        <strong>
                            <span className="pi pi-tag mr-1"></span>
                            <span className="mr-2">PLACA MOTO {bikeNumber}:</span>
                            {bikeNumber === 1 ? response.firstBikePlate : response.secondBikePlate}
                        </strong>
                    </div>
                    <div className='col-6'>
                        <span className="mr-2 pi pi-clock"></span>
                        {bikeNumber === 1 ? response.firstBikeDateTime : response.secondBikeDateTime}
                    </div>
                    <div className='col-12' style={{ wordBreak: 'break-all' }}>
                        {bikeNumber === 1 ? response.firstBikeDescription : response.secondBikeDescription}
                    </div>
                </div>
            </React.Fragment>
        )
    }
    
    const renderGridItem = (response: EventBikerBehaviorResponse) => {
        return (
            <div className="col-12 sm:col-6 lg:col-12 xl:col-6 p-2">
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="gap-2">
                        <div className="flex align-items-center gap-2">
                            <i className="pi pi-camera"></i>
                            <span className="product-category font-semibold">{response.cameraName}</span>
                        </div>
                        <span className="text-1xl ">{response.cameraAddress}</span>
                    </div>
                    <div className="flex justify-content-evenly gap-2">
                        <div className="gap-3 py-5">
                            {getBikeInfo(response, 1)}
                        </div>
                        <div className="gap-3 py-5">
                            {getBikeInfo(response, 2)}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
    const itemTemplate = (cameraConvoy: EventBikerBehaviorResponse) => {
        if (!cameraConvoy) {
            return;
        }

        return renderGridItem(cameraConvoy);
    }

    return (
        <React.Fragment>
            <DataView
                header='EVENTOS DE ANÁLISE COMPORTAMENTAL DOS MOTOQUEIROS'
                value={events}
                itemTemplate={itemTemplate}
                layout='grid'
                emptyMessage="NENHUM EVENTO ENCONTRADO PARA O FILTRO SELECIONADO"
                rows={2}
                paginator>
            </DataView>
        </React.Fragment>
    )
}