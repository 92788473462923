import { Dialog } from 'primereact/dialog';
import React from 'react';
import { whatsappRecipient } from '../../types/types';
import { Button } from 'primereact/button';

export interface DeleteRecipientDialogProps {
    visible: boolean,
    recipient: whatsappRecipient,
    deleteRecipient: () => void,
    closeDialog: () => void,
}

export const DeleteRecipientDialog = (props: DeleteRecipientDialogProps) => {
    const dialogHeader = `EXCLUÍR RECIPIENTE ${props.recipient.recipientName}`;

    const deleteRecipientDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={props.closeDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={props.deleteRecipient} />
        </>
    )

    return (
        <Dialog visible={props.visible} modal header={dialogHeader} footer={deleteRecipientDialogFooter} onHide={props.closeDialog}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle mr-2" style={{ fontSize: '2rem' }} />
                {props.recipient && <span>Tem certeza que deseja deletar o cadastro de <b>{props.recipient.recipientName}</b>?</span>}
            </div>
        </Dialog>
    )
}
