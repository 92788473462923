import { Chart } from 'primereact/chart';
import React, { useEffect, useRef, useState } from 'react';
import { cameraPerformance, cameraPerformanceGraph } from '../../../../models/types';
import { DashboardServices } from '../../../../services/dashboard-services';
import '../../styles/performance-graph.scss';

export interface PerformanceGraphProps {
    date: Date,
    location: number,
    camera: number,
    lineOptions: any
}

export const PerformanceGraph = (props: PerformanceGraphProps): JSX.Element => {
    const _dashboardServices = new DashboardServices();

    const lastGraphData = useRef<cameraPerformanceGraph>();
    const [graphData, setGraphData] = useState<cameraPerformanceGraph>();

    lastGraphData.current = graphData;

    useEffect(() => {
        getCameraPerformanceGraph();
    }, [props.date, props.location, props.camera])

    const getCameraPerformanceGraph = () => {
        _dashboardServices.getCameraPerformanceGraph(props.date, props.location, props.camera)
            .then((data: any) => {
                buildCamerasPerformanceGraph(data.data, props);
            });
    }

    const buildCamerasPerformanceGraph = (result: cameraPerformance[], props: PerformanceGraphProps) => {
        let labels: string[] = [];
        let dataSet: number[] = [];

        result.forEach(element => {
            labels.push(element.description);
        });

        result.forEach(element => {
            dataSet.push(element.quantity);
        });

        let resposta: cameraPerformanceGraph = {
            labels: labels,
            datasets: [
                {
                    label: 'Dados de ' + props.date.toLocaleString("pt-br",
                        {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        }),
                    backgroundColor: '#42A5F5',
                    data: dataSet
                }
            ]
        };

        setGraphData(resposta);
    }

    return (

        <div className="col-12 lg:col-6 xl:col-6" >
            <div className="card performance-graph">
                <Chart type="bar" data={lastGraphData.current} options={props.lineOptions} />
            </div>
        </div>
    );
}
