import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { DropdownInfo } from '../../../../models/types';

export interface WhatsappFeturesHeaderProps {
    locations: DropdownInfo[],
    loading: boolean,
    selectedLocation: number,
    onSelectedLocation: (e: any) => void
}
export const WhatsappFeturesHeader = (props: WhatsappFeturesHeaderProps) => {
    return (
        <div className='col-2'>
            <div className="p-inputgroup mb-2">
                <span className="p-inputgroup-addon">
                    <i className="pi pi-map-marker"></i>
                </span>
                <Dropdown
                    options={props.locations}
                    disabled={props.loading}
                    value={props.selectedLocation}
                    tooltip="Selecione uma localidade para carregar os recipientes"
                    placeholder="Selecione uma localidade"
                    optionValue="id"
                    optionLabel="description"
                    onChange={(e) => props.onSelectedLocation(e)}
                />
            </div>
        </div>
    )
}