import { Button } from "primereact/button";
import { DataView } from 'primereact/dataview';
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { NavigateToLocation } from "../../../components/Navigation/navigate-to-location";
import { DropdownInfo } from "../../../models/types";
import { CameraServices } from "../../../services/camera-services";
import { CustomAlarmService } from "../../../services/custom-alarm-service";
import { FeatureServices } from "../../../services/feature-services";
import { getNumberOfPassengers, getVehicleBrands, getVehicleColors, getVehicleTypes } from "../../../shared/constants-dropdown/vehicle-constants-dropdown";
import { vehicleBrand } from "../../../shared/constants/vehicle-brand";
import { vehicleColor } from "../../../shared/constants/vehicle-color";
import { vehicleType } from "../../../shared/constants/vehicle-type";
import { ToastServices } from "../../../services/utils/toast-services";
import { PageHeader } from "../../../views/page-header";
import '../styles/event-custom-alarm.scss';
import { BlobImageResponse } from "../types/types";
import { ImageViewer } from "../../../components/ImageViewer/image-viewer";

export const EventCustomAlarm = () => {
    const _customAlarmService = new CustomAlarmService();
    const _cameraServices = new CameraServices();
    const _featureServices = new FeatureServices();
    const _toastServices = new ToastServices();

    const [images, setImages] = useState<BlobImageResponse[]>([]);
    const [loadingImages, setLoadingImages] = useState<boolean>(false);
    const [loadingLocations, setLoadingLocations] = useState<boolean>(true);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [cameras, setCameras] = useState<DropdownInfo[]>();
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [selectedCamera, setSelectedCamera] = useState<number>(0);
    const [selectedVehicleType, setSelectedVehicleType] = useState<number>(6);
    const [selectedVehicleColor, setSelectedVehicleColor] = useState<number>(0);
    const [selectedVehicleBrand, setSelectedVehicleBrand] = useState<number>(0);
    const [selectedNumberOfPassengers, setSelectedNumberOfPassengers] = useState<number>(0);

    useEffect(() => {
        loadLocations();
    }, [])

    const loadLocations = () => {
        let result: DropdownInfo[] = [];
        result.push({ id: 0, description: "TODAS LOCALIDADES" });
        setLoadingLocations(true);
        _featureServices.getLocationsByFeature('CUSTOM-ALARM')
            .then(data => {
                data.data.forEach((location: any) => {
                    result.push(location);
                });
                setLocations(result);
                setLoadingLocations(false);
            })
            .catch((error) => {
                _toastServices.showErrorRequestMessage(error);
            })
    }

    const getVehicleImages = () => {
        setLoadingImages(true);
        _customAlarmService.getEvents(selectedCamera, selectedLocation, selectedVehicleType,
            selectedVehicleColor, selectedVehicleBrand, selectedNumberOfPassengers)
            .then((data: any) => {
                setImages(data.data);
                setLoadingImages(false);
            });
    }

    const onSelectedUF = (e: { value: any }) => {
        setSelectedLocation(e.value);

        let response: DropdownInfo[] = _cameraServices.getCamerasByLocation(e.value);
        setSelectedCamera(0);
        setCameras(response);
    }

    const onSelectedCamera = (e: { value: any }) => {
        setSelectedCamera(e.value);
    }

    const renderSearchFilters = () => {
        return (

            <div className="grid">
                <div className="col-2">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-map-marker"></i>
                        </span>
                        <Dropdown
                            options={locations}
                            placeholder='Selecione uma localidade'
                            className="mr-2"
                            optionLabel="description"
                            optionValue="id"
                            value={selectedLocation}
                            disabled={loadingLocations}
                            onChange={(e) => onSelectedUF(e)}>
                        </Dropdown>
                    </div>
                </div>
                <div className="col-2">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-camera"></i>
                        </span>
                        <Dropdown
                            options={cameras}
                            placeholder='Camera'
                            className="mr-2"
                            optionLabel="description"
                            optionValue="id"
                            value={selectedCamera}
                            disabled={selectedLocation === 0}
                            onChange={(e) => onSelectedCamera(e)}>
                        </Dropdown>
                    </div>
                </div>
                <div className="col-8" />
                <div className="col-2">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-fw pi-chart-bar"></i>
                        </span>
                        {
                            <Dropdown
                                emptyMessage="Nenhum Tipo de Veículo Encontrado"
                                optionLabel="description"
                                optionValue="id"
                                options={getVehicleTypes()}
                                placeholder="Selecionar tipo"
                                value={selectedVehicleType}
                                onChange={(e) => setSelectedVehicleType(e.value)}
                            />
                        }
                    </div>
                </div>
                <div className="col-2">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-fw pi-chart-bar"></i>
                        </span>
                        {
                            <Dropdown
                                emptyMessage="Nenhuma Marca Encontrada"
                                optionLabel="description"
                                optionValue="id"
                                options={getVehicleBrands()}
                                placeholder="Selecionar marca"
                                value={selectedVehicleBrand}
                                onChange={(e) => setSelectedVehicleBrand(e.value)}
                            />
                        }
                    </div>
                </div>
                <div className="col-2">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-fw pi-chart-bar"></i>
                        </span>
                        {
                            <Dropdown
                                emptyMessage="Nenhuma Cor Encontrada"
                                optionLabel="description"
                                optionValue="id"
                                options={getVehicleColors()}
                                placeholder="Selecionar cor"
                                value={selectedVehicleColor}
                                onChange={(e) => setSelectedVehicleColor(e.value)}
                            />
                        }
                    </div>
                </div>
                {selectedVehicleType === vehicleType.MOTO &&
                    <div className="col-2">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-fw pi-chart-bar"></i>
                            </span>
                            {
                                <Dropdown
                                    emptyMessage="Nenhuma Opção Encontrada"
                                    optionLabel="description"
                                    optionValue="id"
                                    options={getNumberOfPassengers()}
                                    placeholder="Selecionar número de pessoas"
                                    value={selectedNumberOfPassengers}
                                    onChange={(e) => setSelectedNumberOfPassengers(e.value)}
                                />
                            }
                        </div>
                    </div>
                }
                <div className="col-3">
                    <Button
                        label="Pesquisar"
                        disabled={loadingImages}
                        onClick={(e) => getVehicleImages()}>
                    </Button>
                </div>
            </div>

        )
    }

    const renderSkeletonDataScroller = () => {
        let responseArray: any[] = [];

        for (let i = 0; i < 6; i++) {
            responseArray.push(skeletonGridItem());
        }

        return (
            <div className="p-dataview p-component p-dataview-grid">
                <div className="p-dataview-content">
                    <div className="flex">
                        {responseArray}
                    </div>
                </div>
            </div>
        )
    }

    const skeletonGridItem = () => {
        return (
            <div className="col-12 sm:col-4 lg:col-12 xl:col-2 p-2">
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                        <Skeleton className="w-6rem border-round h-1rem" />
                    </div>
                    <div className="flex flex-column align-items-center gap-3 py-5">
                        <Skeleton className="w-6 shadow-2 border-round h-10rem" />
                        <Skeleton className="w-8rem border-round h-2rem" />
                    </div>
                    <div className="flex align-items-center justify-content-between">
                        <Skeleton className="w-4rem border-round h-2rem" />
                        <Skeleton shape="circle" className="w-3rem h-3rem" />
                    </div>
                </div>
            </div>
        );
    }

    const gridItem = (event: BlobImageResponse) => {
        return (
            <div className="col-12 sm:col-12 lg:col-6 xl:col-3 p-2" style={{ border: '1px solid var(--text-color)', borderRadius: '10px' }}>
                <div className='grid'>
                    <div className='custom-alarm-plate col-12 flex justify-content-center custom-alarm-plate-div'>
                        <i className="pi pi-tag" />
                        <strong className='margin-auto ml-2'>{event.vehiclePlate}</strong>
                    </div>
                    <div className='flex col-12'>
                        <div className='grid col-12'>
                            <div className='col-12'>
                                <div className='grid'>
                                    <div className='col-12'>
                                        <div className="flex justify-content-center">
                                            <ImageViewer
                                                alt={event.vehiclePlate}
                                                src={event.fileUrl}
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    border: '1px solid var(--text-color)',
                                                    borderRadius: '15px',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 title-message justify-content-center flex'>
                                        {event.camera}
                                    </div>
                                    <div className='col-6'>
                                        <i className="pi pi-map" />
                                        <span className='ml-2 custom-alarm-text'>{event.cameraAddress}</span>
                                    </div>
                                    <div className='col-6'>
                                        <i className="pi pi-clock" />
                                        <span className='ml-2 custom-alarm-text'>{event.thrownoffDate}</span>
                                    </div>
                                    <div className='col-6'>
                                        <i className="pi pi-fw pi-chart-bar" />
                                        <strong className="ml-2 ">Tipo de veículo:</strong>
                                        <span className='custom-alarm-text__Parameter'>{event.vehicleTypeId === vehicleType.DUMMY ? "TODOS" : vehicleType[event.vehicleTypeId]}</span>
                                    </div>
                                    <div className='col-6'>
                                        <i className="pi pi-fw pi-chart-bar" />
                                        <strong className="ml-2">Marca do veículo:</strong>
                                        <span className='custom-alarm-text__Parameter'>{event.vehicleBrandId === vehicleBrand.DUMMY ? "TODAS" : vehicleBrand[event.vehicleBrandId]}</span>
                                    </div>
                                    <div className='col-6'>
                                        <i className="pi pi-fw pi-chart-bar" />
                                        <strong className="ml-2">Cor do veículo:</strong>
                                        <span className='custom-alarm-text__Parameter'>{event.vehicleColorId === vehicleColor.DUMMY ? "TODAS" : vehicleColor[event.vehicleColorId]}</span>
                                    </div>
                                    {event.vehicleTypeId == vehicleType.MOTO &&
                                        <div className='col-6'>
                                            <i className="pi pi-fw pi-chart-bar" />
                                            <strong className="ml-2">Número de pessoas:</strong>
                                            <span className='custom-alarm-text__Parameter'>{event.numberOfPassengers === null ? "QUALQUER" : event.numberOfPassengers}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const itemTemplate = (event: BlobImageResponse) => {
        if (!event) {
            return;
        }

        return gridItem(event);
    }

    const renderDataScroller = () => {
        return (
            <DataView
                value={images}
                itemTemplate={itemTemplate}
                layout='grid'
                emptyMessage="Nenhuma imagem encontrada para o filtro selecionado"
                rows={12}
                paginator>
            </DataView>
        )
    }

    return (
        <div className='grid'>
            <div className='col-12'>
                <PageHeader
                    title="EVENTOS DE ALARME CUSTOMIZADO"
                    icon="pi pi-chart-bar"
                />
                <div className='card'>
                    <NavigateToLocation
                        icon="pi-spin pi-cog"
                        text="CONFIGURAR ALARME CUSTOMIZADO"
                        to="custom-alarm"
                    />
                    {renderSearchFilters()}
                    {loadingImages ? renderSkeletonDataScroller() : renderDataScroller()}
                </div>
            </div>
        </div>
    )
}
