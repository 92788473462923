import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import React from 'react';
import { onTimeChange } from '../../../../services/utils/input-services';
import { ConvoyConfiguration } from '../../types/types';
import { Fieldset } from 'primereact/fieldset';
import { LocationConvoyWarning } from './camera-convoy-warning';

export interface locationConvoyConfigProps {

    convoyConfiguration: ConvoyConfiguration,
    loading: boolean,
    updateConfig: (config: ConvoyConfiguration) => void,
    saveConfig: () => void
}

const header = <>
    <span className="titulo-categoria">
        <i className='pi pi-clock mr-2'></i>
        Horário de Funcionamento
    </span>
</>
export const TimeConvoyConfig = (props: locationConvoyConfigProps) => {

    return (
        <>
            <Fieldset legend={header} className='mb-4'> 
                <LocationConvoyWarning
                    startHour={props.convoyConfiguration.startTime}
                    stopHour={props.convoyConfiguration.stopTime}
                />
                <div className='grid'>
                    <div className='col-2'>
                        <div className="p-inputgroup">
                            <strong className='mr-2'>Inicio:</strong>
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-clock" />
                            </span>
                            <Calendar
                                hourFormat='24'
                                stepMinute={30}
                                disabled={props.loading}
                                value={props.convoyConfiguration.startTime}
                                showOnFocus
                                onChange={(e: any) => onTimeChange(e, 'startTime', props.convoyConfiguration, props.updateConfig)}
                                timeOnly
                            />
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className="p-inputgroup">
                            <strong className='mr-2'>Fim:</strong>
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-clock" />
                            </span>
                            <Calendar
                                timeOnly
                                showOnFocus
                                stepMinute={30}
                                disabled={props.loading}
                                value={props.convoyConfiguration.stopTime}
                                onChange={(e: any) => onTimeChange(e, 'stopTime', props.convoyConfiguration, props.updateConfig)}
                            />
                        </div>
                    </div>
                    <div className='col-4'>
                        <Button label='ATUALIZAR HORÁRIOS' onClick={(e) => { props.saveConfig() }} />
                    </div>
                </div>


            </Fieldset>
        </>
    );
}