export const onInputChange = (e: any, property: string, object: any, updateObject: (object: any) => void): void => {
    const val = (e.target && e.target.value) || "";
    let _object = { ...object } as any;
    _object[`${property}`] = val;
    updateObject(_object);
}

export const onTimeChange = (e: any, property: string, object: any, updateObject: (object: any) => void): void => {
    const val = (e.target && e.target.value) || "";

    let _object = { ...object } as any;

    console.log('Old Value',_object.startHour)
    _object[`${property}`] = val;
    updateObject(_object);
}

export const onCheckedChange = (e: any, property: string, object: any, updateObject: (object: any) => void): void => {
    const val: boolean = (e.target && e.target.checked) || false;
    let _object = { ...object } as any;
    _object[property] = val;

    updateObject(_object);
}

export const onNumberChange = (e: any, property: string, object: any, updateObject: (object: any) => void): void => {
    const val = (e && e.value) || "0";
    let _object = { ...object } as any;
    _object[property] = val;
    updateObject(_object);
}