import axios from 'axios';
import { environment } from '../configuration/environment';

export interface BlobImageResponse {
    cameraName: string,
    dateTime: string,
    imageUrl: string,
    locationName: string,
    plate: string
};
export class SearchCarServices {
    getBlobImages(vehiclePlate: string, selectedCamera: number, selectedLocation: number) {
        return axios.get(environment().baseUrl + environment().version + '/image/get-by-plate',
            {
                params: {
                    plate: vehiclePlate,
                    cameraId: selectedCamera,
                    locationId: selectedLocation
                },
                headers: { Authorization: sessionStorage.getItem('Bearer') }
            });
    }
}