import { environment } from "../configuration/environment";
import axios from "axios";
import { user } from "../pages/Login/types/types";
import { AuthServices } from "./utils/auth-services";

export class UserServices {
    _authServices = new AuthServices();

    getUsers() {
        return axios.get(environment().baseUrl + environment().version + "/user", {
            headers: { Authorization: sessionStorage.getItem("Bearer") },
        });
    }

    addUser(user: user) {
        return axios.post(environment().baseUrl + environment().version + "/user", user, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    updateUser(user: user) {
        return axios.put(environment().baseUrl + environment().version + "/user/" + user.id, user, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    updateProfileUser(user: user) {
        return axios.put(environment().baseUrl + environment().version + "/user/profile/" + user.id, user, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    deleteUser(userId: number) {
        return axios.delete(environment().baseUrl + environment().version + "/user/" + userId, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    getCitiesByState(state: string) {
        return axios.get(environment().baseUrl + environment().version + "/location/by-uf/" + state, {
            headers: { Authorization: sessionStorage.getItem("Bearer") },
        });
    }

    updatePassword(token: string, newPassword: string) {
        return axios.get(environment().baseUrl + environment().version + '/user/update-user-password',
            {
                params: {
                    token: token,
                    newPassword: newPassword
                },
                headers: { Authorization: this._authServices.GetUserToken() }
            });
    }

    sendPasswordRequest(email: string) {
        return axios.get(environment().baseUrl + environment().version + '/user/send-recovery-email',
            {
                params: {
                    emailAddress: email
                },
                headers: { Authorization: this._authServices.GetUserToken() }
            })
    }
}
