import { Button } from "primereact/button";
import { DataView } from 'primereact/dataview';
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import React, { useEffect, useState } from "react";
import { DropdownInfo } from "../../../models/types";
import { PageHeader } from "../../../views/page-header";
import { CameraServices } from "../../../services/camera-services";
import { FeatureServices } from "../../../services/feature-services";
import { BlobImageResponse, SearchCarServices } from "../../../services/SearchCarServices";
import './../styles/search-car.scss';

export const SearchCar = () => {
    var _searchCarServices = new SearchCarServices();
    var _featureServices = new FeatureServices();
    var _cameraServices = new CameraServices();

    const [selectedPlate, setSelectedPlate] = useState<string>();
    const [images, setImages] = useState<BlobImageResponse[]>([]);
    const [loadingImages, setLoadingImages] = useState<boolean>(false);
    const [loadingLocations, setLoadingLocations] = useState<boolean>(true);
    const [loadingCameras, setLoadingCameras] = useState<boolean>(true);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [cameras, setCameras] = useState<DropdownInfo[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [selectedCamera, setSelectedCamera] = useState<number>(0);

    useEffect(() => {
        loadLocations();
    }, [])

    const loadLocations = () => {
        setLoadingLocations(true);
        _featureServices.getLocationsByFeature('UPLOAD-MOTORCICLE-IMAGES')
            .then(data => {
                setLocations(data.data);
                setLoadingLocations(false);
            })
    }
    const getVehicleImages = (vehiclePlate: string, cameraId: number, locationId: number) => {
        setLoadingImages(true);
        _searchCarServices.getBlobImages(vehiclePlate, cameraId, locationId)
            .then((data: any) => {
                setImages(data.data);
                setLoadingImages(false);
            });
    }
    const onSelectedUF = (e: { value: any }) => {
        setSelectedLocation(e.value);
        setLoadingCameras(true);

        let response: DropdownInfo[] = _cameraServices.getCamerasByLocation(e.value);

        setSelectedCamera(0);
        setCameras(response);
        setLoadingCameras(false);
    }
    const onSelectedCamera = (e: { value: any }) => {
        setSelectedCamera(e.value);
    }
    const downloadImage = (imageUrl: string) => {
        window.location.href = imageUrl;
    }
    const updatePlate = (value: any) => {
        var result: string = value.toUpperCase();
        setSelectedPlate(result);
    }
    const renderSearchFilters = () => {
        return (
            <div className="card">
                <div className="flex">
                    <div className="col-4 lg:col-6 xl:col-2">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-map-marker"></i>
                            </span>
                            <Dropdown
                                options={locations}
                                placeholder='Selecione uma localidade'
                                className="mr-2"
                                optionLabel="description"
                                optionValue="id"
                                value={selectedLocation}
                                disabled={loadingLocations}
                                onChange={(e) => onSelectedUF(e)}>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="col-4 lg:col-6 xl:col-2">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-camera"></i>
                            </span>
                            <Dropdown
                                options={cameras}
                                placeholder='Camera'
                                className="mr-2"
                                optionLabel="description"
                                optionValue="id"
                                value={selectedCamera}
                                disabled={loadingCameras}
                                onChange={(e) => onSelectedCamera(e)}>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="col-4 lg:col-6 xl:col-2">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-tag"></i>
                            </span>
                            <InputText
                                className="mr-2"
                                value={selectedPlate}
                                placeholder="Placa do veículo"
                                onChange={(e) => updatePlate(e.target.value)}>
                            </InputText>
                        </div>
                    </div>
                    <div className="col-4 lg:col-6 xl:col-2">
                        <Button
                            label="Pesquisar"
                            disabled={loadingImages || selectedLocation === 0}
                            onClick={(e) => getVehicleImages(selectedPlate, selectedCamera, selectedLocation)}>
                        </Button>
                    </div>
                </div>
            </div>)
    }
    const renderSkeletonDataScroller = () => {
        let responseArray: any[] = [];

        for (let i = 0; i < 1; i++) {
            responseArray.push(skeletonGridItem());
        }

        return (
            <div className="card">
                <div className="p-dataview p-component p-dataview-grid">
                    <div className="p-dataview-content">
                        <div className="flex">
                            {responseArray}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const skeletonGridItem = () => {
        return (
            <div className="col-12 sm:col-4 lg:col-12 xl:col-2 p-2">
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                        <Skeleton className="w-6rem border-round h-1rem" />
                    </div>
                    <div className="flex flex-column align-items-center gap-3 py-5">
                        <Skeleton className="w-6 shadow-2 border-round h-10rem" />
                        <Skeleton className="w-8rem border-round h-2rem" />
                    </div>
                    <div className="flex align-items-center justify-content-between">
                        <Skeleton className="w-4rem border-round h-2rem" />
                        <Skeleton shape="circle" className="w-3rem h-3rem" />
                    </div>
                </div>
            </div>
        );
    }
    const gridItem = (product: BlobImageResponse) => {
        return (
            <div className="col-12 sm:col-4 lg:col-12 xl:col-2 p-2">
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                        <div className="flex align-items-center gap-2">
                            <i className="pi pi-camera"></i>
                            <span className="font-semibold">{product.cameraName}</span>
                        </div>
                    </div>
                    <div className="flex flex-column align-items-center gap-3 py-5">
                        <img className="w-6 shadow-2 border-round" src={product.imageUrl} alt={product.plate} />
                        <div className="text-2xl font-bold">
                            <div className="flex align-items-center gap-2">
                                <i className="pi pi-car text-2xl"></i>
                                <span >{product.plate}</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex align-items-center justify-content-between">
                        <div className="flex align-items-center gap-2">
                            <i className="pi pi-clock"></i>
                            <span >{product.dateTime}</span>
                        </div>
                        <Button icon="pi pi-download" className="p-button-rounded" onClick={(e) => downloadImage(product.imageUrl)}></Button>
                    </div>
                </div>
            </div>
        );
    }
    const itemTemplate = (product: BlobImageResponse) => {
        if (!product) {
            return;
        }

        return gridItem(product);
    }
    const renderDataScroller = () => {
        return (
            <div className="card">
                <DataView
                    value={images}
                    itemTemplate={itemTemplate}
                    layout='grid'
                    emptyMessage="Nenhuma imagem encontrada para o filtro selecionado"
                    rows={12}
                    paginator>
                </DataView>
            </div>
        )
    }

    return (
        <div>
            <PageHeader title="CONSULTA DE IMAGENS DE MOTOCICLETAS" icon="pi pi-chart-bar" />
            {renderSearchFilters()}
            {loadingImages ? renderSkeletonDataScroller() : renderDataScroller()}
        </div>
    )
} 