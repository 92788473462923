import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import React from 'react';
import { onTimeChange } from '../../../services/utils/input-services';
import { BikerBehaviorConfig } from '../types/types';

export interface LocationBikerBehaviorConfigProps {
    selectedLocation: number,
    loading: boolean,
    saveConfig: () => void,
    config: BikerBehaviorConfig,
    updateConfig: (config: BikerBehaviorConfig) => void
}
export const LocationBikerBehaviorConfig = (props: LocationBikerBehaviorConfigProps) => {

    return (
        <React.Fragment>
            {props.selectedLocation !== 0 && !props.loading &&
                < div className='card mt-2 location-convoy-config' >
                    <h5>
                        <i className='pi pi-cog mr-2'></i>
                        Configurar horários de operação da análise comportamental de motoqueiros:
                    </h5>
                    <div className='grid'>
                        <div className='col-2'>
                            <div className="p-inputgroup">
                                <strong className='mr-2'>De:</strong>
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-clock" />
                                </span>
                                <Calendar
                                    hourFormat='24'
                                    stepMinute={30}
                                    disabled={props.loading}
                                    value={props.config.startTime}
                                    showOnFocus
                                    onChange={(e: any) => onTimeChange(e, 'startHour', props.config, props.updateConfig)}
                                    timeOnly
                                />
                            </div>
                        </div>
                        <div className='col-2'>
                            <div className="p-inputgroup">
                                <strong className='mr-2'>Até as:</strong>
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-clock" />
                                </span>
                                <Calendar
                                    timeOnly
                                    showOnFocus
                                    stepMinute={30}
                                    disabled={props.loading}
                                    value={props.config.stopTime}
                                    onChange={(e: any) => onTimeChange(e, 'stopHour', props.config, props.updateConfig)}
                                />
                            </div>
                        </div>
                        <div className='col-4'>
                            <Button label='Atualizar Horários' onClick={(e) => { props.saveConfig() }} />
                        </div>
                    </div>
                </div >}
        </React.Fragment>
    );
}