import classNames from "classnames";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { onCheckedChange, onInputChange, onNumberChange } from "../../../../services/utils/input-services";
import { getIntegrationList } from "../../../../services/camera-services";
import './../../styles/camera.scss';
import { cameraIntegration } from "../../types/types";

export interface CreateIntegrationDialogProps {
    visible: boolean,
    integration: cameraIntegration,
    setCameraIntegration: (newIntergation: cameraIntegration, isEditing: boolean) => void,
    setIntegration: (integration: cameraIntegration) => void,
    alreadyHasIntegration: () => boolean,
    onCloseDialog: () => void,
    isEditing: boolean
}

export const CrateIntegrationDialog = (props: CreateIntegrationDialogProps) => {
    const [submitted, setSubmitted] = useState<boolean>(false);

    const onAddNewIntegration = () => {
        setSubmitted(true);

        if (props.integration.chaveIntegracao) {
            props.onCloseDialog();
            props.setCameraIntegration(props.integration, props.isEditing);
            setSubmitted(false);
        }
    }

    const intergationDialogFooter = (
        <>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={props.onCloseDialog}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                disabled={props.alreadyHasIntegration() && !props.isEditing}
                onClick={(e) => onAddNewIntegration()}
            />
        </>
    )

    const getIntegrationName = () => {
        let nomeIntegracao = getIntegrationList
            .filter(x => x.value === props.integration.idIntegracao)
            .map((valor) => valor.name)[0];

        return <strong>{nomeIntegracao}</strong>;
    }

    return (
        <>
            <Dialog visible={props.visible} footer={intergationDialogFooter} onHide={props.onCloseDialog} style={{ width: "25%" }} header="NOVA INTEGRAÇÃO" modal className="p-fluid">
                <div className="grid">
                    <div className="p-field  col-6">
                        <strong>Tipo Integração:</strong>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-user"></i>
                            </span>
                            <Dropdown
                                optionLabel="name"
                                optionValue="value"
                                options={getIntegrationList}
                                value={props.integration.idIntegracao}
                                onChange={(e: any) => onNumberChange(e, "idIntegracao", props.integration, props.setIntegration)}
                            />
                        </div>
                    </div>
                    <div className="p-field  col-6">
                        <strong>Token Integração:</strong>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-user"></i>
                            </span>
                            <InputText
                                value={props.integration.chaveIntegracao}
                                onChange={(e: any) => onInputChange(e, "chaveIntegracao", props.integration, props.setIntegration)}
                                required
                                autoFocus
                                disabled={props.alreadyHasIntegration() && !props.isEditing}
                                className={classNames({ "p-invalid": submitted && !props.integration.chaveIntegracao })}
                            />
                        </div>
                        {submitted && !props.integration.chaveIntegracao && <small className="p-invalid">Insira um token.</small>}
                    </div>
                    <div className="p-field col-6">
                        <Checkbox
                            className="mr-2"
                            inputId="checkOption3"
                            name="option"
                            checked={props.integration.indAtivo}
                            onChange={(e) => onCheckedChange(e, "indAtivo", props.integration, props.setIntegration)}
                        />
                        <strong>Ativa</strong>
                    </div>
                </div>
                {!props.isEditing && props.alreadyHasIntegration() && <small className="p-invalid field-validation">*essa camera já tem uma integração do  tipo {getIntegrationName()}*</small>}
            </Dialog>

        </>
    )
}