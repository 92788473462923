import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { NavigateToLocation } from "../../../components/Navigation/navigate-to-location";
import { DropdownInfo, blackListSearch } from "../../../models/types";
import { BlackListServices } from "../../../services/black-list-services";
import { FeatureServices } from "../../../services/feature-services";
import { ToastServices } from "../../../services/utils/toast-services";
import { PageHeader } from "../../../views/page-header";
import "../styles/black-list-search.scss";
import { EventBlackListGrid } from "./grids/event-black-list-grid";
import { EventBlackListHeader } from "./headers/event-black-list-header";

export const EventBlackList = () => {
    const _featureServices = new FeatureServices();
    const _blackListServices = new BlackListServices();
    const _toastServices = new ToastServices();

    const [plate, setPlate] = useState<string>(null);
    const [blackList, setBlackList] = useState<blackListSearch[]>([]);
    const [locations, setLocations] = useState<DropdownInfo[]>(null);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [cameras, setCameras] = useState<DropdownInfo[]>(null);
    const [selectedCamera, setSelectedCamera] = useState<number>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        loadLocations();
        getBlackListEvents();
    }, []);

    const loadLocations = () => {
        setLoading(true);

        _featureServices.getLocationsByFeature('BLACK-LIST')
            .then((data: any) => {
                setLocations(data.data);
                setLoading(false);
            }).catch((error: any) => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            });
    }

    const getBlackListEvents = (plate: string = null, locationId: number = null, cameraId: number = null) => {
        setLoading(true);

        _blackListServices.getBlackListEvents(plate, locationId, cameraId)
            .then((response: any) => {
                setBlackList(response.data);
                setLoading(false);
            }).catch((error: any) => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            });
    };

    const onPlateInputChange = (event: string) => {
        setPlate(event);
        getBlackListEvents(event, selectedLocation, selectedCamera);
    };

    const onSelectedLocation = (e: { value: any }) => {
        const locationId = e.value;
        setSelectedLocation(locationId);

        let result: DropdownInfo[] = [];
        result.push({ id: 0, description: "TODAS CÂMERAS" });

        if (locationId != 0) {
            setLoading(true);
            _blackListServices.getCameras(locationId).then((response: any) => {
                response.data.forEach(
                    (camera: DropdownInfo) => {
                        result.push(camera);
                    },
                    (error: any) => { _toastServices.showErrorRequestMessage(error); }
                );
                setLoading(false);
            });
        }

        setCameras(result);
        setSelectedCamera(null);

        getBlackListEvents(null, e.value, null);
    };

    const onSelectedCamera = (event: any) => {
        const value = event.value;

        setSelectedCamera(value);

        getBlackListEvents(null, selectedLocation, value);
    };

    const downloadImage = (imageUrl: string) => {
        window.location.href = imageUrl;
    };

    const renderGridItem = (blackList: blackListSearch) => {
        return (
            <>
                <div className="product-item">
                    <img src={blackList.imageUrl} alt={blackList.plate} className='black-list-grid-item-image' />
                    <div className="product-detail">
                        <div style={{ fontSize: '1.5rem' }}>
                            <i className="pi pi-tag product-category-icon"></i>
                            <span className="product-category">{blackList.plate}</span>
                        </div>
                        <div>
                            <i className="pi pi-camera product-category-icon"></i>
                            <span className="product-category">{blackList.cameraName}</span>
                        </div>
                        <div>
                            <i className="pi pi-map-marker product-category-icon"></i>
                            <span className="product-category">{blackList.locationName}</span>
                        </div>
                    </div>
                    <div className="product-detail">
                        <div>
                            <i className="pi pi-user-plus product-category-icon"></i>
                            <span className="product-category">{blackList.userName}</span>
                        </div>
                        <div>
                            <i className="pi pi-clock product-category-icon"></i>
                            <span className="product-category">{blackList.dateTime}</span>
                        </div>
                    </div>
                    <div className="product-action">
                        <Button
                            icon="pi pi-map"
                            label="Mapa"
                            disabled
                        />
                        <Button
                            icon="pi pi-download"
                            label="Download"
                            onClick={(e) => downloadImage(blackList.imageUrl)}
                        />
                    </div>
                </div>
            </>
        );
    }

    const itemTemplate = (blackList: blackListSearch) => {
        if (!blackList) {
            return;
        }

        return renderGridItem(blackList);
    }

    return (
        <div className="grid">
            <div className="col-12">
                <PageHeader
                    title="EVENTOS DE BLACK LIST COMPORTAMENTAL"
                    icon="pi pi-chart-bar"
                />
                <div className="card">
                    <NavigateToLocation
                        icon="pi-spin pi-cog"
                        text="CRIAR NOVA BLACK-LIST COMPORTAMENTAL"
                        to="black-list"
                    />
                    <EventBlackListHeader
                        cameras={cameras}
                        loading={loading}
                        locations={locations}
                        onPlateInputChange={onPlateInputChange}
                        onSelectedLocation={onSelectedLocation}
                        onSelectedCamera={onSelectedCamera}
                        plate={plate}
                        selectedCamera={selectedCamera}
                        selectedLocation={selectedLocation}
                    />
                    <EventBlackListGrid
                        blackList={blackList}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
};
