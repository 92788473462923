import React, { useEffect, useState } from 'react';
import { DropdownInfo } from '../../../models/types';
import { ToastServices } from '../../../services/utils/toast-services';
import { PageHeader } from '../../../views/page-header';
import { FeatureServices } from '../../../services/feature-services';
import { LocationFeatureServices } from '../../../services/location-feature-services';
import { Feature } from '../../Features/types/types';
import { emptyFeature } from '../types/types';
import { WhatsappFeaturesDataTable } from './data-tables/whatsapp-features-data-table';
import { CreateFeatureDialog } from './dialogs/create-feature-dialog';
import { WhatsappFeturesHeader } from './headers/whatsapp-features-header';

export const WhatsappFetures = () => {
    const _toastServices = new ToastServices();
    const _featureServices = new FeatureServices();
    const _locationFeatureServices = new LocationFeatureServices();

    const [feature, setFeature] = useState<Feature>(emptyFeature);
    const [loading, setLoading] = useState<boolean>();
    const [features, setFeatures] = useState<Feature[]>();
    const [locations, setLocations] = useState<DropdownInfo[]>();
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [createFeatureDialog, setCreateFeatureDialog] = useState<boolean>();

    useEffect(() => {
        getLocations();
    }, [])

    const getLocationFeatures = (selectedLocation: number) => {
        _locationFeatureServices.getLocationFeatures(selectedLocation, true)
            .then((data) => {
                setFeatures(data.data);
            });
    }

    const getLocations = () => {
        setLoading(true);
        _featureServices.getLocationsByFeature('WHATSAPP-NOTIFICATION')
            .then((data: any) => {
                setLocations(data.data);
                setLoading(false);
            }).catch((error) => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            });
    }

    const onSelectedLocation = (e: { value: any }) => {
        setSelectedLocation(e.value);
        getLocationFeatures(e.value);
    }

    const onEditFeature = (feature: Feature) => {
        setFeature(feature);
        setCreateFeatureDialog(true);
    }

    const onCloseDialog = () => {
        setCreateFeatureDialog(false);
    }

    const onSaveFeature = () => {
        _locationFeatureServices.updateLocationFeatureWhatsappNotification(feature.locationFeatureId, feature.whatsappNotification)
            .then((data: any) => {
                _toastServices.showSuccesMessage("Recurso atualizado com sucesso");
                getLocationFeatures(selectedLocation);
                setLoading(false);
            }).catch((error) => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            });
        setCreateFeatureDialog(false);
    }

    return (
        <React.Fragment>
            <PageHeader title="CONFIGURAÇÃO DE EVENTOS DE NOTIFICAÇÕES WHATSAPP" icon="pi pi-chart-bar" />
            <div className='card'>
                <WhatsappFeturesHeader
                    loading={loading}
                    locations={locations}
                    onSelectedLocation={onSelectedLocation}
                    selectedLocation={selectedLocation}
                />
                <WhatsappFeaturesDataTable
                    editFeature={onEditFeature}
                    features={features}
                    loading={loading}
                />
                <CreateFeatureDialog
                    feature={feature}
                    closeDialog={onCloseDialog}
                    setFeature={setFeature}
                    showDialog={createFeatureDialog}
                    saveFeature={onSaveFeature}
                />
            </div>
        </React.Fragment>
    )
}