import { Dialog } from 'primereact/dialog';
import React from 'react';
import { Button } from 'primereact/button';
import { cameraIntegration } from '../../types/types';

export interface DDeleteIntegrationDialogProps {
    visible: boolean,
    hideDialog: () => void,
    onDeleteCameraIntegration: () => void,
    integration: cameraIntegration
}

export const DeleteIntegrationDialog = (props: DDeleteIntegrationDialogProps) => {

    const deleteCameraDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={props.onDeleteCameraIntegration} />
        </>
    )

    const dialogHeader = `EXCLUÍR INTEGRAÇÃO: ${props.integration.nomeIntegracao}`;

    return (
        <Dialog visible={props.visible} style={{ width: '700px' }} header={dialogHeader} modal footer={deleteCameraDialogFooter} onHide={props.hideDialog}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle mr-2" style={{ fontSize: '2rem' }} />
                {props.integration && <span>Tem certeza que deseja deletar a integração?</span>}
            </div>
        </Dialog>
    )
}