import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { getIntegrationList } from '../../../../services/camera-services';
import { Toolbar } from 'primereact/toolbar';
import { cameraIntegration } from '../../types/types';

export interface IntegrationsDataTableProps {
    integrations: cameraIntegration[],
    checkBoxEditor: (e: any) => void,
    editIntegration: (integration: cameraIntegration) => void,
    confirmDeleteIntegration: (integration: cameraIntegration) => void,
    onOpenIntegrationDialog: () => void
}



export const IntegrationsDataTable = (props: IntegrationsDataTableProps) => {

    const ativoBodyTemplate = (rowData: any) => {
        if (rowData.indAtivo === true) {
            return (
                <>
                    <Tag severity="success" value="Online" icon="pi pi-check"></Tag>
                </>
            );
        } else {
            return (
                <>
                    <Tag severity="danger" value="Offline" icon="pi pi-times"></Tag>
                </>
            );
        }
    }

    const actionBodyTemplate = (rowData: cameraIntegration) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    disabled={!rowData.idCameraIntegracao}
                    className="p-button-rounded p-button mr-2"
                    onClick={() => props.editIntegration(rowData)}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => props.confirmDeleteIntegration(rowData)}
                />
            </div>
        );
    }
    const toolBarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-">
                    <Button label="Nova Integração" icon="pi pi-plus" className="p-button-success" onClick={(e) => props.onOpenIntegrationDialog()} />
                </div>
            </React.Fragment>
        )
    }
    const bodyIntegrationNameTemplate = (rowData: any) => {
        let nomeIntegracao = getIntegrationList
            .filter(x => x.value === rowData.idIntegracao)
            .map((valor) => valor.name)[0];

        return <strong>{nomeIntegracao}</strong>;
    }
    return (
        <React.Fragment>
            <Toolbar className="p-mb-4" left={toolBarTemplate}></Toolbar>
            <DataTable value={props.integrations} editMode="row" dataKey="idIntegracao">
                <Column field="nomeIntegracao" body={bodyIntegrationNameTemplate} header="Nome Integração" style={{ width: "20%" }}></Column>
                <Column field="chaveIntegracao" header="Token Integração" style={{ width: "20%" }}></Column>
                <Column field="indAtivo" header="Ativa" editor={(options) => props.checkBoxEditor(options)} style={{ width: "20%" }} body={ativoBodyTemplate}></Column>
                <Column body={actionBodyTemplate}></Column>
            </DataTable>
        </React.Fragment>
    )
}