import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import React from 'react';
import { Feature } from '../../../Features/types/types';

export interface WhatsappFeaturesDataTableProps {
    features: Feature[],
    loading: boolean
    editFeature: (e: Feature) => void
}

export const WhatsappFeaturesDataTable = (props: WhatsappFeaturesDataTableProps) => {
    const actionsTemplate = (rowData: Feature) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button mr-2"
                    onClick={() => props.editFeature(rowData)}
                />
            </div>
        );
    }

    const featureNameTemplate = (rowData: Feature) => {
        return rowData.featureName;
    }

    const whatsappAlertTemplate = (rowData: Feature) => {
        if (rowData.whatsappNotification === true) {
            return <Tag severity="success" value="Ativada" icon="pi pi-check" />;
        }

        return <Tag severity="danger" value="Desativada" icon="pi pi-times" />;
    }

    return (
        <div className='col-12'>
            <DataTable
                columnResizeMode='fit'
                value={props.features}
                dataKey="id"
                loading={props.loading}
                paginator
                rows={20}
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                emptyMessage='Nenhum recurso habilitado encontrado para a localidade selecionada'
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} recipientes">
                <Column field="recipientName" header="Nome do recurso" sortable body={featureNameTemplate}></Column>
                <Column field="recipientCode" header="Notificação por whatsapp" sortable body={whatsappAlertTemplate} ></Column>
                <Column body={actionsTemplate} header="Ações"></Column>
            </DataTable>
        </div>
    )
}