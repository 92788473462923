import { Dialog } from 'primereact/dialog';
import React from 'react';
import { ConvoyAlertResponse } from '../../../../models/types';
import '../../styles/convoy-alert-dialog.scss';
import { VehicleTypeIcon } from '../../../../components/VehicleTypeIcon/vehicle-type-icon';
import { ImageViewer } from '../../../../components/ImageViewer/image-viewer';

export interface ConvoyAlertDialogProps {
    convoyAlertDialog: boolean,
    convoyAlert: ConvoyAlertResponse,
    setConvoyAlertDialog: (state: boolean) => void
}

export const ConvoyAlertDialog = (props: ConvoyAlertDialogProps) => {
    return (
        <Dialog visible={props.convoyAlertDialog} className='convoy-dialog' resizable={false} header='ALERTA DE COMBOIO DETECTADO' onHide={() => props.setConvoyAlertDialog(false)}>
            <div className="p-2">
                <div className='grid' style={{ margin: '0px', border: '0.5px solid var(--text-color)', borderRadius: '10px' }}>
                    <div className='col-12'>
                        <div className='title-message justify-content-center flex'>
                            <span>
                                <i className="pi pi-camera mr-2" />
                                {props.convoyAlert.cameraName}
                            </span>
                        </div>
                        <div className='col-12'>
                            <div className='flex justify-content-center'>
                                <i className="pi pi-map" />
                                <span className='ml-2 convoy-text'>{props.convoyAlert.cameraAddress}</span>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className='flex col-6'>
                        <div className='grid col-12'>
                            <div className='col-12'>
                                <div className='grid'>
                                    <div className='col-12'>
                                        <div className="flex justify-content-center">
                                            <ImageViewer
                                                alt={props.convoyAlert.firstVehiclePlate}
                                                src={props.convoyAlert.firstVehicleImageUrl}
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    border: '1px solid var(--text-color)',
                                                    borderRadius: '15px',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-12'>
                                        <div className='flex justify-content-center'>
                                            <i className="pi pi-tag" />
                                            <span className='ml-2 convoy-text'>{props.convoyAlert.firstVehiclePlate}</span>
                                            <span className="mr-2 ml-2"></span>
                                            <VehicleTypeIcon classes="mr-2" size="lg" val={props.convoyAlert.firstVehicleType} typeInput="number" />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='flex justify-content-center'>
                                            <i className="pi pi-clock" />
                                            <span className='ml-2 convoy-text'>{props.convoyAlert.firstEventDateTime}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex col-6'>
                        <div className='grid col-12'>
                            <div className='col-12'>
                                <div className='grid'>
                                    <div className='col-12'>
                                        <div className="flex justify-content-center">
                                            <ImageViewer
                                                alt={props.convoyAlert.secondVehiclePlate}
                                                src={props.convoyAlert.secondVehicleImageUrl}
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    border: '1px solid var(--text-color)',
                                                    borderRadius: '15px',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='flex justify-content-center'>
                                            <i className="pi pi-tag" />
                                            <span className='ml-2 convoy-text'>{props.convoyAlert.secondVehiclePlate}</span>
                                            <span className="mr-2 ml-2"></span>
                                            <VehicleTypeIcon classes="mr-2" size="lg" val={props.convoyAlert.secondVehicleType} typeInput="number" />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='flex justify-content-center'>
                                            <i className="pi pi-clock" />
                                            <span className='ml-2 convoy-text'>{props.convoyAlert.secondEventDateTime}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}