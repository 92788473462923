import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import React from 'react';
import { PerformanceReportResponse } from '../../types/types';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { faL } from '@fortawesome/free-solid-svg-icons';

export interface MonitoringDataTableProps {
    loading: boolean,
    reports: PerformanceReportResponse[],
    globalFilter: string,
    setGlobalFilter: (val: string) => void
}

export const MonitoringDataTable = (props: MonitoringDataTableProps) => {
    const header = `Demonstração de performance das Localidades hoje ${new Date().toLocaleDateString('pt-BR')}` ;

    const ativoBodyTemplate = (rowData: PerformanceReportResponse) => {
        if (rowData.healthy === true) {
            return <Tag severity="success" value="Saudável" icon="pi pi-check" />;
        }

        return <Tag severity="danger" value="Verificar" icon="pi pi-times" />;
    }

    const textBodyTemplate = (rowData: any, valueKey: string) => {
        return <span>{rowData[`${valueKey}`]}</span>;
    }

    const dateBodyTemplate = (rowData: PerformanceReportResponse) => {
        return <span>{rowData.lastIntegration}</span>;
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                <React.Fragment>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={props.globalFilter}
                            onInput={(e: any) => props.setGlobalFilter(e.target.value)}
                            placeholder="Procurar..."
                        />
                    </span>
                </React.Fragment>
            </>
        )
    }

    return (
        <>
            <Toolbar
                className="p-mb-4"
                right={rightToolbarTemplate}
            />
            <DataTable
                columnResizeMode='fit'
                value={props.reports}
                dataKey="id"
                paginator
                rows={40}
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} cameras"
                globalFilter={props.globalFilter}
                emptyMessage="Nenhuma camera encontrada."
                header={header}
                loading={props.loading}>
                <Column field="locationName" header="LOCALIDADE" sortable></Column>
                <Column field="cameraName" header="ÚLTIMA CAMERA" sortable body={x => textBodyTemplate(x, 'cameraName')} ></Column>
                <Column field="lastIntegration" header="ÚLTIMA INTEGRAÇÃO" sortable body={dateBodyTemplate} ></Column>
                <Column field="healthy" header="SAUDÁVEL?" sortable body={ativoBodyTemplate}></Column>
            </DataTable>
        </>
    )
}