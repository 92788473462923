import { Dialog } from 'primereact/dialog';
import React from 'react';
import { Feature } from '../../../Features/types/types';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { onCheckedChange } from '../../../../services/utils/input-services';

export interface CreateFeatureDialogProps {
    showDialog: boolean,
    feature: Feature,
    setFeature: (feature: Feature) => void,
    saveFeature: () => void,
    closeDialog: () => void,
}

export const CreateFeatureDialog = (props: CreateFeatureDialogProps) => {
    const dialogHeader = `Editando recurso: ${props.feature.featureName}`;

    const featureDialogFooter = (
        <>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={props.closeDialog}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={props.saveFeature}
            />
        </>
    )
    return (
        <Dialog visible={props.showDialog} style={{ width: '600px' }} header={dialogHeader} footer={featureDialogFooter} onHide={props.closeDialog}>
            <div className='grid'>
                <div className='col-6 mt-2'>
                    <Checkbox
                        checked={props.feature.whatsappNotification}
                        onChange={(e) => onCheckedChange(e, 'whatsappNotification', props.feature, props.setFeature)}
                    />
                    <strong className="ml-2 titulo-categoria">Habilitar Notificação por Whatsapp</strong>
                </div>
            </div>
        </Dialog>
    );
}