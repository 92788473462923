import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import React, { useEffect, useRef, useState } from "react";
import { environment } from "../../../configuration/environment";
import { BehavioralConvoyAlertResponse, ConvoyAlertResponse, CustomAlarmAlertResponse, DssEventSubscriptionResponse, NoPlateAlertResponse, PointABAlertResponse, blackListResponse, cardResponse, exceptionResponse, integrationCardResponse, plateEventResponse } from "../../../models/types";
import { DashboardServices, isResponseFromSameCamera, isResponseFromSameLocation, userHasAccessToLocation } from "../../../services/dashboard-services";
import { blankBehavioralConvoyAlert, blankBlackListAlert, blankCard, blankConvoyAlert, blankCustomAlarmAlert, blankDssEventSubscriptionAlert, blankIntegrationCard, blankNoPlateAlert, blankPointABAlert } from "../types/types";
import AudioPlayer from "./audio-data";
import { ExceptionList } from "./components/exception-list";
import { LiveCard } from "./components/live-card";
import { VehicleVisualizationCard } from "./components/vehicle-visualization-list";
import { BehavioralConvoyAlertDialog } from "./dialog/behavioral-convoy-alert-dialog";
import { BlackListDialog } from "./dialog/black-list-dialog";
import { ConvoyAlertDialog } from "./dialog/convoy-alert-dialog";
import { CustomAlarmAlertDialog } from "./dialog/custom-alarm-alert-dialog";
import { DssEventSubscriptionDialog } from "./dialog/dss-event-subscription-dialog";
import { NoPlateAlertDialog } from "./dialog/no-plate-alert-dialog";
import { PointABAlertDialog } from "./dialog/point-AB-alert-dialog";

interface SignalRLiveDataProps {
    selectedLocation: number;
    selectedCamera: number;
    selecteDate: Date;
    setSignalRConnected: (val: boolean) => void;
}
export const SignalRLiveData = (props: SignalRLiveDataProps): JSX.Element => {
    const today = new Date();
    const _dashboardServices = new DashboardServices();

    const lastOlderDate = useRef<boolean>(false);
    const lastSelectedCamera = useRef<number>(0);
    const lastSelectedLocation = useRef<number>(0);
    const lastConnection = useRef<HubConnection>();
    const lastCard = useRef<cardResponse>(blankCard);
    const lastExceptionList = useRef<exceptionResponse[]>([]);
    const lastReadPlateList = useRef<plateEventResponse[]>([]);
    const lastIntegrationCardHelios = useRef<integrationCardResponse>(blankIntegrationCard);
    const lastIntegrationCardCortex = useRef<integrationCardResponse>(blankIntegrationCard);
    const lastIntegrationCardAlerta = useRef<integrationCardResponse>(blankIntegrationCard);

    const [playAudio, setPlayAudio] = useState(false);
    const [audioFile, setAudioFile] = useState("");
    const [olderDate, setOlderDate] = useState<boolean>(false);
    const [card, setCard] = useState<cardResponse>(blankCard);
    const [loadingCard, setLoadingCard] = useState<boolean>(true);
    const [loadingIntegrationCard, setLoadingIntegrationCard] = useState<boolean>(true);
    const [blackListDialog, setBlackListDialog] = useState(false);
    const [connection, setConnection] = useState<HubConnection>();
    const [convoyAlertDialog, setConvoyAlertDialog] = useState(false);
    const [pointABAlertDialog, setPointABAlertDialog] = useState(false);
    const [noPlateAlertDialog, setNoPlateAlertDialog] = useState(false);
    const [customAlarmAlertDialog, setCustomAlarmAlertDialog] = useState(false);
    const [readPlateList, setReadPlateList] = useState<plateEventResponse[]>([]);
    const [exceptionList, setExceptionList] = useState<exceptionResponse[]>([]);
    const [integrationCardHelios, setIntegrationCardHelios] = useState<integrationCardResponse>(blankIntegrationCard);
    const [integrationCardCortex, setIntegrationCardCortex] = useState<integrationCardResponse>(blankIntegrationCard);
    const [integrationCardAlerta, setIntegrationCardAlerta] = useState<integrationCardResponse>(blankIntegrationCard);
    const [dssEventSubscriptionDialog, setDssEventSubscriptionDialog] = useState(false);
    const [behavioralConvoyAlertDialog, setBehavioralConvoyAlertDialog] = useState(false);
    const [convoyAlert, setConvoyAlert] = useState<ConvoyAlertResponse>(blankConvoyAlert);
    const [noPlateAlert, setNoPlateAlert] = useState<NoPlateAlertResponse>(blankNoPlateAlert);
    const [blackListAlert, setBlakListAlert] = useState<blackListResponse>(blankBlackListAlert);
    const [pointABAlert, setPointABAlert] = useState<PointABAlertResponse>(blankPointABAlert);
    const [customAlarmAlert, setCustomAlarmAlert] = useState<CustomAlarmAlertResponse>(blankCustomAlarmAlert);
    const [behavioralConvoyAlert, setBehavioralConvoyAlert] = useState<BehavioralConvoyAlertResponse>(blankBehavioralConvoyAlert);
    const [dssEventSubscription, setDssEventSubscription] = useState<DssEventSubscriptionResponse>(blankDssEventSubscriptionAlert);

    lastCard.current = card;
    lastOlderDate.current = olderDate;
    lastConnection.current = connection;
    lastReadPlateList.current = readPlateList;
    lastExceptionList.current = exceptionList;
    lastIntegrationCardHelios.current = integrationCardHelios;
    lastIntegrationCardCortex.current = integrationCardCortex;
    lastIntegrationCardAlerta.current = integrationCardAlerta;
    lastSelectedCamera.current = props.selectedCamera;
    lastSelectedLocation.current = props.selectedLocation;

    const emptyExceptionList = lastExceptionList.current.length <= 0;

    const intervalInMilliseconds = 300000;
    useEffect(() => {
        const interval = setInterval(() => {
            updateCardData();
            updateIntegrationCardData("helios");
            updateIntegrationCardData("cortex");
            setReadPlateList([]);
            setExceptionList([]);
        }, intervalInMilliseconds);

        return () => clearInterval(interval);
    }, [props.selecteDate, props.selectedCamera, props.selectedLocation])

    useEffect(() => {
        if (lastConnection.current === null || lastConnection.current === undefined) {
            const newConnection = new HubConnectionBuilder()
                .withUrl(environment().signalR + "live-connection", {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,
                })
                .withAutomaticReconnect()
                .build();
            setConnection(newConnection);
        }
    }, [connection]);

    useEffect(() => {
        let dateDay: number = props.selecteDate.getDate();
        let todayDay: number = today.getDate();

        let dateMonth: number = props.selecteDate.getMonth();
        let todayMonth: number = today.getMonth();

        let dateYear: number = props.selecteDate.getFullYear();
        let todayYear: number = today.getFullYear();

        if (dateDay < todayDay || dateMonth < todayMonth || dateYear < todayYear) {
            setOlderDate(true);
        } else {
            setOlderDate(false);
        }

        updateCardData();
        updateIntegrationCardData("helios");
        updateIntegrationCardData("cortex");
        setReadPlateList([]);
        setExceptionList([]);
    }, [props.selecteDate, props.selectedCamera, props.selectedLocation])

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then((result) => {
                    console.log("Connected to the SignalR!");
                    props.setSignalRConnected(true);

                    connection.on('camera-report', (readPlateResponse: plateEventResponse) => {
                        handleCardResponse(readPlateResponse);
                        handleReadPlateList(readPlateResponse);
                    })

                    connection.on('integration-report-helios', (integrationResponse: integrationCardResponse) => {
                        handleIntegrationCardResponse(integrationResponse, "helios");
                    })

                    connection.on('integration-report-cortex', (integrationResponse: integrationCardResponse) => {
                        handleIntegrationCardResponse(integrationResponse, "cortex");
                    })

                    connection.on("exception-report", (lastException: exceptionResponse) => {
                        handleExceptionList(lastException);
                    })

                    connection.on('black-list-report', (blackListResponse: blackListResponse) => {
                        if (userHasAccessToLocation(blackListResponse.locationId)) {
                            setPlayAudio(false);
                            setBlackListDialog(false);
                            setBlakListAlert(blankBlackListAlert);
                            handleBlackListAlert(blackListResponse);
                        }
                    })

                    connection.on(`convoy-report`, (convoyAlertResponse: ConvoyAlertResponse) => {
                        if (userHasAccessToLocation(convoyAlertResponse.locationId)) {
                            setConvoyAlertDialog(false);
                            setPlayAudio(false);
                            setConvoyAlert(blankConvoyAlert);
                            handleConvoyAlert(convoyAlertResponse);
                        }
                    })

                    connection.on(`point-ab-report`, (pointABAlertResponse: PointABAlertResponse) => {
                        if (userHasAccessToLocation(pointABAlertResponse.locationId)) {
                            setPointABAlertDialog(false);
                            setPlayAudio(false);
                            setPointABAlert(blankPointABAlert);
                            handlePointABAlert(pointABAlertResponse);
                        }
                    })

                    connection.on(`custom-alarm-report`, (customAlarmAlertResponse: CustomAlarmAlertResponse) => {
                        if (userHasAccessToLocation(customAlarmAlertResponse.locationId)) {
                            setCustomAlarmAlertDialog(false);
                            handleCustomAlarmAlert(customAlarmAlertResponse);
                        }
                    })

                    connection.on(`no-plate-report`, (noPlateAlertResponse: NoPlateAlertResponse) => {
                        if (userHasAccessToLocation(noPlateAlertResponse.locationId)) {
                            setNoPlateAlertDialog(false);
                            handleNoPlateAlert(noPlateAlertResponse);
                        }
                    })

                    connection.on(`behavioral-convoy-report`, (behavioralConvoyAlertResponse: BehavioralConvoyAlertResponse) => {
                        if (userHasAccessToLocation(behavioralConvoyAlertResponse.locationId)) {
                            setBehavioralConvoyAlertDialog(false);
                            handleBehavioralConvoyAlert(behavioralConvoyAlertResponse);
                        }
                    })

                    connection.on(`dss-event-subscription`, (response: DssEventSubscriptionResponse) => {
                        if (userHasAccessToLocation(response.locationId)) {
                            setDssEventSubscriptionDialog(false);
                            handleDssEventSubscriptionAlert(response);
                        }
                    })
                })
                .catch((error) => {
                    console.log("Error while starting SignalR connection!", error);
                    props.setSignalRConnected(false);
                });
        }
    }, [connection])

    const updateCardData = () => {
        setLoadingCard(true);
        _dashboardServices.getCardData(props.selecteDate, props.selectedLocation, props.selectedCamera).then((response: any) => {
            if (response.status === 200) {
                setCard(response.data);
            } else {
                setCard(blankCard);
            }
            setLoadingCard(false);
        });
    }

    const updateIntegrationCardData = (integrationType: string) => {
        setLoadingIntegrationCard(true);
        _dashboardServices.getIntegrationCardData(props.selecteDate, props.selectedLocation, props.selectedCamera, integrationType).then((response: any) => {
            if (integrationType == "helios") {
                if (response.status === 200) {
                    setIntegrationCardHelios(response.data);
                } else {
                    setIntegrationCardHelios(blankIntegrationCard);
                }
            } else if (integrationType == "cortex") {
                if (response.status === 200) {
                    setIntegrationCardCortex(response.data);
                } else {
                    setIntegrationCardCortex(blankIntegrationCard);
                }
            } else if (integrationType == "alerta") {
                if (response.status === 200) {
                    setIntegrationCardAlerta(response.data);
                } else {
                    setIntegrationCardAlerta(blankIntegrationCard);
                }
            }
            setLoadingIntegrationCard(false);
        });
    }

    const onCloseBlackListDialog = () => {
        setBlackListDialog(false);
        setPlayAudio(false);
    }

    const onCloseConvoyAlertDialog = () => {
        setConvoyAlertDialog(false);
        setPlayAudio(false);
    }

    const onClosePointABAlertDialog = () => {
        setPointABAlertDialog(false);
        setPlayAudio(false);
    }

    const onCloseCustomAlarmAlertDialog = () => {
        setCustomAlarmAlertDialog(false);
        setPlayAudio(false);
    }

    const onCloseNoPlateAlertDialog = () => {
        setNoPlateAlertDialog(false);
        setPlayAudio(false);
    }

    const onCloseBehavioralConvoyAlertDialog = () => {
        setBehavioralConvoyAlertDialog(false);
        setPlayAudio(false);
    }

    const onPlayAudioWarning = (sound_name: string) => {
        var _mutedSound = localStorage.getItem("mute-sounds") ?? "false";

        if (_mutedSound === "false") {
            setAudioFile(sound_name);
            setPlayAudio(true);
            setTimeout(() => {
                setPlayAudio(false);
            }, 5000);
        }
    }

    const handleBlackListAlert = (blackListResponse: blackListResponse) => {
        setBlakListAlert(blackListResponse);
        setBlackListDialog(true);
        onPlayAudioWarning("black_list");
    }

    const handleReadPlateList = (readPlateResponse: plateEventResponse) => {
        if (isResponseFromSameLocation(readPlateResponse.locationId, lastSelectedLocation.current) && isResponseFromSameCamera(readPlateResponse.locationId, lastSelectedCamera.current)) {
            let response: plateEventResponse[] = [...lastReadPlateList.current];

            setTimeout(() => {
                response.unshift(readPlateResponse);
            }, 100);

            if (response.length > 10) {
                response.pop();
            }

            setReadPlateList(response);
        }
    }

    const handleExceptionList = (lastException: exceptionResponse) => {
        let response: exceptionResponse[] = [...lastExceptionList.current];

        if (isResponseFromSameLocation(lastException.locationId, lastSelectedLocation.current) && isResponseFromSameCamera(lastException.locationId, lastSelectedCamera.current)) {
            response.unshift(lastException);

            if (response.length > 10) {
                response.pop();
            }

            setTimeout(() => {
                response = [...lastExceptionList.current];
                response.pop();
                setExceptionList(response);
            }, 10000);

            setExceptionList(response);
        }
    }

    const handleCardResponse = (readPlateResponse: plateEventResponse) => {
        let updateCard: cardResponse | undefined = lastCard.current;

        if (updateCard?.quantityCar !== 0 && !lastOlderDate.current) {
            if (isResponseFromSameLocation(readPlateResponse.locationId, lastSelectedLocation.current) && isResponseFromSameCamera(readPlateResponse.locationId, lastSelectedCamera.current)) {
                var vehicleType: string = readPlateResponse.vehicleType;

                setCard({
                    quantityTruck: handleVehicleTypeResponse(vehicleType, "Caminhao", updateCard.quantityTruck),
                    quantityCar: handleVehicleTypeResponse(vehicleType, "Carro", updateCard.quantityCar),
                    quantityMotorBike: handleVehicleTypeResponse(vehicleType, "Moto", updateCard.quantityMotorBike),
                    quantityPickup: handleVehicleTypeResponse(vehicleType, "Pickup", updateCard.quantityPickup),
                    quantityBus: handleVehicleTypeResponse(vehicleType, "Onibus", updateCard.quantityBus),
                    quantityOther: handleVehicleTypeResponse(vehicleType, "Outro", updateCard.quantityOther),
                    quantityOfBikerBehaviorsTriggeredEvents: updateCard.quantityOfBikerBehaviorsTriggeredEvents,
                    quantityOfBlackListTriggeredEvents: updateCard.quantityOfBlackListTriggeredEvents,
                    quantityOfCustomAlarmTriggeredEvents: updateCard.quantityOfCustomAlarmTriggeredEvents,
                    quantityOfDestinationABTriggeredEvents: updateCard.quantityOfDestinationABTriggeredEvents,
                    quantityOfNoPlateTriggeredEvents: updateCard.quantityOfNoPlateTriggeredEvents,
                } as cardResponse);
            }
        }
    }

    const handleConvoyAlert = (convoyAlert: ConvoyAlertResponse) => {
        setConvoyAlert(convoyAlert);
        setConvoyAlertDialog(true);
        onPlayAudioWarning("modo_comboio");
    }

    const handlePointABAlert = (pointABAlert: PointABAlertResponse) => {
        setPointABAlert(pointABAlert);
        setPointABAlertDialog(true);
        onPlayAudioWarning("deslocamento_especial");
    }

    const handleCustomAlarmAlert = (customAlarmAlert: CustomAlarmAlertResponse) => {
        setCustomAlarmAlert(customAlarmAlert);
        setCustomAlarmAlertDialog(true);
        onPlayAudioWarning("alarme_customizado");
    }

    const handleNoPlateAlert = (noPlateAlertResponse: NoPlateAlertResponse) => {
        setNoPlateAlert(noPlateAlertResponse);
        setNoPlateAlertDialog(true);
        onPlayAudioWarning("veículo_sem_placa");
    }

    const handleBehavioralConvoyAlert = (behavioralConvoyAlertResponse: BehavioralConvoyAlertResponse) => {
        setBehavioralConvoyAlert(behavioralConvoyAlertResponse);
        setBehavioralConvoyAlertDialog(true);
        onPlayAudioWarning("modo_comboio_comportamental");
    }

    const handleDssEventSubscriptionAlert = (dssEventSubscription: DssEventSubscriptionResponse) => {
        setDssEventSubscription(dssEventSubscription);
        setDssEventSubscriptionDialog(true);
        onPlayAudioWarning("alarme_customizado");
    }

    const handleVehicleTypeResponse = (vehicleResponse: string, compareValue: string, quantity: number) => {
        if (vehicleResponse === compareValue) {
            var response = (quantity ?? 0) + 1;

            return response;
        }

        return quantity ?? 0;
    }

    const handleIntegrationCardResponse = (integrationResponse: integrationCardResponse, integrationType: string) => {

        if (integrationType == "helios") {

            let updateCard: integrationCardResponse | undefined = lastIntegrationCardHelios.current;

            if (updateCard?.activeCameras !== 0 && !lastOlderDate.current) {
                if (isResponseFromSameLocation(integrationResponse.locationId, lastSelectedLocation.current) && isResponseFromSameCamera(integrationResponse.cameraId, lastSelectedCamera.current)) {
                    setIntegrationCardHelios({
                        totalRead: (updateCard?.totalRead ?? 0) + 1,
                        totalFailure: integrationResponse.isSuccess ? updateCard?.totalFailure : (updateCard?.totalFailure ?? 0) + 1,
                        activeCameras: lastIntegrationCardHelios.current.activeCameras ?? 0,
                        inactiveCameras: lastIntegrationCardHelios.current.inactiveCameras ?? 0,
                        locationCount: lastIntegrationCardHelios.current.locationCount ?? 0,
                        totalSuccess: lastIntegrationCardHelios.current.totalSuccess ?? 0
                    } as integrationCardResponse)
                }

            }
        } else if (integrationType == "cortex") {
            let updateCard: integrationCardResponse | undefined = lastIntegrationCardCortex.current;

            if (updateCard?.activeCameras !== 0 && !lastOlderDate.current) {
                if (isResponseFromSameLocation(integrationResponse.locationId, lastSelectedLocation.current) && isResponseFromSameCamera(integrationResponse.cameraId, lastSelectedCamera.current)) {
                    setIntegrationCardCortex({
                        totalRead: (updateCard?.totalRead ?? 0) + 1,
                        totalFailure: integrationResponse.isSuccess ? updateCard?.totalFailure : (updateCard?.totalFailure ?? 0) + 1,
                        activeCameras: lastIntegrationCardCortex.current.activeCameras ?? 0,
                        inactiveCameras: lastIntegrationCardCortex.current.inactiveCameras ?? 0,
                        locationCount: lastIntegrationCardCortex.current.locationCount ?? 0,
                        totalSuccess: lastIntegrationCardCortex.current.totalSuccess ?? 0
                    } as integrationCardResponse)
                }

            }
        } else if (integrationType == "alerta") {
            let updateCard: integrationCardResponse | undefined = lastIntegrationCardAlerta.current;

            if (updateCard?.activeCameras !== 0 && !lastOlderDate.current) {
                if (isResponseFromSameLocation(integrationResponse.locationId, lastSelectedLocation.current) && isResponseFromSameCamera(integrationResponse.cameraId, lastSelectedCamera.current)) {
                    setIntegrationCardAlerta({
                        totalRead: (updateCard?.totalRead ?? 0) + 1,
                        totalFailure: integrationResponse.isSuccess ? updateCard?.totalFailure : (updateCard?.totalFailure ?? 0) + 1,
                        activeCameras: lastIntegrationCardAlerta.current.activeCameras ?? 0,
                        inactiveCameras: lastIntegrationCardAlerta.current.inactiveCameras ?? 0,
                        locationCount: lastIntegrationCardAlerta.current.locationCount ?? 0,
                        totalSuccess: lastIntegrationCardAlerta.current.totalSuccess ?? 0
                    } as integrationCardResponse)
                }
            }
        }
    }

    return (
        <React.Fragment>
            <React.Fragment>
                <LiveCard isIntegrationCardLoading={loadingIntegrationCard} integrationResponseHelios={lastIntegrationCardHelios.current} integrationResponseCortex={lastIntegrationCardCortex.current} integrationResponseAlerta={lastIntegrationCardAlerta.current} cardResponse={lastCard.current} isCardLoading={loadingCard} isOutDated={olderDate} />
                <VehicleVisualizationCard list={readPlateList} isOlderDate={olderDate} emptyExceptionList={emptyExceptionList} />
                {!emptyExceptionList && <ExceptionList list={lastExceptionList.current} isOlderDate={olderDate} />}
            </React.Fragment>
            <React.Fragment>
                <AudioPlayer play={playAudio} soundName={audioFile} />
                <BlackListDialog blackListAlert={blackListAlert} blackListDialog={blackListDialog} setBlackListDialog={onCloseBlackListDialog} />
                <ConvoyAlertDialog convoyAlert={convoyAlert} convoyAlertDialog={convoyAlertDialog} setConvoyAlertDialog={onCloseConvoyAlertDialog} />
                <PointABAlertDialog pointABAlert={pointABAlert} pointABAlertDialog={pointABAlertDialog} setPointABAlertDialog={onClosePointABAlertDialog} />
                <CustomAlarmAlertDialog customAlarmAlert={customAlarmAlert} customAlarmAlertDialog={customAlarmAlertDialog} setCustomAlarmAlertDialog={onCloseCustomAlarmAlertDialog} />
                <NoPlateAlertDialog noPlateAlert={noPlateAlert} noPlateAlertDialog={noPlateAlertDialog} setNoPlateAlertDialog={onCloseNoPlateAlertDialog} />
                <BehavioralConvoyAlertDialog behavioralConvoyAlert={behavioralConvoyAlert} behavioralConvoyAlertDialog={behavioralConvoyAlertDialog} setBehavioralConvoyAlertDialog={onCloseBehavioralConvoyAlertDialog} />
                <DssEventSubscriptionDialog dssEventSubscriptionAlert={dssEventSubscription} dssEventSubscriptionDialog={dssEventSubscriptionDialog} setDssEventSubscriptionDialog={setDssEventSubscriptionDialog} />
            </React.Fragment>
        </React.Fragment>
    )
};
