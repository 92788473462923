import React, { useState } from 'react';
import { PageHeader } from '../../../views/page-header';
import { DssHeader } from './header/dss-header';
import { CreateDss } from './body/create-dss';

export const Dss = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);

    return (
        <React.Fragment>
            <div className="grid">
                <div className="col-12">
                    <PageHeader
                        title="CONFIGURAÇÃO DSS CLIENT"
                        icon="pi pi-chart-bar"
                    />
                    <div className='card'>
                        <DssHeader
                            loading={loading}
                            setLoading={setLoading}
                            selectedLocation={selectedLocation}
                            setSelectedLocation={setSelectedLocation}
                        />
                        <CreateDss
                            loading={loading}
                            selectedLocation={selectedLocation}
                            setLoading={setLoading}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}