import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { exceptionResponse } from '../../../../models/types';
import '../../styles/exception-list.scss';
import { OutDatedIcon } from '../../../../components/OutDated/out-dated';


export interface ExceptionListProps {
    list: exceptionResponse[],
    isOlderDate: boolean
}

export const ExceptionList = (props: ExceptionListProps): JSX.Element => {
    let response: any = [];

    if (!props || !props?.list) {
        return <></>;
    }

    const getChildKey = (x: exceptionResponse): string => {
        return x.captureTime + x.cameraId + x.message.length;
    }

    if (props.list.length > 0) {
        props.list.forEach((x: exceptionResponse) => {
            response.push(
                <React.Fragment>
                    <div key={getChildKey(x)} className='col-12 lg:col-12 xl:col-12'>
                        <div className='card'>
                            <div className='exception-card'>
                                <div className='grid'>
                                    <div className='col-12'>
                                        {getIconValue(x)}
                                        <strong className='exception-title'>{x.title}</strong> | <span className='exception-title'>{x.captureTime}</span>
                                        <hr />
                                    </div>
                                    <div className='col-12 exception-body-div'>
                                        <span className='exception-body'>{x.message}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        });
    }

    const renderSkeletonExceptionList = () => {
        return (<>
            <div className='col-12 lg:col-12 xl:col-12'>
                <div className='card'>
                    <div className='exception-card'>
                        <Skeleton width='95%' height='20px' shape='rectangle' />
                        <hr />
                        <Skeleton width='95%' height='40px' shape='rectangle' />
                    </div>
                </div >
            </div >
        </>)
    }

    const renderOutDatedMessage = () => {
        return (
            <>
                <div className='col-12 lg:col-12 xl:col-12'>
                    <div className='card'>
                        <div className='exception-card'>
                            <div className='grid'>
                                <div className='col-12'>
                                    {OutDatedIcon(props.isOlderDate)}
                                    <strong className='exception-title ml-2'>Mensagens interrompidas</strong>
                                    <hr />
                                </div>
                                <div className='col-12'>
                                    <Skeleton width='90%' height='40px' shape='rectangle' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderExceptionList = () => {
        return response;
    }
    return (
        <div className="col-12 lg:col-12 xl:col-3">
            <div className='grid' style={{ position: 'relative' }}>
                <div className='grid col-12 lg:col-12 xl:col-12' style={{ position: 'absolute' }}>
                    {props.isOlderDate ? renderOutDatedMessage() : (props.list.length > 0 ? renderExceptionList() : renderSkeletonExceptionList())}
                </div>
            </div>
        </div>
    )
}

const getIconValue = (response: exceptionResponse) => {
    return <i className={"pi pi-" + (response.isError ? "times" : "info") + "-circle mr-2 mb-1"} />;
}
