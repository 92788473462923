import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import blackLogo from './../../../../wwroot/img/moon-black-logo.png';
import whiteLogo from './../../../../wwroot/img/moon-white-logo.png';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ToastServices } from '../../../../services/utils/toast-services';
import { UserServices } from '../../../../services/user-services';
import { ClipLoader } from 'react-spinners';

export interface SendPasswordRequestDialogProps {
    visible: boolean
    onCloseDialog: () => void,
}

const getImageSource = () => {
    var userTheme = localStorage.getItem("user-theme") || 'claro';

    return userTheme === 'claro' ? blackLogo : whiteLogo;
}

export const SendPasswordRequestDialog = (props: SendPasswordRequestDialogProps) => {
    const _toastService = new ToastServices();
    const _userServices = new UserServices();

    const [email, setEmail] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const updateInput = (e: any) => {
        setEmail(e.target.value);
    }

    const onSendRequest = () => {
        if (email !== null && email !== undefined) {
            setLoading(true);
            _userServices.sendPasswordRequest(email)
                .then(data => {
                    setLoading(false);
                    _toastService.showSuccesMessage("Email enviado com sucesso");
                })
                .catch(error => { _toastService.showErrorRequestMessage(error); setLoading(false); })
        } else {
            _toastService.showErrorMessage("Preencha um email válido");
        }
    }

    return (
        <Dialog visible={props.visible} style={{ width: '500px' }} onHide={props.onCloseDialog}>
            <div className='flex justify-content-center'>
                <img src={getImageSource()} alt='olho-vivo-logo' />
            </div>
            <div className='flex justify-content-center mt-2'>
                <strong>Enviar email de recuperação de senha</strong>
            </div>
            <div className='grid'>
                <div className='col-12'>
                    <div className="mt-4 p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-user"></i>
                        </span>
                        <InputText
                            id="ddModelo"
                            required
                            min={6}
                            type='email'
                            value={email}
                            onChange={(e) => updateInput(e)}
                            placeholder="digite seu email"
                        />
                    </div>
                </div>
                <div className='col-12 flex'>
                    <Button
                        className=''
                        onClick={(e) => onSendRequest()}
                        label='Enviar recuperação de senha'
                    />
                    <div className='clip-loader-container ml-2'>
                        <ClipLoader color='var(--text-color)' loading={loading} size={25} />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}