import { Dialog } from 'primereact/dialog';
import React from 'react';
import { PointABAlertResponse } from '../../../../models/types';
import '../../styles/point-AB-alert-dialog.scss';

export interface PointABAlertDialogProps {
    pointABAlertDialog: boolean,
    pointABAlert: PointABAlertResponse,
    setPointABAlertDialog: (state: boolean) => void
}

export const PointABAlertDialog = (props: PointABAlertDialogProps) => {
    return (
        <Dialog visible={props.pointABAlertDialog} className='point-ab-dialog' resizable={false} header='ALERTA DE DESLOCAMENTO ESPECIAL DETECTADO' onHide={() => props.setPointABAlertDialog(false)}>
            <div className='card'>
                <div className='point-ab-alert-plate-div mb-4'>
                    <i className="pi pi-tag" />
                    <strong className='ml-2 point-ab-alert-plate'>{props.pointABAlert.plate}</strong>
                </div>
                <div className='flex'>
                    <div className='grid'>
                        <div className='col-12'>
                            <img className='point-ab-image' src={props.pointABAlert.imageUrlPointA} alt={props.pointABAlert.plate}></img>
                        </div>
                        <div className='col-12'>
                            <div className='grid'>
                                <div className='col-12 title-message'>
                                    {props.pointABAlert.cameraNamePointA}
                                </div>
                                <div className='col-12 mb-2'>
                                    <i className="pi pi-map" />
                                    <span className='ml-2 point-ab-alert-text'>{props.pointABAlert.cameraAddressPointA}</span>
                                </div>
                                <div className='col-12'>
                                    <i className="pi pi-clock" />
                                    <span className='ml-2 point-ab-alert-text'>{props.pointABAlert.dateTimePointA}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid'>
                        <div className='col-12'>
                            <img className='point-ab-image' src={props.pointABAlert.imageUrlPointB} alt={props.pointABAlert.plate}></img>
                        </div>
                        <div className='col-12'>
                            <div className='grid'>
                                <div className='col-12 title-message'>
                                    {props.pointABAlert.cameraNamePointB}
                                </div>
                                <div className='col-12 mb-2'>
                                    <i className="pi pi-map" />
                                    <span className='ml-2 point-ab-alert-text'>{props.pointABAlert.cameraAddressPointB}</span>
                                </div>
                                <div className='col-12'>
                                    <i className="pi pi-clock" />
                                    <span className='ml-2 point-ab-alert-text'>{props.pointABAlert.dateTimePointB}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
