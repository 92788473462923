import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import React from 'react';
import { camera } from '../../types/types';

export interface CamerasDataTableProps {
    cameras: camera[],
    selectedCameras: camera[],
    globalFilter: string,
    setGlobalFilter: (val: any) => void,
    setSelectedCameras: (e: any) => void,
    loading: boolean,
    editCamera: (camera: camera) => void,
    confirmDeleteCamera: (camera: camera) => void,
    confirmDeleteSelected: () => void,
    openNew: () => void
}

export const CamerasDataTable = (props: CamerasDataTableProps) => {
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={props.openNew} />
                    <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" onClick={props.confirmDeleteSelected} disabled={!props.selectedCameras || !props.selectedCameras.length} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                <React.Fragment>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={props.globalFilter}
                            onInput={(e: any) => props.setGlobalFilter(e.target.value)}
                            placeholder="Procurar..."
                        />
                    </span>
                </React.Fragment>
            </>
        )
    }

    const cameraBrandBodyTemplate = (rowData: any) => {
        return (
            <>
                <span>
                    {rowData.nomCameraModelo}
                </span>
            </>
        );
    }

    const nameBodyTemplate = (rowData: any) => {
        return (
            <>
                {rowData.dscCamera}
            </>
        );
    }

    const ativoBodyTemplate = (rowData: any) => {
        if (rowData.indAtivo === true) {
            return <Tag severity="success" value="Ativa" icon="pi pi-check" />;
        }

        return <Tag severity="danger" value="Inativa" icon="pi pi-times" />;
    }

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button mr-2"
                    onClick={() => props.editCamera(rowData)}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => props.confirmDeleteCamera(rowData)}
                />
            </div>
        );
    }

    const heliosTokenBodyTemplate = (rowData: any) => {
        return (
            <>
                {rowData.dscHeliosToken}
            </>
        );
    }

    const cortexTokenBodyTemplate = (rowData: any) => {
        return (
            <>
                {rowData.dscCortexToken}
            </>
        );
    }

    const alertaTokenBodyTemplate = (rowData: any) => {
        return (
            <>
                {rowData.dscAlertaToken}
            </>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-2">Câmeras</h5>
        </div >
    )

    return (
        <React.Fragment>
            <Toolbar
                className="p-mb-4"
                left={leftToolbarTemplate}
                right={rightToolbarTemplate}
            />
            <DataTable
                columnResizeMode='fit'
                value={props.cameras}
                selection={props.selectedCameras}
                onSelectionChange={(e: any) => props.setSelectedCameras(e.value)}
                dataKey="id"
                paginator
                selectionMode='multiple'
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} cameras"
                globalFilter={props.globalFilter}
                emptyMessage="Nenhuma camera encontrada."
                header={header}
                loading={props.loading}>
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column field="dscCamera" header="DESCRIÇÃO" sortable body={nameBodyTemplate}></Column>
                <Column field="idCamera" header="DESCRIÇÃO" hidden sortable></Column>
                <Column field="nomCameraModelo" header="MODELO" sortable body={cameraBrandBodyTemplate} ></Column>
                <Column field="dscHeliosToken" header="TOKEN HELIOS" sortable body={heliosTokenBodyTemplate}></Column>
                <Column field="dscCortexToken" header="TOKEN CORTEX" sortable body={cortexTokenBodyTemplate}></Column>
                <Column field="dscAlertaToken" header="TOKEN ALERTA" sortable body={alertaTokenBodyTemplate}></Column>
                <Column field="indAtivo" header="ATIVO" sortable body={ativoBodyTemplate}></Column>
                <Column body={actionBodyTemplate}></Column>
            </DataTable>
        </React.Fragment>
    )
}
