import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import { camera } from '../../types/types';

export interface DeleteCameraDialogProps {
    deleteCameraDialog: boolean,
    camera: camera,
    hideDeleteCameraDialog: () => void,
    deleteCamera: () => void

}
export const DeleteCameraDialog = (props: DeleteCameraDialogProps) => {
    const deleteCameraDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={props.hideDeleteCameraDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={props.deleteCamera} />
        </>
    )


    return (
        <Dialog visible={props.deleteCameraDialog} style={{ width: '700px' }} header="Confirm" modal footer={deleteCameraDialogFooter} onHide={props.hideDeleteCameraDialog}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle mr-2" style={{ fontSize: '2rem' }} />
                {props.camera && <span>Tem certeza que deseja deletar a camera <b>{props.camera.dscCamera}</b>?</span>}
            </div>
        </Dialog>
    )
}