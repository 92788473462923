export enum vehicleBrand {
    DUMMY,
    BUICK,
    CADILLAC,
    CHEVROLET,
    GMC,
    HOLDEN,
    AUDI,
    BENTLEY,
    BUGATTI,
    LAMBORGHINI,
    MAN,
    PORSCHE,
    SCANIA,
    SEAT,
    ŠKODA,
    VOLKSWAGEN,
    JAC,
    LEXUS,
    SCION,
    TOYOTA,
    HYUNDAI,
    KIA,
    FORD,
    LINCOLN,
    RENAULT,
    INFINITI,
    NISSAN,
    NISMO,
    MITSUBISHI,
    ABARTH,
    ALFAROMEO,
    CHRYSLER,
    FERRARI,
    DODGE,
    SUBARU,
    VOLVO,
    LANDROVER,
    JAGUAR,
    MAZDA,
    MERCEDESBENZ,
    BMW,
    MINI,
    ROLLSROYCE,
    HONDA,
    PEUGEOT,
    MASERATI,
    JEEP,
    FIAT
}
