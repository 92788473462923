import { Dialog } from 'primereact/dialog';
import React from 'react';
import { whatsappGroup } from '../../types/types';
import { Button } from 'primereact/button';

export interface DeleteGroupDialogProps {
    visible: boolean,
    group: whatsappGroup,
    deleteGroup: () => void,
    closeDialog: () => void,
}

export const DeleteGroupDialog = (props: DeleteGroupDialogProps) => {
    const dialogHeader = `EXCLUÍR GRUPO ${props.group.groupName}`;

    const deleteGroupDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={props.closeDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={props.deleteGroup} />
        </>
    )

    return (
        <Dialog visible={props.visible} modal header={dialogHeader} footer={deleteGroupDialogFooter} onHide={props.closeDialog}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle mr-2" style={{ fontSize: '2rem' }} />
                {props.group && <span>Tem certeza que deseja deletar o grupo <b>{props.group.groupName}</b>?</span>}
            </div>
        </Dialog>
    )
}
