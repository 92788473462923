import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { AuthServices } from "../../../services/utils/auth-services";
import { ToastServices } from "../../../services/utils/toast-services";
import { UserServices } from "../../../services/user-services";
import { LoginServices } from "../../../services/login-services";
import { emptyUser, user } from "../../Login/types/types"
import { EditUserDialog } from "./dialogs/edit-user-dialog";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";

export const Profile = () => {
    const history = useHistory();
    const _toastServices = new ToastServices();
    const _userServices = new UserServices();
    const _loginServices = new LoginServices();
    const _authServices = new AuthServices();

    const [user, setUser] = useState<user>(emptyUser);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userDialog, setUserDialog] = useState(false);
    const [dialogHeader, setDialogHeader] = useState<string>(null);

    const [isEditingUser, setIsEditingUser] = useState<boolean>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);

    _authServices.HandleAppAuth();

    useEffect(() => {
        const user = _loginServices.getUserProfile
        setLoading(false);
        setUser(user);
        setDialogHeader("Editar Usuário");
        setIsEditingUser(true);
        setUserDialog(true);
        setSubmitted(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hideDialog = () => {
        setUserDialog(false);
        setLoading(false);
        setUser(emptyUser);
        setSubmitted(false);
        history.goBack()
    }

    const handleSubmit = () => {
        setSubmitted(true);

        // Verifica se as senhas coincidem
        if (user.newpassword !== "" && user.newpassword !== null && user.newpassword !== user.repetpassword) {
            setPasswordError("As senhas não coincidem."); // Define a mensagem de erro
            return;
        }

        // Verifica se a nova senha é diferente da senha antiga
        if (user.newpassword !== "" && user.newpassword !== null && user.newpassword === user.password) {
            setPasswordError("A nova senha não pode ser igual à senha antiga."); // Define a mensagem de erro
            return;
        }

        setPasswordError(null); // Reseta o erro caso as verificações passem

        // Continua com o envio do formulário se tudo estiver ok
        onSubmit();
    };

    const onSubmit = () => {
        setLoading(true);
        setSubmitted(true);

        if (isUserValid()) {

            if (user.id) {
                _userServices.updateProfileUser(user).then(
                    () => {
                        _toastServices.showSuccesMessage("Usuário atualizado com sucesso.");
                    },
                    (error) => _toastServices.showErrorRequestMessage(error));
            }
            setUserDialog(false);
        }
        setLoading(false);
        history.goBack()
    }

    const isUserValid = () => {
        return user.userName && user.email
    }

    return (
        <div className="grid">
            <div className="col-12">
                <EditUserDialog
                    user={user}
                    setUser={setUser}
                    setUserDialog={setUserDialog}
                    onSubmit={handleSubmit}
                    setSubmitted={setSubmitted}
                    isEditingUser={isEditingUser}
                    visible={userDialog}
                    hideDialog={hideDialog}
                    submitted={submitted}
                    dialogHeader={dialogHeader}
                    passwordError={passwordError}
                />
            </div>
            <div className='clip-loader-container'>
                <ClipLoader color='var(--text-color)' loading={loading} size={25} />
            </div>
        </div>
    )
};
