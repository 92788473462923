import React from 'react';
import './page-header.scss';

export interface PageHeaderProps {
    title: string
    icon: string
}

export const PageHeader = (props: PageHeaderProps) => {
    return (
        <strong>
            <h2 className='page-header-title'>
                <i className={props.icon + ' mr-2'} style={{ fontSize: '2.5rem' }} />
                {props.title}
            </h2>
        </strong>
    );
}