import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { ToastServices } from "../../../../services/utils/toast-services";
import { DestinationABService } from "../../../../services/destination-ab-service";
import { destinationABInterface } from "../../types/types";
import "./../../styles/destination-ab.scss";

export interface DeleteDestinationsABDialogProps {
    destinationAB: destinationABInterface;
    setLoading: (isLoading: boolean) => void;
    getDestinationsAB: () => void;
    setDeleteDestinationsABDialog: (showDestionationABDialog: boolean) => void;
    visible: boolean;
    setSelectedDestinationsAB: (selectedDestinationsAB: any) => void;
    selectedDestinationsAB: any;
}

export const DeleteDestinationsABDialog = (props: DeleteDestinationsABDialogProps) => {
    const destinationABervice = new DestinationABService();
    const _toastService = new ToastServices();
    const hideDeleteDestionationsABDialog = () => {
        props.setDeleteDestinationsABDialog(false);
    };

    const deleteSelectedDestinationsAB = () => {
        props.setLoading(true);
        props.selectedDestinationsAB.forEach((destinationAB: destinationABInterface) => {
            destinationABervice.deleteDestinationAB(destinationAB.id).then(
                () => {
                    props.getDestinationsAB();
                    _toastService.showSuccesMessage(`Ponto A & B Excluído com Sucesso.`);
                },
                (error: any) => {
                    props.getDestinationsAB();
                    _toastService.showErrorRequestMessage(error);
                    props.setLoading(false);
                }
            );
        });
        props.setSelectedDestinationsAB(null);

        props.setDeleteDestinationsABDialog(false);
    };

    const deleteDestionationsABDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDestionationsABDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedDestinationsAB} />
        </>
    );

    return (
        <Dialog visible={props.visible} style={{ width: "450px" }} header="Confirm" modal footer={deleteDestionationsABDialogFooter} onHide={hideDeleteDestionationsABDialog}>
            <ToastContainer />
            <Toast ref={toast} />
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                {props.destinationAB && <span className="ml-2">Você têm certeza que deseja excluir os pontos A & B selecionados?</span>}
            </div>
        </Dialog>
    );
};
