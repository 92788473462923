import { Dialog } from "primereact/dialog";
import React from "react";
import { BehavioralConvoyAlertResponse } from "../../../../models/types";
import "../../styles/behavioral-convoy-dialog.scss";

export interface BehavioralConvoyDialogProps {
    behavioralConvoyAlertDialog: boolean;
    behavioralConvoyAlert: BehavioralConvoyAlertResponse;
    setBehavioralConvoyAlertDialog: (state: boolean) => void;
}

export const BehavioralConvoyAlertDialog = (props: BehavioralConvoyDialogProps) => {
    return (
        <Dialog visible={props.behavioralConvoyAlertDialog} className="point-ab-dialog" resizable={false} header="ALERTA DE MODO COMBOIO COMPORTAMENTAL DETECTADO" onHide={() => props.setBehavioralConvoyAlertDialog(false)}>
            <div className="card">
                <div className="col-12 behavioral-convoy-plate">
                    <i className="pi pi-chart-bar mt-3 icon" />
                    <span className="ml-2 behavioral-convoy-plate">{props.behavioralConvoyAlert.firstVehicleType.type + "-" + props.behavioralConvoyAlert.secondVehicleType.type}</span>
                </div>
                <div className="col-12 behavioral-convoy-plate">
                    <i className="pi pi-camera mt-3 icon" />
                    <span className="ml-2">{props.behavioralConvoyAlert.cameraName}</span>
                </div>
                <div className="col-12 behavioral-convoy-plate">
                    <i className="pi pi-map mt-3 icon" />
                    <span className="ml-2">{props.behavioralConvoyAlert.cameraAddress}</span>
                </div>
                <div className="flex col-12">
                    <div className="grid col-6">
                        <div className="behavioral-convoy-plate col-12 flex justify-content-start behavioral-convoy-plate-div">
                            <i className="pi pi-tag" />
                            <strong className="margin-auto ml-2">{props.behavioralConvoyAlert.firstVehicleType.vehiclePlate}</strong>
                        </div>
                        <div className="col-6">
                            <img className="behavioral-convoy-image" src={props.behavioralConvoyAlert.firstVehicleType.imageUrl} alt={props.behavioralConvoyAlert.firstVehicleType.vehiclePlate}></img>
                        </div>
                        <div className="col-12">
                            <div className="grid">
                                <div className="col-12">
                                    <i className="pi pi-clock" />
                                    <span className="ml-2 behavioral-convoy-text">{props.behavioralConvoyAlert.firstVehicleType.date}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid col-6">
                        <div className="behavioral-convoy-plate col-12 flex justify-content-start behavioral-convoy-plate-div">
                            <i className="pi pi-tag" />
                            <strong className="margin-auto ml-2">{props.behavioralConvoyAlert.secondVehicleType.vehiclePlate}</strong>
                        </div>
                        <div className="col-6">
                            <img className="behavioral-convoy-image flex justify-content-start" src={props.behavioralConvoyAlert.secondVehicleType.imageUrl} alt={props.behavioralConvoyAlert.secondVehicleType.vehiclePlate}></img>
                        </div>
                        <div className="col-12">
                            <div className="grid">
                                <div className="col-12">
                                    <i className="pi pi-clock" />
                                    <span className="ml-2 behavioral-convoy-text">{props.behavioralConvoyAlert.secondVehicleType.date}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
