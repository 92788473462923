import axios from "axios";
import classNames from "classnames";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import React, { useEffect, useState } from "react";
import { environment } from "../../../../configuration/environment";
import { DropdownInfo } from "../../../../models/types";
import { onCheckedChange, onInputChange, onNumberChange } from "../../../../services/utils/input-services";
import { ToastServices } from "../../../../services/utils/toast-services";
import { CameraServices, getImageModeList, getUFList } from "../../../../services/camera-services";
import { camera, cameraIntegration, emptyIntegration } from "../../types/types";
import { IntegrationsDataTable } from "../data-tables/integrations-data-table";
import './../../styles/camera.scss';
import { CrateIntegrationDialog } from "./create-integration-dialog";
import { DeleteIntegrationDialog } from "./delete-integration-dialog";

export interface CreateCameraDialogProps {
    camera: camera,
    setCamera: (camera: camera) => void,
    visible: boolean,
    hideDialog: () => void,
    saveCamera: () => void,
    submitted: boolean
}

export const CreateCameraDialog = (props: CreateCameraDialogProps) => {
    const header = "Camera #" + props.camera.id;

    const _cameraService = new CameraServices();
    const _toastService = new ToastServices();

    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [cameraBrands, setCameraBrands] = useState([]);
    const [isEditingIntegration, setIsEditingIntegration] = useState<boolean>(false);
    const [integrationsDialog, setIntegrationsDialog] = useState<boolean>(false);
    const [integration, setIntegration] = useState<cameraIntegration>(emptyIntegration);
    const [newIntegrationActiveValue, setNewIntegrationActiveValue] = useState(null);
    const [deleteIntegrationDialog, setDeleteIntegrationDialog] = useState<boolean>(false);

    useEffect(() => {
        getCamerasBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.camera])

    useEffect(() => {
        if (props.camera.nomUF) {
            getLocationsByUf(props.camera.nomUF);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.camera.nomUF])

    const getLocationsByUf = (uf: string) => {
        axios
            .get(environment().baseUrl + environment().version + "/location/by-uf/" + uf, {
                headers: { Authorization: sessionStorage.getItem("Bearer") },
            })
            .then(
                (response) => setLocations(response.data),
                (error) => _toastService.showErrorRequestMessage(error)
            );
    }

    const alreadyHasIntegration = (): boolean => {
        if (props.camera.integrations && props.camera.integrations.find(x => x.idIntegracao === integration.idIntegracao)) {
            return true;
        }
        return false;
    }

    const onIntegrationCheckChange = (e: any, name: boolean) => {
        e.rowData[e.field] = name;
        setNewIntegrationActiveValue(name);
    }

    const atualizaCidades = (e: any) => {
        onInputChange(e, "nomUF", props.camera, props.setCamera);
        getLocationsByUf(e.target.value);
    }

    const checkBoxEditor = (options: any) => {
        let { rowData, newValue, field, originalEvent: event } = options;

        setNewIntegrationActiveValue(rowData[field]);

        return <Checkbox
            checked={newIntegrationActiveValue}
            onChange={(e) => onIntegrationCheckChange(options, e.target.checked)}
        />
    }

    const getCamerasBrands = () => {
        let response = _cameraService.getCameraBrands();
        setCameraBrands(response);
    }

    const onCloseDialog = () => {
        setIntegrationsDialog(false);
    }

    const onOpenIntegrationDialog = () => {
        setIntegration(emptyIntegration);
        setIsEditingIntegration(false);
        setIntegrationsDialog(true);
    }

    const editIntegration = (integration: cameraIntegration) => {
        setIsEditingIntegration(true);
        setIntegration({ ...integration });
        setIntegrationsDialog(true);
    }

    const confirmDeleteIntegration = (integration: cameraIntegration) => {
        setIntegration(integration);
        setDeleteIntegrationDialog(true);
    }

    const onCloseDeleteIntegrationDialog = () => {
        setDeleteIntegrationDialog(false);
    }

    const onDeleteCameraIntegration = () => {
        let _integrations = props.camera.integrations
            .filter(val => val.chaveIntegracao !== integration.chaveIntegracao || val.idIntegracao !== integration.idIntegracao)

        if (integration.idCameraIntegracao) {
            _cameraService.deleteCameraIntegration(props.camera, integration)
                .then((response) => { }, (error) => _toastService.showErrorRequestMessage(error));
        }

        let _camera = { ...props.camera } as any;
        _camera.integrations = _integrations;

        props.setCamera(_camera);
        setDeleteIntegrationDialog(false);
        _toastService.showSuccesMessage('Integração excluída');
    }

    const addNewIntegration = (newIntergation: cameraIntegration, isEditing: boolean) => {
        setIntegration(newIntergation);

        let _integrations: cameraIntegration[] = props.camera.integrations
            .filter(x => !isEditing || (x.idCameraIntegracao !== integration.idCameraIntegracao));

        _integrations.push(newIntergation);

        let _camera = { ...props.camera } as any;
        _camera.integrations = _integrations;
        props.setCamera(_camera);
    }

    const cameraDialogFooter = (
        <>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={props.hideDialog}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => props.saveCamera()}
            />
        </>
    )

    return (
        <>
            <Dialog visible={props.visible} footer={cameraDialogFooter} style={{ width: "50%" }} header={header} modal className="p-fluid" onHide={props.hideDialog}>
                <span className="titulo-categoria">IDENTIFICAÇÃO:</span>
                <div className="categoria">
                    <div className="grid">
                        <div className="col-6">
                            <strong>Fabricante:</strong>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-video"></i>
                                </span>
                                <Dropdown
                                    id="ddModelo"
                                    value={props.camera.idCameraModelo}
                                    required
                                    onChange={(e) => onInputChange(e, "idCameraModelo", props.camera, props.setCamera)}
                                    options={cameraBrands}
                                    optionLabel="name"
                                    optionValue="idIntegration"
                                    placeholder="Modelo"
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <strong>Nome:</strong>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-tag"></i>
                                </span>
                                <InputText
                                    id="name"
                                    value={props.camera.dscCamera}
                                    onChange={(e) => onInputChange(e, "dscCamera", props.camera, props.setCamera)}
                                    required
                                    autoFocus
                                    className={classNames({ "p-invalid": props.submitted && !props.camera.dscCamera })}
                                />
                            </div>
                            {props.submitted && !props.camera.dscCamera && <small className="p-invalid">Insira um nome.</small>}
                        </div>
                        <div className="col-6">
                            <Checkbox
                                className="mr-2"
                                inputId="checkOption3"
                                name="option"
                                checked={props.camera.indAtivo}
                                onChange={(e) => onCheckedChange(e, "indAtivo", props.camera, props.setCamera)}>
                            </Checkbox>
                            <strong className="titulo-categoria">Ativa</strong>
                        </div>
                        <div className="col-6">
                            <Checkbox
                                className="mr-2"
                                inputId="checkOption6"
                                name="option"
                                checked={props.camera.indSignalR}
                                onChange={(e) => onCheckedChange(e, "indSignalR", props.camera, props.setCamera)}>
                            </Checkbox>
                            <strong className="titulo-categoria">Signal-R</strong>
                        </div>
                    </div>
                </div>
                {props.camera.idCameraModelo === 3 &&
                    <>
                        <span className="titulo-categoria">CONFIGURAÇÃO:</span>
                        <div className="categoria">
                            <div className="grid">
                                <div className="col-3">
                                    <strong>Velocidade Máxima (km/h):</strong>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-car"></i>
                                        </span>
                                        <InputNumber
                                            useGrouping={false}
                                            value={props.camera.velocidadeMaxima}
                                            onChange={(e) => onNumberChange(e, "velocidadeMaxima", props.camera, props.setCamera)}
                                            required
                                            max={160}
                                            className={classNames({ "p-invalid": props.submitted && !props.camera.velocidadeMaxima })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                <span className="titulo-categoria">LOCALIZAÇÃO:</span>
                <div className="categoria">
                    <div className="grid">
                        <div className="col-6">
                            <strong>Estado:</strong>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-map"></i>
                                </span>
                                <Dropdown
                                    id="ddEstado"
                                    value={props.camera.nomUF}
                                    filter
                                    onChange={(e) => atualizaCidades(e)}
                                    options={getUFList}
                                    optionLabel="value"
                                    optionValue="value"
                                    placeholder="Estado"
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <strong>Cidade:</strong>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-map-marker"></i>
                                </span>
                                <Dropdown
                                    options={locations}
                                    filter
                                    showClear
                                    optionValue="id"
                                    optionLabel="description"
                                    disabled={locations.length <= 0}
                                    showFilterClear
                                    value={props.camera.idLocalidade}
                                    onChange={(e) => onInputChange(e, "idLocalidade", props.camera, props.setCamera)}
                                    placeholder="Cidade"
                                    className={classNames({ "p-invalid": props.submitted && !props.camera.idLocalidade })}
                                />
                            </div>
                            {props.submitted && !props.camera.idLocalidade && <small className="p-invalid">Insira uma cidade.</small>}
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-3">
                            <strong>Latitude:</strong>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-info"></i>
                                </span>
                                <InputMask
                                    mask="99.9999"
                                    value={props.camera.numLatitude}
                                    onChange={(e) => onInputChange(e, "numLatitude", props.camera, props.setCamera)}
                                    required
                                    className={classNames({ "p-invalid": props.submitted && !props.camera.numLatitude })}
                                />
                            </div>
                            {props.submitted && !props.camera.numLatitude && <small className="p-invalid">Insira uma Latitude.</small>}
                        </div>
                        <div className="col-3">
                            <strong>Longitude:</strong>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-info"></i>
                                </span>
                                <InputMask
                                    mask="99.9999"
                                    value={props.camera.numLongitude}
                                    onChange={(e) => onInputChange(e, "numLongitude", props.camera, props.setCamera)}
                                    required
                                    className={classNames({ "p-invalid": props.submitted && !props.camera.numLongitude })}
                                />
                            </div>
                            {props.submitted && !props.camera.numLongitude && <small className="p-invalid">Insira uma Longitude.</small>}
                        </div>
                        <div className="col-6">
                            <strong>Endereço:</strong>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-info"></i>
                                </span>
                                <InputText
                                    id="endereco"
                                    value={props.camera.dscEndereco}
                                    onChange={(e) => onInputChange(e, "dscEndereco", props.camera, props.setCamera)}
                                    required
                                    autoFocus
                                    className={classNames({ "p-invalid": props.submitted && !props.camera.dscEndereco })}
                                />
                            </div>
                            {props.submitted && !props.camera.dscEndereco && <small className="p-invalid">Insira um endereço.</small>}
                        </div>
                    </div>
                </div>
                {props.camera.idCameraModelo != 2 && (
                    <>
                        <span className="titulo-categoria">CONEXÃO HTTP:</span>
                        <div className="categoria">
                            <div className="grid">
                                <div className="col-6">
                                    <strong>URL ou IP:</strong>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-globe"></i>
                                        </span>
                                        <InputText
                                            value={props.camera.dscUrl}
                                            onChange={(e) => onInputChange(e, "dscUrl", props.camera, props.setCamera)}
                                            id="name1"
                                            type="text"
                                            required
                                            autoFocus
                                            className={classNames({ "p-invalid": props.submitted && !props.camera.dscUrl })}
                                        />
                                    </div>
                                    {props.camera.idCameraModelo === 1 && props.submitted && !props.camera.dscUrl && <small className="p-invalid">Insira uma url.</small>}
                                </div>
                                <div className="col-3">
                                    <strong>Porta:</strong>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-sign-in"></i>
                                        </span>
                                        <InputNumber
                                            useGrouping={false}
                                            value={props.camera.numPorta}
                                            max={99999}
                                            onChange={(e) => onNumberChange(e, "numPorta", props.camera, props.setCamera)}
                                            required
                                            autoFocus
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="field col-3">
                                    <strong>Usuário:</strong>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-user"></i>
                                        </span>
                                        <InputText
                                            required
                                            autoFocus
                                            value={props.camera.dscUsuario}
                                            onChange={(e) => onInputChange(e, "dscUsuario", props.camera, props.setCamera)}
                                            className={classNames({ "p-invalid": props.submitted && !props.camera.dscUsuario })}
                                        />
                                    </div>
                                    {props.camera.idCameraModelo === 1 && props.submitted && !props.camera.dscUsuario && <small className="p-invalid">Insira um usuário.</small>}
                                </div>
                                <div className="field col-3">
                                    <strong>Senha:</strong>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-lock"></i>
                                        </span>
                                        <Password
                                            value={props.camera.dscSenha}
                                            onChange={(e) => onInputChange(e, "dscSenha", props.camera, props.setCamera)}
                                            required
                                            autoFocus
                                            feedback={false}
                                            toggleMask
                                            className={classNames({ "p-invalid": props.submitted && !props.camera.dscSenha })}
                                        />
                                    </div>
                                    {props.camera.idCameraModelo === 1 && props.submitted && !props.camera.dscSenha && <small className="p-invalid">Insira uma senha.</small>}
                                </div>
                                <div className="field col-3">
                                    <strong>Modo de Imagem:</strong>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-image"></i>
                                        </span>
                                        <Dropdown
                                            value={props.camera.numImageMode}
                                            optionLabel="name"
                                            optionValue="value"
                                            onChange={(e) => onNumberChange(e, "numImageMode", props.camera, props.setCamera)}
                                            options={getImageModeList}
                                            required
                                            autoFocus
                                        />
                                    </div>
                                    {props.camera.idCameraModelo === 1 && props.submitted && !props.camera.dscSenha && <small className="p-invalid">Insira uma senha.</small>}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <span className="titulo-categoria">INTEGRAÇÕES:</span>
                <div className="categoria">
                    <IntegrationsDataTable
                        checkBoxEditor={checkBoxEditor}
                        confirmDeleteIntegration={confirmDeleteIntegration}
                        editIntegration={editIntegration}
                        integrations={props.camera.integrations}
                        onOpenIntegrationDialog={onOpenIntegrationDialog}
                    />
                </div>
            </Dialog>

            <CrateIntegrationDialog
                isEditing={isEditingIntegration}
                setCameraIntegration={addNewIntegration}
                setIntegration={setIntegration}
                integration={integration}
                alreadyHasIntegration={alreadyHasIntegration}
                visible={integrationsDialog}
                onCloseDialog={onCloseDialog}
            />
            <DeleteIntegrationDialog
                visible={deleteIntegrationDialog}
                hideDialog={onCloseDeleteIntegrationDialog}
                integration={integration}
                onDeleteCameraIntegration={onDeleteCameraIntegration}
            />
        </>
    )
}
