import axios from 'axios';
import { environment } from "../configuration/environment";
import { DssModel, TimelineEvent } from "../pages/Dss/models/types";

import { AuthServices } from "./utils/auth-services";

export class DssServices {
    _authServices = new AuthServices();

    getLocationDss(locationId: number) {
        return axios.get(environment().baseUrl + environment().version + "/dss/dss-by-location/" + locationId, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        })
    }

    saveLocationDss(dssModel: DssModel) {
        return axios.post(environment().baseUrl + environment().version + "/dss", dssModel, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        })
    }

    deleteLocationDss(locationId: number) {
        return axios.delete(environment().baseUrl + environment().version + "/dss/" + locationId, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        })
    }

    validateDssModel(dssModel: DssModel): boolean {
        return dssModel.url !== '' && dssModel.user !== '' && dssModel.password !== '' && dssModel.locationId !== 0 && dssModel.password !== null && dssModel.password !== undefined;
    }

    getTimeLineEvents(): TimelineEvent[] {
        return [
            { status: 'DSS Cadastrado', icon: 'pi pi-server' },
            { status: 'Usuário e Senha validados', icon: 'pi pi-verified' },
            { status: 'Eventos Sincronizados', icon: 'pi pi-sitemap' },
            { status: 'Cameras Sincronizadas', icon: 'pi pi-camera' },
            { status: 'Pronto', icon: 'pi pi-check' },

        ]
    }

    syncDssClientEvents(locationId: number) {
        return axios.get(environment().baseUrl + environment().version + "/dss/sync-events-to-dss/" + locationId, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        })
    }

    syncDssClientCameras(locationId: number) {
        return axios.get(environment().baseUrl + environment().version + "/dss/sync-cameras-to-dss/" + locationId, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        })
    }
}