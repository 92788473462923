import React from 'react';
import { whatsappRecipient } from '../../types/types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';

export interface WhatsappDataTableProps {
    recipents: whatsappRecipient[],
    selectedRecipents: whatsappRecipient[],
    setSelectedRecipients: (e: any) => void,
    editRecipient: (recipient: whatsappRecipient) => void,
    deleteRecipient: (recipient: whatsappRecipient) => void,
    loading: boolean,
    globalFilter: string
}


export const WhatsappDataTable = (props: WhatsappDataTableProps) => {

    const recipentsNameTemplate = (rowData: whatsappRecipient) => {
        return <span>{rowData.recipientName}</span>
    }

    const recipentsCodeTemplate = (rowData: whatsappRecipient) => {
        return <span>{rowData.recipientCode}</span>
    }

    const createStampTemplate = (rowData: whatsappRecipient) => {
        return <span>{rowData.createStamp}</span>
    }

    const activeTemplate = (rowData: whatsappRecipient) => {
        if (rowData.active === true) {
            return <Tag severity="success" value="Ativo" icon="pi pi-check" />;
        }

        return <Tag severity="danger" value="Inativo" icon="pi pi-times" />;
    }

    const actionsTemplate = (rowData: whatsappRecipient) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button mr-2"
                    onClick={() => props.editRecipient(rowData)}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => props.deleteRecipient(rowData)}
                />
            </div>
        );

    }

    return (
        <div className='col-12'>
            <DataTable
                columnResizeMode='fit'
                value={props.recipents}
                selection={props.selectedRecipents}
                selectionMode='multiple'
                dataKey="id"
                loading={props.loading}
                paginator
                globalFilter={props.globalFilter}
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                emptyMessage='Nenhum recipiente encontrado para a localidade selecionada'
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} recipientes">
                <Column field="recipientName" header="Nome do recipiente" sortable body={recipentsNameTemplate}></Column>
                <Column field="recipientCode" header="Código do recipiente" sortable body={recipentsCodeTemplate} ></Column>
                <Column field="createStamp" header="Data de Cadastro" sortable body={createStampTemplate} ></Column>
                <Column field="active" header="Ativo" sortable body={activeTemplate} ></Column>
                <Column body={actionsTemplate} ></Column>
            </DataTable>
        </div>
    );
}