import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { DropdownInfo } from '../../../models/types';
import { ToastServices } from '../../../services/utils/toast-services';
import { PageHeader } from '../../../views/page-header';
import { FeatureServices } from '../../../services/feature-services';
import { LocationFeatureServices } from '../../../services/location-feature-services';
import { LocationServices } from '../../../services/location-services';
import '../styles/features-data.scss';
import { Feature } from '../types/types';
import { FeaturePickList } from './pick-lists/feature-pick-list';

export const Features = () => {
    const _toastServices = new ToastServices();
    const _featureServices = new FeatureServices();
    const _locationServices = new LocationServices();
    const _locationFeatureServices = new LocationFeatureServices();

    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [features, setFeatures] = useState<DropdownInfo[]>([]);
    const [locationFeatures, setLocationFeatures] = useState<DropdownInfo[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getLocations();
        getFeatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFeatures = () => {
        setLoading(true);
        _featureServices.getFeatures(false)
            .then((data: any) => {
                setFeatures(data.data);
            })
            .catch((error: any) => {
                _toastServices.showErrorRequestMessage(error);
            });
        setLoading(false);
    }
    const getFilteredFeatures = (): DropdownInfo[] => {
        var _locationFeatures = locationFeatures;
        var _filteredList: string[] = _locationFeatures.map(x => x.description);

        return features.filter(x => !_filteredList.includes(x.description));
    }
    const getLocationFeatures = (locationId: number) => {
        setLoading(true);
        _locationFeatureServices.getLocationFeatures(locationId, false)
            .then((data: any) => {
                var locationFeatures: DropdownInfo[] = data.data
                    .map((x: any) => {
                        return {
                            description: x.featureName,
                            id: x.featureId
                        } as DropdownInfo
                    });

                setLocationFeatures(locationFeatures);
                setLoading(false);
            })
            .catch((error: any) => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            });
    }
    const getLocations = () => {
        setLoading(true);
        _locationServices.getLocationsWithActiveCameras()
            .then((data: any) => {
                setLocations(data.data);
                setLoading(false);
            }).catch((error) => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            });
    }

    const onSelectedLocation = (e: { value: any }) => {
        setSelectedLocation(e.value);
        getLocationFeatures(e.value);
    }
    const onChange = (event: any) => {
        setFeatures(event.source);
        setLocationFeatures(event.target);
    }
    const onSaveChanges = () => {
        setLoading(true);
        var _featureList: Feature[] = locationFeatures
            .map((lf: DropdownInfo) => {
                return {
                    featureName: lf.description,
                    featureId: lf.id,
                    locationFeatureId: null,
                    whatsappNotification: false
                };
            });

        _locationFeatureServices.updateLocationFeatures(selectedLocation, _featureList)
            .then((data: any) => {
                _toastServices.showSuccesMessage("Recursos atualizados");
                setLoading(false);
            })
            .catch((error) => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            });

        getLocations();
        getFeatures();
        if (selectedLocation) {
            getLocationFeatures(selectedLocation)
        }
    }

    const renderPageHeader = () => {
        return (
            <div className='grid'>
                <div className='col-2'>
                    <div className="p-inputgroup mb-2">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-map-marker"></i>
                        </span>
                        <Dropdown
                            options={locations}
                            value={selectedLocation}
                            disabled={loading}
                            placeholder='Selecione uma localidade'
                            optionLabel='description'
                            optionValue='id'
                            onChange={(e) => onSelectedLocation(e)}
                        />
                    </div>
                </div>
                <div className='col-1'>
                    <Button className='mr-2' label='Salvar' size='large' disabled={loading} onClick={(e) => onSaveChanges()} />
                    <ClipLoader color='var(--text-color)' loading={loading} size={25} />
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <PageHeader title="CONFIGURAÇÃO DE RECURSOS POR LOCALIDADE" icon="pi pi-chart-bar" />
            <div className='card'>
                {renderPageHeader()}
                {
                    selectedLocation !== 0 &&
                    <FeaturePickList
                        features={getFilteredFeatures()}
                        locationFeatures={locationFeatures}
                        onChange={onChange}
                    />
                }
            </div>
        </React.Fragment>
    )
}
