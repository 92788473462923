import { DropdownInfo } from "../../models/types";
import { vehicleBrand } from "../constants/vehicle-brand";
import { vehicleColor } from "../constants/vehicle-color";
import { vehicleType } from "../constants/vehicle-type";

export const getVehicleColors = (): DropdownInfo[] => {
    var values = Object.keys(vehicleColor)
        .filter((v) => isNaN(Number(v)))
        .map((name) => {
            return {
                value: vehicleColor[name as keyof typeof vehicleColor],
                name,
            };
        });

    var newValues: DropdownInfo[] = values
        .filter((x) => x.value !== 0)
        .map((x) => {
            return {
                id: x.value,
                description: x.name
            } as DropdownInfo;
        }).sort((a, b) => (a.description < b.description ? -1 : 1));

    newValues.unshift({ id: 0, description: 'TODAS AS CORES' });

    return newValues;
}

export const getVehicleTypes = (): DropdownInfo[] => {
    var values = Object.keys(vehicleType)
        .filter((v) => isNaN(Number(v)))
        .map((name) => {
            return {
                value: vehicleType[name as keyof typeof vehicleType],
                name,
            };
        });

    var newValues: DropdownInfo[] = values
        .filter((x) => x.value !== 6)
        .map((x) => {
            return {
                id: x.value,
                description: x.name
            } as DropdownInfo;
        }).sort((a, b) => (a.description < b.description ? -1 : 1));

    newValues.unshift({ id: 6, description: 'TODOS OS TIPOS' });

    return newValues;
}

export const getVehicleBrands = (): DropdownInfo[] => {
    var values = Object.keys(vehicleBrand)
        .filter((v) => isNaN(Number(v)))
        .map((name) => {
            return {
                value: vehicleBrand[name as keyof typeof vehicleBrand],
                name,
            };
        });

    var newValues: DropdownInfo[] = values
        .filter((x) => x.value !== 0)
        .map((x) => {
            return {
                id: x.value,
                description: x.name
            } as DropdownInfo;
        }).sort((a, b) => (a.description < b.description ? -1 : 1));

    newValues.unshift({ id: 0, description: 'TODAS AS MARCAS' });

    return newValues;
}

export const getNumberOfPassengers = (): DropdownInfo[] => {
    const values = [1, 2];

    var newValues: DropdownInfo[] = values
    .map((x) => {
        return {
            id: x,
            description: x.toString()
        } as DropdownInfo;
    }).sort((a, b) => (a.description < b.description ? -1 : 1));

    newValues.unshift({ id: 0, description: 'QUALQUER QUANTIDADE DE PESSOAS' });

    return newValues;
}
