import axios from 'axios';
import { environment } from "../configuration/environment";
import { AuthServices } from "./utils/auth-services";
import { updatedCameraStatusUpdateRequest } from '../pages/DSM-Monitor/types/types';
export class MonitoringServices {

    _authServices = new AuthServices();
    _controllerBaseUrl = environment().baseUrl + environment().version + '/dashboard/';
    _monitorcontrollerBaseUrl = environment().baseUrl + environment().version + '/monitor/';

    getPerformanceReport() {
        return axios.get(this._controllerBaseUrl + 'performance-check',
            {
                headers: {
                    Authorization: this._authServices.GetUserToken()
                }
            })
    }

    getLocationsByFeature(type?: string) {
        return axios.get(this._monitorcontrollerBaseUrl + 'locations-by-feature-monitor', {
            params: {
                type: type
            },
            headers: {
                Authorization: this._authServices.GetUserToken()
            }
        });
    }

    getApplicationRecent(type?: string) {
        return axios.get(this._monitorcontrollerBaseUrl + 'data-by-monitor', {
            params: {
                type: type
            },
            headers: {
                Authorization: this._authServices.GetUserToken()
            }
        });
    }

    getCamerasByFeatureAndLocation(locationId: number) {
        return axios.get(this._monitorcontrollerBaseUrl + 'cameras-by-feature-monitor', {
            params: {
                locationId: locationId,
            },
            headers: {
                Authorization: this._authServices.GetUserToken()
            }
        });
    }

    getCameraRecent(locationId: number) {
        return axios.get(this._monitorcontrollerBaseUrl + 'data-by-camera', {
            params: {
                locationId: locationId
            },
            headers: {
                Authorization: this._authServices.GetUserToken()
            }
        });
    }

    updateCameraStatus(updatedCamera: updatedCameraStatusUpdateRequest) {
        return axios.put(this._monitorcontrollerBaseUrl + 'update-data-by-camera',
        {
            cameraId: updatedCamera.cameraId,
            activeMonitor: updatedCamera.activeMonitor
        },
        {
            headers: {
                Authorization: this._authServices.GetUserToken()
            }
        });
    }
}
