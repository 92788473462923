import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import { WhatsappGroup } from '../../../../components/WhatsappGroup/whatsapp-group';
import { DropdownInfo } from '../../../../models/types';
import { FeatureServices } from '../../../../services/feature-services';
import { onCheckedChange, onInputChange } from '../../../../services/utils/input-services';
import { ToastServices } from '../../../../services/utils/toast-services';
import { blackListItem, eventReasons } from '../../types/types';

export interface CreateEventDialogProps {
    visible: boolean,
    submitted: boolean,
    isEditing: boolean,
    event: blackListItem,
    setEvent: (event: blackListItem) => void,
    saveEvent: () => void,
    hideDialog: () => void
}
export const CreateEventDialog = (props: CreateEventDialogProps) => {
    const _featureServices = new FeatureServices();
    const _toastServices = new ToastServices();

    const [locations, setLocations] = useState<DropdownInfo[]>();

    useEffect(() => {
        _featureServices.getLocationsByFeature('BLACK-LIST')
            .then((data: any) => {
                setLocations(data.data);
            }).catch((error: any) => {
                _toastServices.showErrorRequestMessage(error);
            })
    }, [props.event.locationId]);

    const onDropdownValueChange = (val: any, name: string) => {
        let event = { ...props.event } as any;

        event[`${name}`] = val.target.value;

        props.setEvent(event);
    };

    const eventDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={props.saveEvent} />
        </>
    )

    const descriptionIsRequired = props.event.reason === 'OUTRA';
    const headerText = (props.isEditing ? "EDITANDO " : "CRIANDO ") + "BLACK-LIST";

    return (
        <Dialog visible={props.visible} style={{ width: '450px' }} header={headerText} modal className="p-fluid p-grid" footer={eventDialogFooter} onHide={props.hideDialog}>
            <div className="categoria mb-2" style={{ display: `${props.isEditing ? `none` : `inherit`}` }} >
                <strong>Localidade *</strong>
                <Dropdown
                    id="plate"
                    maxLength={7}
                    options={locations}
                    value={props.event.locationId}
                    onChange={(e) => onInputChange(e, 'locationId', props.event, props.setEvent)}
                    required
                    autoFocus
                    showClear
                    optionValue="id"
                    optionLabel="description"
                    placeholder='Selecione uma localidade'
                    className={classNames({ 'p-invalid': (props.submitted && !props.event.plate) || props.submitted && props.event.plate.length !== 7 && props.event.plate })}
                />
                {props.submitted && (!props.event.plate) && <small className="p-invalid">Informe a Placa.</small>}
                {props.submitted && props.event.plate.length !== 7 && props.event.plate && <small className="p-invalid">Placa Inválida.</small>}
            </div>
            <div className="categoria mb-2" style={{ display: `${props.isEditing ? `none` : `inherit`}` }} >
                <strong>Placa *</strong>
                <InputText
                    id="plate"
                    maxLength={7}
                    value={props.event.plate}
                    onChange={(e) => onInputChange(e, 'plate', props.event, props.setEvent)}
                    required
                    autoFocus
                    disabled={props.event.locationId === 0}
                    className={classNames({ 'p-invalid': (props.submitted && !props.event.plate) || props.submitted && props.event.plate.length !== 7 && props.event.plate })}
                />
                {props.submitted && (!props.event.plate) && <small className="p-invalid">Informe a Placa.</small>}
                {props.submitted && props.event.plate.length !== 7 && props.event.plate && <small className="p-invalid">Placa Inválida.</small>}
            </div>

            <div className="categoria mb-2">
                <strong>Razão *</strong>
                <Dropdown
                    id='reason'
                    value={props.event.reason}
                    options={eventReasons}
                    onChange={(e) => onInputChange(e, 'reason', props.event, props.setEvent)}
                    required
                    disabled={props.event.locationId === 0}
                    className={classNames({ 'p-invalid': props.submitted && !props.event.reason })}
                />
                {props.submitted && !props.event.reason && <small className="p-invalid">Informe a Razão.</small>}
            </div>

            <div className="categoria">
                <strong>Descrição {descriptionIsRequired && <>*</>}</strong>
                <InputTextarea
                    id="description"
                    value={props.event.description}
                    onChange={(e) => onInputChange(e, 'description', props.event, props.setEvent)}
                    required
                    disabled={props.event.locationId === 0}
                    className={classNames({ 'p-invalid': props.submitted && descriptionIsRequired && props.event.description === `` })}
                />
                {props.submitted && descriptionIsRequired && props.event.description === `` && <small className="p-invalid">Informe a Descrição.</small>}
            </div>
            {props.isEditing && (
                <div className="p-field">
                    <Checkbox
                        className="mr-2"
                        inputId="checkOption3"
                        name="option"
                        checked={props.event.active}
                        onChange={(e) => onCheckedChange(e, "active", props.event, props.setEvent)}>
                    </Checkbox>
                    <strong className="titulo-categoria">Ativa</strong>
                </div>
            )}
            {props.event.locationId != 0 && (
                <>
                    <span className="titulo-categoria">CONFIGURAÇÃO GRUPO WHATSAPP:</span>
                    <div className="categoria">
                        <WhatsappGroup selectedGroupId={props.event.whatsappGroupId} selectedLocation={props.event.locationId} object={props.event} objectUpdate={props.setEvent} />
                    </div>
                </>
            )}
        </Dialog>
    )
}
