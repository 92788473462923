import React from 'react';
import '../../styles/black-list-warning.scss';

export const BlackListWarning = () => {
    return (
        <>
            <div className="card mb-4 black-list-warning">
                <span style={{ color: `#fcad03`, fontSize: "1.2rem", display: `flex`, alignItems: `center` }}>
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "1.2em" }} />
                    As atualizações da black list podem levar de 3 a 5 minutos para entrarem em vigor
                </span>
            </div>
        </>
    );
}