import { BehavioralConvoyAlertResponse, ConvoyAlertResponse, CustomAlarmAlertResponse, DssEventSubscriptionResponse, NoPlateAlertResponse, PointABAlertResponse, blackListResponse, cardResponse, integrationCardResponse } from "../../../models/types";
import { VehicleInformationsResponse } from "../../Event-behabioral-convoy/types/types";

export const blankCard = {
    quantityCar: 0,
    quantityMotorBike: 0,
    quantityBus: 0,
    quantityOther: 0,
    quantityTruck: 0,
    quantityOfBikerBehaviorsTriggeredEvents: 0,
    quantityOfBlackListTriggeredEvents: 0,
    quantityOfCustomAlarmTriggeredEvents: 0,
    quantityOfDestinationABTriggeredEvents: 0,
    quantityOfNoPlateTriggeredEvents: 0,
    quantityOfConvoyTriggeredEvents: 0,
    quantityOfBehavioralConvoyTriggeredEvents: 0
} as cardResponse;

export const blankIntegrationCard = {
    totalRead: 0,
    totalFailure: 0,
    totalSuccess: 0,
    activeCameras: 0,
    inactiveCameras: 0,
    locationCount: 0,
    locationId: 0
} as integrationCardResponse;

export const blankBlackListAlert: blackListResponse = {
    cameraName: ``,
    dateTime: ``,
    imageUrl: ``,
    plate: ``,
    description: ``,
    reason: ``,
    address: ``,
    locationId: 0
}

export const blankConvoyAlert: ConvoyAlertResponse = {
    firstVehicleImageUrl: '',
    secondVehicleImageUrl: '',
    cameraAddress: '',
    cameraName: '',
    firstVehiclePlate: '',
    secondVehiclePlate: '',
    firstEventDateTime: '',
    secondEventDateTime: '',
    firstVehicleBrand: 0,
    firstVehicleColor: 0,
    firstVehicleType: 0,
    secondVehicleBrand: 0,
    secondVehicleColor: 0,
    secondVehicleType: 0,
    locationId: 0,
    whatsappGroupId: null
}

export const blankPointABAlert: PointABAlertResponse = {
    imageUrlPointA: '',
    imageUrlPointB: '',
    cameraAddressPointA: '',
    cameraAddressPointB: '',
    cameraNamePointA: '',
    cameraNamePointB: '',
    plate: '',
    dateTimePointA: '',
    dateTimePointB: '',
    locationId: 0
}

export const blankCustomAlarmAlert: CustomAlarmAlertResponse = {
    plate: '',
    dateTime: '',
    cameraAddress: '',
    cameraName: '',
    imageUrl: '',
    vehicleTypeId: 0,
    vehicleColorId: 0,
    vehicleBrandId: 0,
    locationId: 0,
    numberOfPassengers: 0
}

export const blankNoPlateAlert: NoPlateAlertResponse = {
    dateTime: '',
    cameraAddress: '',
    cameraName: '',
    imageUrl: '',
    locationId: 0,
}

export const blankVehicleInformations: VehicleInformationsResponse = {
    date: '',
    imageUrl: '',
    type: '',
    vehiclePlate: ''
}

export const blankBehavioralConvoyAlert: BehavioralConvoyAlertResponse = {
    cameraAddress: '',
    cameraName: '',
    locationId: 0,
    firstVehicleType: blankVehicleInformations,
    secondVehicleType: blankVehicleInformations
}

export const blankDssEventSubscriptionAlert: DssEventSubscriptionResponse = {
    alarmDescription: '',
    azureImageUrl: '',
    vehiclePlate: '',
    cameraName: '',
    alarmTime: '',
    alarmType: '',
    locationId: 0,
}

