import { Dialog } from 'primereact/dialog';
import React from 'react';
import { blackListItem } from '../../types/types';
import { Button } from 'primereact/button';


export interface deleteEventDialogProps {
    visible: boolean,
    hideDialog: () => void,
    deleteEvent: () => void,
    event: blackListItem
}
export const DeleteEventDialog = (props: deleteEventDialogProps) => {
    const deleteEventDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={props.deleteEvent} />
        </>
    );
    return (
        <Dialog visible={props.visible} style={{ width: '450px' }} header="Confirm" modal footer={deleteEventDialogFooter} onHide={props.hideDialog}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                {props.event && <span>Tem certeza que deseja deletar o evento <b>{props.event.plate}</b>?</span>}
            </div>
        </Dialog>
    );
}