import React, { useEffect, useState } from 'react';
import { NavigateToLocation } from '../../../components/Navigation/navigate-to-location';
import { PageHeader } from '../../../views/page-header';
import { EventBikerBehaviorHeader } from '../components/event-biker-behavior-header';
import { EventBikerBehaviorGrid } from '../components/event-biker-behavior-grid';

export const EventBikerBehavior = () => {
    const [loading, setLoading] = useState<boolean>();
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [selectedCamera, setSelectedCamera] = useState<number>(null);

    var cameraId: number = selectedCamera;

    useEffect(() => {
        if (selectedCamera !== 0 || selectedCamera !== null) {
            cameraId = null;
        }
    }, [selectedLocation])

    return (
        <div className="grid">
            <div className="col-12">
                <PageHeader
                    title="BUSCAR EVENTOS DE ANÁLISE COMPORTAMENTAL DOS MOTOQUEIROS"
                    icon='pi pi pi-car'
                />
                <div className="card">
                    <NavigateToLocation
                        icon="pi-spin pi-cog"
                        text="CONFIGURAR ANÁLISE COMPORTAMENTAL DE MOTOQUEIROS"
                        to="biker-behavior-configuration"
                    />
                    <EventBikerBehaviorHeader
                        loading={loading}
                        selectedCamera={selectedCamera}
                        setSelectedCamera={setSelectedCamera}
                        setLoading={setLoading}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                    />
                    <EventBikerBehaviorGrid
                        loading={loading}
                        selectedLocation={selectedLocation}
                        cameraId={cameraId}
                        setLoading={setLoading}
                    />
                </div>
            </div>
        </div>
    )
}