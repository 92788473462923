import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { DropdownInfo } from "../../../src/models/types";
import { onInputChange } from "../../services/utils/input-services";
import { WhatsAppServices } from "../../services/whatsapp-services";

export interface WhatsappGroupProps {
    selectedGroupId: number,
    selectedLocation: number,
    object: any,
    objectUpdate: (object: any) => void
}

const whatsappService = new WhatsAppServices();

export const WhatsappGroup = (props: WhatsappGroupProps) => {
    const [locationGroups, setLocationGroups] = useState<DropdownInfo[]>([]);

    useEffect(() => {
        if (props.selectedLocation !== 0) {
            whatsappService.getWhatsappGroupsByLocation(props.selectedLocation)
                .then(data => {
                    var responseData: DropdownInfo[] = data.data;

                    var allGroups: DropdownInfo = {
                        description: "Notificar todos grupos",
                        id: null,
                    };
                    responseData.unshift(allGroups);

                    setLocationGroups(responseData);
                })
        }
    }, [props.selectedLocation]);


    return (
        <>
            <div className="grid">
                <div className='col-12'>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-fw pi-users"></i>
                        </span>
                        <Dropdown
                            emptyMessage="Nenhum Grupo Encontrado"
                            optionLabel="description"
                            optionValue="id"
                            options={locationGroups}
                            placeholder="Notificar Todos Grupos"
                            value={props.selectedGroupId}
                            onChange={(e) => onInputChange(e, "whatsappGroupId", props.object, props.objectUpdate)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
