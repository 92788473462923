import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Tag } from "primereact/tag"
import React, { useRef } from "react"
import { vehicleBrand } from "../../../shared/constants/vehicle-brand"
import { vehicleColor } from "../../../shared/constants/vehicle-color"
import { vehicleType } from "../../../shared/constants/vehicle-type"
import { customAlarmInterface } from "../types/types"

export interface CustomAlarmListDataTableProps {
    customAlarms: customAlarmInterface[];
    selectedCustomAlarms: customAlarmInterface[];
    setSelectedCustomAlarm: (e: any) => void;
    loading: boolean;
    globalFilter: string;
    editCustomAlarm: (rowData: customAlarmInterface) => void;
    confirmDeleteCustomAlarm: (rowData: customAlarmInterface) => void;
}

export const CustomAlarmListDataTable = (props: CustomAlarmListDataTableProps) => {

    const header = (
        <div className="table-header">
            <h5 className="p-m-2">ALARMES CUSTOMIZADOS CADASTRADOS NO SISTEMA</h5>
        </div>
    )

    const cameraBodyTemplate = (customAlarm: customAlarmInterface) => {
        return <>{customAlarm.camera.description}</>;
    }

    const cameraLocationBodyTemplate = (customAlarm: customAlarmInterface) => {
        return <>{customAlarm.cameraLocation.description}</>;
    }

    const vehicleTypeBodyTemplate = (customAlarm: customAlarmInterface) => {
        return <>{customAlarm.vehicleTypeId === 6 ? "TODOS" : vehicleType[customAlarm.vehicleTypeId]}</>;
    }

    const vehicleColorBodyTemplate = (customAlarm: customAlarmInterface) => {
        return <>{customAlarm.vehicleColorId === 0 ? "TODAS" : vehicleColor[customAlarm.vehicleColorId]}</>;
    }

    const vehicleBrandBodyTemplate = (customAlarm: customAlarmInterface) => {
        return <>{customAlarm.vehicleBrandId === 0 ? "TODAS" : vehicleBrand[customAlarm.vehicleBrandId]}</>;
    }

    const isActiveBodyTemplate = (customAlarm: customAlarmInterface) => {
        if (customAlarm.isActive === true) {
            return (
                <>
                    <Tag severity="success" value="Ativo" icon="pi pi-check"></Tag>
                </>
            );
        } else {
            return (
                <>
                    <Tag severity="danger" value="Inativo" icon="pi pi-times"></Tag>
                </>
            );
        }
    }

    const actionBodyTemplate = (rowData: customAlarmInterface) => {
        return (
            <div className="actions my-2 xl:offset-md-5">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button mr-2" onClick={() => props.editCustomAlarm(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => props.confirmDeleteCustomAlarm(rowData)} />
            </div>
        );
    }

    const dt = useRef(null);

    return (
        <DataTable
            ref={dt}
            value={props.customAlarms}
            selection={props.selectedCustomAlarms}
            onSelectionChange={(e: any) => props.setSelectedCustomAlarm(e.value)}
            dataKey="id"
            paginator
            rows={10}
            selectionMode='multiple'
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
            currentPageReportTemplate="Mostrando {first} de {last}, de um total de {totalRecords} alarmes customizados"
            globalFilter={props.globalFilter}
            emptyMessage="Nenhum alarme customizado encontrado."
            header={header}
            loading={props.loading}
        >
            <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
            <Column field="camera.description" header="Câmera" sortable body={cameraBodyTemplate}></Column>
            <Column field="cameraLocation.description" header="Localidade" sortable body={cameraLocationBodyTemplate}></Column>
            <Column field="vehicleType" header="Tipo" sortable body={vehicleTypeBodyTemplate}></Column>
            <Column field="vehicleColor" header="Cor" sortable body={vehicleColorBodyTemplate}></Column>
            <Column field="vehicleBrandB" header="Marca" sortable body={vehicleBrandBodyTemplate}></Column>
            <Column field="isActive" header="Ativo" sortable body={isActiveBodyTemplate}></Column>
            <Column body={actionBodyTemplate}></Column>
        </DataTable>
    )
}
