export interface BikerBehaviorConfig {
    locationId: number,
    startTime: Date,
    stopTime: Date
}

export interface EventBikerBehaviorResponse {
    cameraName: string,
    cameraAddress: string,
    firstBikePlate: string,
    secondBikePlate: string,
    firstBikeImageUrl: string,
    secondBikeImageUrl: string,
    firstBikeDescription: string,
    secondBikeDescription: string,
    firstBikeDateTime: string,
    secondBikeDateTime: string
}

export const BlankBikerBehaviorConfig: BikerBehaviorConfig = {
    locationId: 1,
    startTime: new Date(),
    stopTime: new Date()
}