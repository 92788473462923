import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../../views/page-header';
import { MonitoringServices } from '../../../services/monitoring-services';
import { PerformanceReportResponse } from '../types/types';
import { ToastServices } from '../../../services/utils/toast-services';
import { MonitoringDataTable } from './components/monitoring-datatable';

export const Monitoring = () => {
    const _toastService = new ToastServices();
    const _monitoringServices = new MonitoringServices();

    var [loading, setLoading] = useState<boolean>(false);
    var [globalFilter, setGlobalFilter] = useState<string>('');
    var [performanceReport, setPerformanceReport] = useState<PerformanceReportResponse[]>([]);


    useEffect(() => {
        getPerformanceReport();
    }, []);

    const getPerformanceReport = () => {
        setLoading(true);
        _monitoringServices.getPerformanceReport()
            .then(data => {
                setLoading(false);
                setPerformanceReport(data.data);
            }, (error) => {
                setLoading(false);
                _toastService.showErrorRequestMessage(error);
            });


    }
    return (
        <div className="grid">
            <div className="col-12">
                <PageHeader title="MONITORAMENTO DE PERFORMANCE" icon="pi pi-chart-line" />
                <div className="card">
                    <MonitoringDataTable
                        globalFilter={globalFilter}
                        loading={loading}
                        reports={performanceReport}
                        setGlobalFilter={setGlobalFilter}
                    />
                </div>
            </div>
        </div>
    )
}