import React from 'react';
import '../../styles/camera-convoy-warning.scss';

export interface LocationConvoyWarningProps {
    startHour: Date,
    stopHour: Date
}
export const LocationConvoyWarning = (props: LocationConvoyWarningProps) => {

    const dateTimeConfigurations: any = { timeStyle: 'short' };

    return (
        <>
            <div className="card mb-4 black-list-warning">
                <span style={{ color: '#fcad03', fontSize: '1.2rem', display: 'flex', alignItems: 'center' }}>
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "1.2em" }} />
                    O <strong className='mr-1 ml-1'>Modo Comboio & Modo Comboio Comportamental</strong> estão atualmente em operação de <strong className='ml-2 mr-2'>{props.startHour.toLocaleTimeString('pt-br', dateTimeConfigurations)}</strong> até as  <strong className='ml-2 mr-2'>{props.stopHour.toLocaleTimeString('pt-br', dateTimeConfigurations)}</strong> para as câmeras com a opção ativada.
                </span>
            </div>
        </>
    );

}