import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import React from "react"
import { dsmApplicationLocation } from "../../types/types"

export interface DSMApplicationLocationsDataTableProps {
  dsmApplicationLocations: dsmApplicationLocation[]
  deleteLocation: (rowData: any) => void
}

export const DSMApplicationLocationsDataTable = (props: DSMApplicationLocationsDataTableProps) => {
  const bodyLocationNameTemplate = (rowData: any) => {
    let locationName = props.dsmApplicationLocations.filter((x) => x.locationId === rowData.locationId).map((valor) => valor.locationName)[0];

    return <strong>{locationName}</strong>;
};

const actionBodyTemplate = (rowData: dsmApplicationLocation) => {
  return (
      <div className="actions">
          <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => props.deleteLocation(rowData)} />
      </div>
  );
};

return (
  <DataTable
  emptyMessage="Nenhuma Localidade Selecionada"
  value={props.dsmApplicationLocations}
  editMode="row"
  dataKey="locationId"
  className={"location-list"}
>
      <Column field="locationName"
      body={bodyLocationNameTemplate}
      style={{ width: "85%" }}></Column>
      <Column body={actionBodyTemplate}></Column>
  </DataTable>
)
}
