import axios from "axios";
import { environment } from "../configuration/environment";
import { AuthServices } from "./utils/auth-services";
import { blackListItem } from "../pages/Black-list/types/types";

export class BlackListServices {
    _authServices = new AuthServices();

    getLocations() {
        return axios.get(environment().baseUrl + environment().version + "/black-list/active-locations/", {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    getCameras(locationId: number) {
        return axios.get(environment().baseUrl + environment().version + `/black-list/active-cameras/` + locationId,
            {
                headers: {
                    Authorization: sessionStorage.getItem(`Bearer`)
                }
            });
    }

    getBlackLists() {
        return axios.get(
            environment().baseUrl + environment().version + `/black-list`,
            {
                headers: {
                    Authorization: this._authServices.GetUserToken()
                }
            });
    }
    
    getBlackListEvents(plate: string, locationId: number, cameraId: number) {
        return axios.get(environment().baseUrl + environment().version + "/black-list/events", {
            headers: {
                Authorization: this._authServices.GetUserToken()
            },
            params: {
                plate: plate,
                locationId: locationId === 0 ? null : locationId,
                cameraId: cameraId === 0 ? null : cameraId,
            },
        });
    }

    createBlackList(blackList: blackListItem) {
        return axios.post(
            environment().baseUrl + environment().version + `/black-list`,
            blackList,
            {
                headers: {
                    Authorization: this._authServices.GetUserToken()
                }
            });
    }

    updateBlackList(blackList: blackListItem) {
        return axios.put(
            environment().baseUrl + environment().version + `/black-list/${blackList.idBlackList}`,
            blackList,
            {
                headers: {
                    Authorization: sessionStorage.getItem(`Bearer`)
                }
            });
    }

    deleteBlackList(blackList: blackListItem) {
        return axios.delete(
            environment().baseUrl + environment().version + `/black-list/${blackList.idBlackList}`,
            {
                headers: {
                    Authorization: sessionStorage.getItem(`Bearer`)
                }
            });
    }
}
