export enum vehicleColor {
    DUMMY,
    AZUL,
    AMARELO,
    PRETO,
    PRATA,
    BRANCO,
    CINZA,
    VERDE,
    MARROM,
    BEGE,
    VERMELHO
}

