import React from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "../../styles/dsmApplication.scss";
import { dsmApplication } from "../../types/types";
import { toast, ToastContainer } from "react-toastify";
import { ToastServices } from "../../../../services/utils/toast-services";
import { DSMApplicationServices } from "../../../../services/dsm-application-services";

export interface DeleteDSMApplicationsDialogProps {
    dsmApplication: dsmApplication;
    setLoading: (isLoading: boolean) => void;
    getDSMApplications: () => void;
    setDeleteDSMApplicationsDialog: (showDSMApplicationDialog: boolean) => void;
    visible: boolean;
    setSelectedDSMApplications: (selectedDSMApplications: any) => void;
    selectedDSMApplications: any;
}

export const DeleteDSMApplicationsDialog = (props: DeleteDSMApplicationsDialogProps) => {
    const dsmApplicationService = new DSMApplicationServices();
    const _toastService = new ToastServices();
    const hideDeleteDSMApplicationsDialog = () => {
        props.setDeleteDSMApplicationsDialog(false);
    };

    const deleteSelectedDSMApplications = () => {
        props.setLoading(true);
        props.selectedDSMApplications.forEach((dsmApplication: dsmApplication) => {
            dsmApplicationService.deleteDSMApplication(dsmApplication.dsmApplicationId).then(
                () => {
                    props.getDSMApplications();
                    _toastService.showSuccesMessage(`Servidor DSM ${dsmApplication.dsmApplicationName} Excluído com Sucesso.`);
                },
                (error: any) => {
                    props.getDSMApplications();
                    _toastService.showErrorRequestMessage(error);
                    props.setLoading(false);
                }
            );
        });
        props.setSelectedDSMApplications(null);

        props.setDeleteDSMApplicationsDialog(false);
    };

    const deleteDSMApplicationsDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDSMApplicationsDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedDSMApplications} />
        </>
    );

    return (
        <Dialog visible={props.visible} style={{ width: "450px" }} header="Confirm" modal footer={deleteDSMApplicationsDialogFooter} onHide={hideDeleteDSMApplicationsDialog}>
            <ToastContainer />
            <Toast ref={toast} />
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                {props.dsmApplication && <span className="ml-2">Você têm certeza que deseja excluir os servidores DSM selecionados?</span>}
            </div>
        </Dialog>
    );
};
