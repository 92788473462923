import axios from "axios";
import { environment } from "../configuration/environment";
import { AuthServices } from "./utils/auth-services";
import { EventABRequest, destinationABInterface } from "../pages/Destination-ab/types/types";

export class DestinationABService {
    _authServices = new AuthServices();

    getEventsThrownOff(vehiclePlate: string, locationIdPointA: number, cameraIdPointA: number, locationIdPointB: number, cameraIdPointB: number) {
        const eventAB: EventABRequest = (
            {
                cameraIdPointA: cameraIdPointA === 0 ? null : cameraIdPointA,
                cameraIdPointB: cameraIdPointB === 0 ? null : cameraIdPointB,
                locationIdPointA: locationIdPointA === 0 ? null : locationIdPointA,
                locationIdPointB: locationIdPointB === 0 ? null : locationIdPointB,
                vehiclePlate: vehiclePlate
            }
        );

        return axios.post(environment().baseUrl + environment().version + "/destination-ab/get-events-thrown-off", eventAB, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    getDestinationsAB() {
        return axios.get(environment().baseUrl + environment().version + "/destination-ab", {
            headers: { Authorization: sessionStorage.getItem("Bearer") },
        });
    }

    addDestinationAB(destinationAB: destinationABInterface) {
        var request: any = {
            cameraIdPointA: destinationAB.cameraPointA.id,
            cameraIdPointB: destinationAB.cameraPointB.id
        };

        return axios.post(environment().baseUrl + environment().version + "/destination-ab", request, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    updateDestinationAB(destinationAB: destinationABInterface) {
        var request: any = {
            cameraIdPointA: destinationAB.cameraPointA.id,
            cameraIdPointB: destinationAB.cameraPointB.id,
            isActive: destinationAB.isActive
        };

        return axios.put(environment().baseUrl + environment().version + "/destination-ab/" + destinationAB.id, request, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    deleteDestinationAB(destinationABId: number) {
        return axios.delete(environment().baseUrl + environment().version + "/destination-ab/" + destinationABId, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }
}
