import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { CSSProperties } from "react";
import { onInputChange } from "../../../../services/utils/input-services";
import { whatsappGroup } from "../../types/types";

export interface EditGroupDialogProps {
    visible: boolean;
    closeDialog: () => void;
    dialogHeader: string;
    submitted: boolean;
    saveGroup: () => void;
    group: whatsappGroup;
    setGroup: (group: whatsappGroup) => void;
}

export const EditGroupDialog = (props: EditGroupDialogProps) => {

    const recipientDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={props.closeDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={(e) => props.saveGroup()} />
        </>
    );
    const dialogStyle: CSSProperties = {
        width: "700px",
    };

    return (
        <Dialog visible={props.visible} onHide={props.closeDialog} style={dialogStyle} resizable={false} footer={recipientDialogFooter} header={props.dialogHeader}>
            <span className="titulo-categoria">GRUPO:</span>
            <div className="categoria">
                <div className="grid">
                    <div className="col-6">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-fw pi-pencil"></i>

                            </span>
                            <InputText
                                    required
                                    placeholder="Nome do Grupo"
                                    value={props.group.groupName}
                                    onChange={(e) => onInputChange(e, "groupName", props.group, props.setGroup)}
                                    className={classNames({ "p-invalid": props.submitted && !props.group.groupName })}
                                />
                        </div>
                        {props.submitted && !props.group.groupName && <small className="p-invalid">Insira um nome para o grupo</small>}
                    </div>
                </div>
            </div>
        </Dialog >
    );
};
