import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { ToastServices } from "../../../../services/utils/toast-services";
import { DestinationABService } from "../../../../services/destination-ab-service";
import { destinationABInterface } from "../../types/types";
import "./../../styles/destination-ab.scss";

export interface DeleteDestinationABDialogProps {
    destinationAB: destinationABInterface;
    setLoading: (isLoading: boolean) => void;
    setDestinationAB: (destionationAB: destinationABInterface) => void;
    getDestinationsAB: () => void;
    emptyDestionationAB: destinationABInterface;
    setDeleteDestionationABDialog: (showDestionationABDialog: boolean) => void;
    visible: boolean;
}

export const DeleteDestinationABDialog = (props: DeleteDestinationABDialogProps) => {
    const destinationABService = new DestinationABService();
    const _toastService = new ToastServices();
    const hideDeleteDestinationABDialog = () => {
        props.setDeleteDestionationABDialog(false);
    };

    const deleteDestinationAB= () => {
        props.setLoading(true);
        destinationABService.deleteDestinationAB(props.destinationAB.id).then(
            () => {
                props.setDeleteDestionationABDialog(false);
                props.setDestinationAB(props.emptyDestionationAB);
                props.setLoading(false);
                _toastService.showSuccesMessage(`Ponto A & B excluído com sucesso.`);
                props.getDestinationsAB();
            },
            (error: any) => {
                props.getDestinationsAB();
                _toastService.showErrorRequestMessage(error);
                props.setLoading(false);
            }
        );
    };

    const deleteDestinationABDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDestinationABDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteDestinationAB} />
        </>
    );

    return (
        <Dialog visible={props.visible} style={{ width: "450px" }} header="Confirmação" modal footer={deleteDestinationABDialogFooter} onHide={hideDeleteDestinationABDialog}>
            <ToastContainer />
            <Toast ref={toast} />
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3 " style={{ fontSize: "2rem" }} />
                {props.destinationAB && (
                    <span className="ml-2">
                        Você têm certeza que deseja excluir este ponto A & B?
                    </span>
                )}
            </div>
        </Dialog>
    );
};
