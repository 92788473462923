import React, { useState } from "react";
import { PageHeader } from "../../../views/page-header";
import { DsmBodyRED } from "./body/dsm-body-red";


export const DsmMonitoringRED = (props: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);

    return (
        <React.Fragment>
            <div className="grid">
                <div className="col-12">
                    <PageHeader
                        title="SAÚDE DOS SERVIDORES DSM MOON REDUNDANCE"
                        icon="pi pi-heart"
                    />
                    <div className="card">
                        <DsmBodyRED
                            loading={loading}
                            setLoading={setLoading}
                            selectedLocation={selectedLocation}
                            setSelectedLocation={setSelectedLocation}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
